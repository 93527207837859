<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible">
			<div class="modal-background" @click="destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog">
					<div class="popup-title" v-if="title">
						<h1 class="title is-1">{{title}}</h1>

            <div class="popup-close" @click.prevent="destroy">
                <i class="icon">close</i>
            </div>
					</div>

					<div v-if="markdown" class="popup-body">
                        <vue-simple-markdown :source="text"></vue-simple-markdown>
					</div>
					<div v-else class="popup-body">
						{{text}}
					</div>

					<div class="popup-footer">
						<button class="button is-rounded is-success" @click.prevent="destroy">
							{{ $t('developers.confirm') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
    export default {
        props: {
            title: {
                type: String
            },
            text:  {
                type: String,
            },
            markdown: {
                type: Boolean,
                default: false
            }
        },
    }
</script>
