<template>
    <div class="field gifty-color-picker" :class="{ 'disabled': disabled }">
        <label v-if="!!label " class="label" :for="id">{{ labelText }}</label>
        <div class="control is-clearfix" :class="[{ 'with-shadow': typeof $attrs.inner === 'undefined', 'cp-disabled': disabled}, gClass]"
             v-click-outside="close">

            <div v-if="show || inline"
                 class="color-picker-container"
                 :class="{ inline }">

                <div v-if="!inline" class="color-picker-close">
                    <i class="icon" @click="close()">close</i>
                </div>

                <chrome-picker v-model="color"
                               class="picker editor-container"
                               :class="{ 'with-shadow': !inline, 'has-palette': hasPalette }"></chrome-picker>

            </div>

            <div v-if="hasPalette" class="colorpicker-suggested-colors" :class="{ 'palette-container': inline }">
                <div :class="['swatch', {'swatch-border': color === '#FFFFFF', 'swatch-selected': equal(c) }]"
                     v-for="c in filteredColors" :key="c"
                     :data-color="c"
                     :style="{ background: c }"
                     @click="handlerClick(c)">
                </div>
            </div>

            <div :id="id"
                 class="swatch-picker"
                 @click="toggle()"
                 :class="{ 'disabled': disabled }"
                 v-if="!inline" :style="{ 'border-color': customColor }"></div>

            <div>
                <p class="help" v-if="!!subtitle">{{ subtitle }}</p>
                <p class="help is-danger" v-if="hasError" v-text="errorMessage"></p>
            </div>
        </div>
    </div>
</template>

<script>

import debounce from 'lodash/debounce'
import VueColor from 'vue-color'
import InputHelper from '../../utils/InputHelper'
import Vibrant from 'node-vibrant'
import ClickOutside from '../ClickOutsideDirective'

export default InputHelper.extend({
    directives: {
        'click-outside': ClickOutside
    },
    data() {
        return {
            show: false,
            palette: [],
            _customColor: '#ddd',
        }
    },
    props: {
        subtitle: '',
        prepopulate: false,
        suggestions: Array,
        inline: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        hasPalette() {
            return this.filteredColors.length > 0
        },
        color: {
            set: debounce(function (color) {
                color = color.hex || color
                this.data[this.name] = color
                this.$emit('change', color)
            }, 100),
            get() {
                if (typeof this.data[this.name] !== 'string') {
                    return '#2e2e2e'
                }
                return this.data[this.name] || '#2e2e2e'
            },
        },
        customColor() {
            return this.filteredColors.indexOf(this.color) === -1 ? this._customColor : '#ddd'
        },
        filteredColors() {
            if(typeof this.palette === 'undefined') return []

            // Filter out double colors from the palette
            return this.palette.filter((color, index, self) => self.indexOf(color) === index)
        },
    },
    components: {
        'chrome-picker': VueColor.Chrome,
    },
    mounted() {
        if (typeof this.suggestions === 'undefined') {
            axios.get(`/api/company/${this.$store.getters['user/activeCompanyId']}/settings`).then(response => {

                response = response.data.data.color_suggestions

                this.palette = typeof this.suggestions !== 'undefined' && this.suggestions.length > 0 ? this.suggestions : response
            })
        }

        // Als er een kleur waarde mee is gegeven aan `prepopulate` > zet de kleur naar de nieuwe kleur waarde
        if (this.prepopulate) {
            this._customColor = this.prepopulate
        }

    },
    methods: {
        toggle() {
            if (this.disabled === true) {
                return
            }

            this.show = !this.show
        },
        close() {
            this.show = false
        },
        equal(color) {
            try {
                return color.toLowerCase() === this.color.toLowerCase()
            } catch (e) {
                console.log('error')
                return false
            }
        },
        handlerClick(c) {
            this.color = c
        },

        /**
         * @param {FileList|File|Blob|String}from Read colors from given image
         * @returns {Promise|Bluebird|Promise<T>} Returns promise with the parsed palette as value
         */
        setPalette(from) {

            return new Promise((resolve, reject) => {

                const readColors = event => {
                    const dataUri = !!event ? event.target.result : from

                    // Read image colors on load
                    Vibrant
                        .from(dataUri)
                        .getPalette()
                        .then(palette => {

                            this.palette = []
                            for (let vibrant in palette) {

                                // Continue for loop if invalid credentials
                                if (!palette.hasOwnProperty(vibrant)) continue
                                if (palette[vibrant] === null) continue

                                this.palette.push(palette[vibrant].getHex())
                            }

                            resolve(this.palette)
                        })
                }

                if (typeof from === 'string') {
                    readColors()
                } else {

                    //Set reader and onload event
                    const file = from instanceof Blob || from instanceof File ? from : from[0]
                    const reader = new FileReader()
                    reader.onload = readColors

                    // Read first file as image and wait for onload event
                    reader.readAsDataURL(file)
                }
            })
        }
    },
    watch: {
//			prepopulate: function () {
//				this.update();
//			},
        suggestions(value) {
            this.palette = value
        },
        color(value) {
            if (this.palette.indexOf(value) === -1) {
                this._customColor = value
            }
        },
        palette(value) {
            this.$emit('change-palette', value)
        }
    },
})
</script>

<style>
.colorpicker-suggested-colors {
    display: inline;
}
</style>
