// api calls
import { entitiesApi as api } from '../../api/Entities'

// state -> info about way of storing data; https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
const state = {
    entities:   {},
    entityList: []
}

// getters
const getters = {
    all:       state => state.entityList.map(entityId => state.entities[entityId]),
    getByRole: (state) => (role) => {
        return state.entityList
           .map(entityId => state.entities[entityId])
           .filter(entity => entity[role] === 1)
    },
    getById:   (state) => (id) => {
        return state.entities[id]
    },
}

// actions
const actions = {
    loadAll ({commit}) {
        return new Promise((resolve, reject) => {
            api.getEntities()
               .then(({data}) => {
                   commit('setEntities', {
                       entities: data.data
                   })
                   resolve()
               })
        })
    },
    add ({commit}, payload) {
        // The server post should be handled by g-form
        commit('addEntity', payload)
    },
    update ({commit}, payload) {
        // The server patch should be handled by g-form
        commit('updateEntity', payload)
    },
    delete ({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.deleteEntity(payload.id)
               .then(({data}) => {
                   commit('deleteEntity', payload)
                   resolve()
               })
        })
    }
}

// mutations
const mutations = {
    setEntities (state, payload) {
        state.entities   = payload.entities.reduce((obj, entity) => {
            obj[entity.id] = entity

            return obj
        }, {})
        state.entityList = payload.entities.map(entity => entity.id)
    },
    addEntity (state, payload) {
        state.entities[payload.entity.id] = payload.entity
        state.entityList.push(payload.entity.id)
    },
    updateEntity (state, payload) {
        state.entities[payload.entity.id] = payload.entity
    },
    deleteEntity (state, payload) {
        delete state.entities[payload.id]
        state.entityList = state.entityList.filter(id => id !== payload.id)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
