<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('admin.users.popup.title') }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :form="form"
                            :action="`/api/admin/users/${this.id}`"
                            method="PATCH"
                            prepopulate="true">

                        <div class="popup-body popup-rows" v-if="form.id !== null">

                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <label>{{ $t('admin.users.popup.name') }}</label>
                                </div>
                                <div class="column">
                                    <g-input name="first_name" :label="$t('admin.users.popup.first_name')"/>
                                    <g-input name="last_name" :label="$t('admin.users.popup.last_name')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <label>{{ $t('admin.users.popup.email') }}</label>
                                </div>
                                <div class="column">
                                    <g-input name="email" :label="$t('admin.users.popup.email')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <label>{{ $t('admin.users.popup.phone') }}</label>
                                </div>
                                <div class="column">
                                    <g-input name="phone" :label="$t('admin.users.popup.phone')"/>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <label>{{ $tc('admin.users.company', form.companies.length) | capitalize }}</label>
                                </div>

                                <div class="column">
                                    <template v-if="form.companies.length === 0">
                                        <div class="field">
                                            {{ $t('admin.users.popup.no_companies') }}
                                        </div>
                                    </template>

                                    <div class="field" :key="index" v-for="(company, index) in form.companies"
                                         :class="[ index === 0 ? 'mt-2' : 'mt-5' ]" v-else>
                                        <strong class="is-flex is-align-items-center is-justify-content-space-between">
                                            {{ company.name }}
                                            <a class="button is-medium" @click="unlinkCompany(company, index)">
                                                <i class="icon is-danger">clear</i>
                                            </a>
                                        </strong>
                                        <div class="permissions mb-2">
                                        <span class="no-permissions"
                                              v-if="parsePermissions(company.permissions).length < 1">
                                            {{ $t('account.no_permissions') }}
                                        </span>
                                            <span v-else v-for="permission in parsePermissions(company.permissions)"
                                                  :class="getPermissionClass(permission)">
                                            {{ $t(`employees.permissions.${permission}`) }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <label>{{ $t('admin.users.popup.action') }}</label>
                                </div>

                                <div class="column" id="user-form-buttons">
                                    <button class="button mb-2" @click.prevent="deactivateAccount">
                                        <i class="icon">delete</i>
                                        <span>{{ $t('admin.users.popup.deactivate') }}</span>
                                    </button>
                                    <br />

                                    <button class="button mb-2" @click.prevent="resetPassword">
                                        <i class="icon">restart_alt</i>
                                        <span>{{ $t('admin.users.popup.password_reset') }}</span>
                                    </button>
                                    <br />

                                    <button class="button" @click.prevent="impersonateUser" :disabled="form.companies.length === 0">
                                        <i class="icon">exit_to_app</i>
                                        <span>{{ $t('admin.users.popup.impersonate_user') }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('admin.users.popup.cancel') }}</a>
                            <g-button>{{ $t('admin.users.popup.save') }}</g-button>
                        </div>

                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        id: String
    },

    data() {
        const formData = {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            phone: null,
            companies: null,
        }

        return {
            form: new Form(formData)
        }
    },

    methods: {
        impersonateUser() {
            axios.post(`/api/admin/impersonation/${this.id}`)
                 .then(() => {
                     this.destroy()
                     this.$router.push({ name: 'settings.company.details' })
                     this.$nextTick(() => {
                         this.$store.dispatch('user/getUserData')
                     })
                 })
        },

        unlinkCompany(company, index) {
            this.$buefy.dialog.confirm({
                title: this.$t('admin.users.popup.unlink_company'),
                message: this.$t('admin.users.popup.unlink_company_message', {name: company.name}),
                confirmText: this.$t('admin.users.popup.unlink_company_button'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post(`/api/admin/users/${this.id}/unlink/${company.id}`).then(() => {
                        this.$delete(this.form.companies, index)
                        this.$buefy.dialog.alert({
                            message: this.$t('admin.users.popup.unlink_company_confirmation', {
                                name: company.name
                            }),
                        })
                    })
                }
            })
        },

        deactivateAccount() {
            this.$buefy.dialog.confirm({
                title: this.$t('admin.users.popup.deactivate_title'),
                message: this.$t('admin.users.popup.deactivate_message'),
                confirmText: this.$t('admin.users.popup.deactivate_button'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    axios.post(`/api/admin/users/${this.id}/deactivate`).then(() => {
                        this.destroy()
                    })
                }
            })
        },

        resetPassword() {
            this.$buefy.dialog.confirm({
                title: this.$t('admin.users.popup.password_reset_title'),
                message: this.$t('admin.users.popup.password_reset_message'),
                confirmText: this.$t('admin.users.popup.password_reset_button'),
                type: 'is-success',
                hasIcon: true,
                onConfirm: () => {
                    axios.post(`/api/admin/users/${this.id}/reset-password`).then(() => {
                        this.$buefy.dialog.alert({
                            title: this.$t('admin.users.popup.password_reset_confirmation_title'),
                            message: this.$t('admin.users.popup.password_reset_confirmation_message')
                        })
                    })
                }
            })
        },

        getPermissionClass(permission) {
            const classList = {
                administrator: 'pill black',
                financials: 'pill yellow',
                giftcard_issue: 'pill blue',
                giftcard_redeem: 'pill green',
                statistics: 'pill aqua',
            }

            return classList[permission]
        },

        parsePermissions(data) {
            const permissionFields = (({administrator, financials, statistics, giftcard_redeem, giftcard_issue, giftcard_mark_promotional}) => (
                {administrator, financials, statistics, giftcard_redeem, giftcard_issue, giftcard_mark_promotional}
            ))(data);

            // Prevent administrator accounts from listing all other permissions
            if (permissionFields.administrator === true) {
                return ['administrator']
            }

            return Object.entries(permissionFields).filter(([x, value]) => {
                return value
            }).map(([permission, x]) => {
                return permission
            })
        }
    },

    filters: {
        capitalize: function (value) {
            if (! value) {
                return ''
            }

            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        }
    }
}
</script>
