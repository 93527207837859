<template>
  <div class="accordeon-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Accordeon",
}
</script>

<style scoped lang="scss">
.accordeon-group {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
</style>
