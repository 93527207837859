import Vue from 'vue'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import Errors from './Errors'

class MaxDepthExceededError extends Error {
    constructor(message = 'Maximum nesting depth of 10 levels exceeded') {
        super(message);
        this.name = 'MaxDepthExceededError';
    }
}

class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     * @param {object} options
     */
    constructor(data, options = {}) {
        this.activeLocale = null
        this.translatableFields = new Map()
        this.availableLocales = options.locales || []

        this.originalData = this.processTranslatableFields(data)

        // Initialize all fields as reactive properties
        const fields = {}
        for (let field in this.originalData) {
            fields[field] = this.originalData[field]
        }

        // Use Vue.set to ensure reactivity
        Object.keys(fields).forEach(key => {
            Vue.set(this, key, fields[key])
        })

        this.errors = new Errors()
        this.config = {}
    }

    /**
     * Process the initial data and expand translatable fields
     * @param {object} data
     * @returns {object}
     */
    processTranslatableFields(data) {
        const processedData = {}

        for (const [key, value] of Object.entries(data)) {
            processedData[key] = value

            if (value !== null && typeof value === 'object' && value.translatable === true) {
                this.translatableFields.set(key, {
                    useFallback: value.useFallback ?? true,
                    default: value.default || ''
                })

                // Well set the original key name to the default value as fallback and to prevent sending the configuration props
                processedData[key] = value.default || ''

                this.availableLocales.forEach(locale => {
                    let defaultVal = value.default || '';

                    // If default is an object, get the right value for the current locale or fallback
                    if (isObject(defaultVal)) {
                        defaultVal = defaultVal[locale] || ''
                    }

                    const localizedKey = `${key}.${locale}`
                    processedData[localizedKey] = defaultVal
                })
            }
        }

        return processedData
    }

    /**
     * Check if a field is translatable
     * @param {string} fieldName
     * @returns {boolean}
     */
    isFieldTranslatable(fieldName) {
        return this.translatableFields.has(fieldName)
    }

    /**
     * Set the active locale
     * @param {string} locale
     */
    setLocale(locale) {
        this.activeLocale = locale
    }

    async addField(field, value) {
        Vue.set(this.originalData, field, value)
        Vue.set(this, field, value)
    }

    async removeField(field) {
        Vue.delete(this.originalData, field)
        Vue.delete(this, field)
    }

    /**
     * Convert array notation to dot notation
     * e.g., response['parameters']['sender_name'] -> 'parameters.sender_name'
     */
    arrayToDotNotation(obj, prefix = '') {
        let result = {};

        const processValue = (value, key, currentPrefix) => {
            const newKey = currentPrefix
                ? `${currentPrefix}.${!isNaN(key) ? key : key}`
                : key;

            if (value && typeof value === 'object' && !(value instanceof File) && !(value instanceof Blob)) {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        if (typeof item === 'object') {
                            Object.assign(result, this.arrayToDotNotation(item, `${newKey}.${index}`));
                        } else {
                            result[`${newKey}.${index}`] = item;
                        }
                    });
                } else {
                    Object.assign(result, this.arrayToDotNotation(value, newKey));
                }
            } else {
                result[newKey] = value;
            }
        };

        for (const key in obj) {
            if (!obj.hasOwnProperty(key)) continue;
            processValue(obj[key], key, prefix);
        }

        return result;
    }

    /**
     * Convert dot notation to array notation
     * e.g., 'parameters.sender_name' -> parameters[sender_name]
     */
    dotToArrayNotation(path) {
        if (path.includes('[')) return path;
        return path.replace(/\.(\w+)/g, '[$1]');
    }

    prepopulate(action) {
        return new Promise((resolve, reject) => {
            axios.get(action)
                .then(async (response) => {
                    await this.setFormData(response);

                    resolve(response)
                })
        })

    }

    setFormData(response) {
        return new Promise((resolve) => {
            let responseData = response.data.hasOwnProperty('data') ? response.data.data : response.data;

            // Convert response data to dot notation
            const flattenedData = this.arrayToDotNotation(responseData);

            // Update form fields that exist in originalData
            for (let property in this.originalData) {
                // Check if the flat or nested version of the property exists in the response
                const value = flattenedData[property] ?? responseData[property];
                if (value !== undefined) {
                    Vue.set(this, property, value);
                }
            }

            response.data = responseData;

            resolve(response);
        });
    }

    /**
     * Helper method to apply fallbacks to translatable fields to another locale
     * @param data
     * @returns {*}
     */
    applyFallbacks(data) {
        const result = {...data};

        for (const [fieldName, config] of this.translatableFields.entries()) {
            if (!config.useFallback) continue;

            this.availableLocales.forEach(locale => {
                const key = `${fieldName}.${locale}`;
                const value = result[key];

                if (!value || value.trim() === '') {
                    const englishKey = `${fieldName}.en`;
                    if (locale !== 'en' && result[englishKey]) {
                        result[key] = result[englishKey];
                        return;
                    }

                    for (const fallbackLocale of this.availableLocales) {
                        const fallbackKey = `${fieldName}.${fallbackLocale}`;
                        if (result[fallbackKey] && result[fallbackKey].trim() !== '') {
                            result[key] = result[fallbackKey];
                            return;
                        }
                    }
                }
            });
        }

        return result;
    }

    data(request) {
        const MAX_DEPTH = 10;
        let formData = new FormData();

        formData.append('_method', request.toUpperCase());

        const appendNestedValue = (value, propertyPath, depth = 0) => {
            if (depth > MAX_DEPTH) {
                throw new MaxDepthExceededError();
            }

            // Convert dot notation to array notation for the form field name
            const formattedPath = this.dotToArrayNotation(propertyPath);

            if (value instanceof Blob || value instanceof File) {
                formData.append(formattedPath, value, value.name);
                return;
            }

            if (typeof value === 'undefined') {
                return;
            }

            if (typeof value !== 'string' && value === null) {
                formData.append(formattedPath, '');
                return;
            }

            if (isArray(value)) {
                value.forEach((item, index) => {
                    const newPath = `${formattedPath}[${index}]`;
                    appendNestedValue(item, newPath, depth + 1);
                });
                return;
            }

            if (isObject(value)) {
                Object.entries(value).forEach(([key, val]) => {
                    const newPath = formattedPath ? `${formattedPath}[${key}]` : key;
                    appendNestedValue(val, newPath, depth + 1);
                });
                return;
            }

            formData.append(formattedPath, value);
        };

        // Apply fallbacks before processing
        const dataWithFallbacks = this.applyFallbacks(this);

        // Process main data
        for (let property in this.originalData) {
            if (!this.originalData.hasOwnProperty(property)) {
                continue;
            }

            // Don't send the 'root' field of a translatable field
            if (this.isFieldTranslatable(property)) {
                continue;
            }

            appendNestedValue(dataWithFallbacks[property], property);
        }

        return formData;
    }

    setErrors(errors = {}) {
        this.errors = new Errors(errors)
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = ''
        }

        this.errors.clear()
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url)
    }

    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url)
    }

    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url)
    }

    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url)
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        return new Promise((resolve, reject) => {
            axios.post(url, this.data(requestType), this.config)
                .then((response) => {
                    this.onSuccess(response.data)

                    resolve(response.data)
                })
                .catch(error => {
                    this.onFail(error.response.data)

                    reject(error)
                })
        })
    }

    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     */
    onSuccess(data) {

    }

    /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail(errors) {
        this.errors.clear(null)

        if (typeof errors.errors !== 'undefined') {
            this.errors.record(errors.errors)
        }
    }
}

export default Form
