import includes from 'lodash/includes'
import Cropper from '../components/Design/Builder/Cropper'

export default {
    components: {Cropper},
    props:      {
        name:           {
            required: true,
            type:     String,
        },
        label:          {
            type: String,
        },
        maxFileSize:    {
            type: Number,
        },
        ratio:          {
            type: Number,
        },
        cropperOptions: {
            required: true,
            type:     Object,
        },
    },
    data () {
        return {
            form:          {},
            cropper:       null,
            reader:        new FileReader(),
            hasImage:      false,
            dragDropFocus: false,
            warnings:      [],
        }
    },
    computed:   {
        parsedFileSize () {
            return this.maxFileSize > 1000 ? Math.floor(this.maxFileSize / 1000) + ' MB' : this.maxFileSize + ' KB'
        },
        file: {
            get () {
                return typeof this.form[this.name] === 'string' ? [] : this.form[this.name]
            },
            set (newValue) {
                this.$emit('input', newValue)
                this.form[this.name] = newValue
            },
        },
    },
    watch:      {},
    mounted () {
        this.form          = this.$parent.$props.form
        this.reader.onload = this.readImage
    },
    methods:    {

        /**
         * Check if it is a valid image
         *
         * Empty function used for overwriting in child Components
         *
         * @param file
         * @param event
         * @returns {boolean}
         */
        validateImage (file, event) {
            return true
        },

        _validateImage (image, event) {
            if (image === undefined) {
                return false
            }

            /// Check if Mime type contains image
            if (image.type.indexOf('image') === -1) {

                this.$buefy.toast.open({
                    message: 'Dit is geen geldige afbeelding!',
                    type:    'is-danger',
                })

                if (typeof event.preventDefault === 'function') {
                    event.preventDefault()
                }

                return false
            }

            return true
        },

        /**
         *
         * @param image
         */
        setWarnings (image) {
            this.warnings = []
            const img     = new Image()

            img.src = URL.createObjectURL(image)

            img.onload = () => {
                const width  = img.naturalWidth,
                      height = img.naturalHeight
                if (image.type.indexOf('svg') === -1 && width < this.cropperOptions.width && height < this.cropperOptions.height) {
                    this.warnings.push(`Het lijkt er op dat deze afbeelding niet van voldoende kwaliteit is. De afbeelding zal daardoor minder scherp zijn op de cadeaubon. De geschikte afmetingen zijn tenminste ${this.cropperOptions.width}x${this.cropperOptions.height}.`)
                }

                URL.revokeObjectURL(img.src)
            }
        },

        /**
         * Update the highlight around the uploader when dragging a file over it
         * @param value
         */
        updateDragDropFocus (value) {
            this.dragDropFocus = value
        },

        /**
         * Upload event
         * @param event
         */
        uploadImage (event) {
            this.updateDragDropFocus(false)
            let value

            if (!!event[0] && Blob && event[0] instanceof Blob) {
                // If event is standard upload event

                value = event
            } else if (event instanceof Blob || event instanceof File) {
                // If event is a File

                value = [event]
            } else { // If event is a drag and drop event

                value = event.target.files || event.dataTransfer.files
            }

            const printFileTypes = [
                'application/postscript',
                'application/eps',
                'application/x-eps',
                'image/eps',
                'image/x-eps',
            ]

            if (includes(printFileTypes, value[0].type) ||
               includes(value[0].type, 'photoshop') ||
               includes(value[0].name, '.psd') ||
               includes(value[0].type, 'psd') ||
               includes(value[0].type, 'pdf')) {

                if (value[0].size / 1000 >= this.maxFileSize) {

                    this.$buefy.toast.open({
                        message: 'Dit bestand is te groot',
                        type:    'is-danger',
                    })

                    event.preventDefault()
                    return false
                }

                const toast = this.$buefy.toast.open({
                    message:  'Even geduld, drukwerkbestand wordt omgezet!',
                    type:     'is-info',
                    duration: 10 * 1000,
                })

                const data = new FormData()
                data.append('image', value[0])

                const config = {
                    headers:      {'content-type': 'multipart/form-data'},
                    responseType: 'blob',
                }

                axios.post('/api/builder/convert', data, config).then(r => {
                    const blob = new Blob([r.data], {type: 'image/png'})

                    this.$emit('change', blob)
                    this.reader.readAsDataURL(blob)
                    this.setWarnings(blob)
                    toast.close()
                })

                return true
            }

            if (this._validateImage(value[0], event) && this.validateImage(value[0], event)) {
                this.$emit('change', value[0])
                this.reader.readAsDataURL(value[0])
                this.setWarnings(value[0])

                if (typeof this.saveImageToLibrary === 'function') {
                    this.saveImageToLibrary(value[0])
                }
            }
            event.target.value = ''
        },

        /**
         * Delete image event
         * @param file
         */
        deleteImage (file = this.file) {

            this.file     = null
            this.hasImage = false
            this.warnings = []

            this.$emit('delete', file)
        },

        /**
         * Sets the cropper event chain in motion
         *
         * @link Event chain: ../Builder/Cropper.vue on line 42
         * @param imageUrl
         */
        readImage (imageUrl) {
            this.hasImage = true

            let image = typeof imageUrl === 'string' ? imageUrl : imageUrl.target.result || imageUrl
            return new Promise(resolve => {
                this.$refs.cropper.load(image).then(() => {
                    resolve(image)
                })
            })
        },

        /**
         * Sets file value to the new cropped canvas
         * Gets called after the @crop event
         * @param canvas
         */
        crop (canvas) {
            if (!this.hasImage) {
                return
            }
            this.file = canvas
        },

    },
}
