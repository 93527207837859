// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../images/layout/blueprint-bg.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab-content[data-v-d8f4c27a]{display:flex;flex:1;flex-direction:column;max-height:100%}.tab-content .tab-header .header[data-v-d8f4c27a]{background-color:#fff;border-bottom:.063rem solid #efefef;font-size:.875rem;padding:.8rem 1rem;width:100%}.tab-content.justify[data-v-d8f4c27a]{justify-content:space-between}.blueprint-bg[data-v-d8f4c27a]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat #f6f9fb;background-blend-mode:darken;background-position-x:-5px}", "",{"version":3,"sources":["webpack://./resources/assets/js/components/Popup/TabbedViewItem.vue"],"names":[],"mappings":"AACA,8BAEI,YAAA,CADA,MAAA,CAEA,qBAAA,CACA,eAAJ,CAEI,kDACI,qBAAA,CAIA,mCAAA,CAFA,iBAAA,CACA,kBAAA,CAFA,UAGR,CAGI,sCACI,6BADR,CAKA,+BACI,iEAAA,CACA,4BAAA,CAGA,0BAJJ","sourcesContent":["\n.tab-content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    max-height: 100%;\n\n    .tab-header .header {\n        background-color: #ffffff;\n        width: 100%;\n        font-size: 0.875rem;\n        padding: 0.8rem 1rem;\n        border-bottom: 0.063rem solid #efefef;\n    }\n\n    &.justify {\n        justify-content: space-between;\n    }\n}\n\n.blueprint-bg {\n    background: url('../../../images/layout/blueprint-bg.svg') repeat #F6F9FB;\n    background-blend-mode: darken;\n\n    // The background starts with a border on the left side. This can look funny if the container itself also has a border\n    background-position-x: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
