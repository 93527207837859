<template>
    <ModalLayout
        visible
        @destroy="onModalClose"
        @success="onModalSubmit"
        :success-loading="loading"
        :tabs="tabItems"
        :tabActive="activeTab"
        @tabSelected="(tab) => activeTab = tab"
    >
        <template v-slot:title>{{ $t('action_codes.form.title') }}</template>

        <template v-slot:settingArea>
            <p>{{ $t('action_codes.form.subtitle') }}</p>

            <g-form :form="form" :action="formUrl" class="form-container"
                    v-slot="{ activeLocale, changeLocale, availableLocales }"
                    :method="formMethod"
                    :prepopulate="formMethod === 'patch'"
                    :before="parseFormData"
                    @prepopulated="parseExistingFormData"
                    @loading-state="(state) => loading = state" ref="formRef"
                    @fail="setActiveStepForErrors"
                    @success="formSuccess">
                <accordeon-item
                    :title="$t('action_codes.form.general_settings')"
                    :top-title="$t('accordeon.step', [1])"
                    :active="activeStep === 1"
                    @select="selectStep(1)"
                >
                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                        <div>
                            <label class="group-label">{{ $t('action_codes.form.label.action_type') }}</label>
                            <div class="field-body">
                                <g-select name="type" :label="$t('action_codes.form.type')" :options="actionOptions"
                                          disabled
                                          readonly/>
                                <g-input name="name" :label="$t('action_codes.form.name')"/>
                            </div>

                            <label class="group-label">{{ $t('action_codes.form.label.action_code') }}</label>
                            <g-input name="code" :label="$t('action_codes.form.code')"/>

                            <label class="group-label">{{ $t('action_codes.form.label.usage_limit') }}</label>
                            <g-input type="number" name="max_uses" :label="$t('action_codes.form.max_uses')"/>

                            <label class="group-label">{{ $t('action_codes.form.label.validity') }}</label>
                            <div class="field-body">
                                <g-datepicker name="valid_from" :label="$t('action_codes.form.valid_from')"/>
                                <g-datepicker name="valid_until" :label="$t('action_codes.form.valid_until')"
                                              disable-past/>
                            </div>
                            <g-switch name="is_active" :label="$t('action_codes.form.active')"/>
                        </div>

                        <div>
                            <g-button @click.prevent="activeStep++" :is-rounded="false" class="is-pulled-right">
                                {{ $t('accordeon.next') }}
                            </g-button>
                        </div>
                    </div>
                </accordeon-item>

                <accordeon-item
                    :title="$t('action_codes.form.type_gift_card')"
                    :top-title="$t('accordeon.step', [2])"
                    :active="activeStep === 2"
                    @select="selectStep(2)">
                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                        <div>
                            <label class="group-label">
                                {{ $t('action_codes.form.label.reward') }}
                                <small>{{ $t('action_codes.form.label.reward_subtitle') }}</small>
                            </label>
                            <div class="field-body">
                                <g-select name="parameters.reward_type"
                                          :label="$t('action_codes.form.reward_type')"
                                          :options="rewardOptions"/>
                                <g-format
                                    key="g-format-reward_value_fixed_amount"
                                    v-if="form['parameters.reward_type'] === 'fixed_amount'"
                                    name="parameters.reward_value_fixed_amount"
                                    type="currency"
                                    :label="$t('action_codes.form.reward_value')"/>
                                <g-format
                                    key="g-format-reward_value_percentage"
                                    v-if="form['parameters.reward_type'] === 'percentage'"
                                    name="parameters.reward_value_percentage"
                                    type="percentage"
                                    :label="$t('action_codes.form.reward_value')"/>
                                <g-select
                                    v-if="form['parameters.reward_type'] === 'package'"
                                    name="parameters.reward_value_package"
                                    :options="packageOptions"
                                    :label="$t('action_codes.form.reward_value')"/>
                            </div>

                            <g-select :options="expirationOptions" name="parameters.reward_expiration"
                                      :label="$t('action_codes.form.expiration')"/>

                            <label class="group-label">
                                {{ $t('action_codes.form.label.qualification_criteria') }}
                                <small>{{ $t('action_codes.form.label.qualification_criteria_subtitle') }}</small>
                            </label>
                            <div class="field-body">
                                <g-select name="parameters.criteria.0.type"
                                          :label="$t('action_codes.form.criteria_type')"
                                          :options="criteriaOptions"/>
                                <g-format
                                    v-if="form['parameters.criteria.0.type'] === 'min_amount'"
                                    name="parameters.criteria.0.minimum_amount"
                                    type="currency"
                                    :label="$t('action_codes.form.criteria_value_min_gift_card_amount')"/>
                                <g-select
                                    v-if="form['parameters.criteria.0.type'] === 'package'"
                                    name="parameters.criteria.0.package_ids"
                                    :options="packageOptions"
                                    :label="$t('action_codes.form.criteria_value_is_package')" :multiple="true"/>
                            </div>

                            <label class="group-label">
                                {{ $t('action_codes.form.label.reporting_labels') }}
                                <small>{{ $t('action_codes.form.label.reporting_labels_subtitle') }}</small>
                            </label>
                            <tags-input v-model="editableTag"
                                        :tags="form['parameters.reporting_tags']"
                                        :add-on-key="[';', ',', 13]"
                                        :max-tags="10"
                                        :placeholder="$t('action_codes.form.label.reporting_labels')"
                                        :autocomplete-items="tagsAutocomplete"
                                        :autocomplete-min-length="0"
                                        autocomplete="off"
                                        class="mb-3"
                                        @tags-changed="newTags => form['parameters.reporting_tags'] = newTags"/>

                        </div>

                        <div>
                            <g-button @click.prevent="activeStep++" :is-rounded="false" class="is-pulled-right">
                                {{ $t('accordeon.next') }}
                            </g-button>
                        </div>
                    </div>
                </accordeon-item>

                <accordeon-item
                    :title="$t('action_codes.form.email_settings')"
                    :top-title="$t('accordeon.step', [3])"
                    :active="activeStep === 3"
                    @select="selectStep(3)">

                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                        <div>
                            <label class="group-label">{{ $t('action_codes.form.label.modify_language') }}</label>
                            <g-select
                                name="locale"
                                :value="activeLocale"
                                @input="changeLocale"
                                :options="availableLocales"
                            />

                            <label class="group-label">{{ $t('action_codes.email.sender') }}</label>
                            <div class="field-body">
                                <g-input name="parameters.sender_name" :label="$t('action_codes.email.name')"/>
                                <g-input name="parameters.sender_email" :label="$t('action_codes.email.email')"/>
                            </div>

                            <label class="group-label">
                                {{ $t('action_codes.email.subject') }}
                            </label>
                            <g-input name="parameters.subject" :label="$t('action_codes.email.subject')" translatable/>

                            <label class="group-label">
                                {{ $t('action_codes.email.message') }}
                                <small>
                                    {{ $t('action_codes.email.message_notice') }}
                                </small>
                            </label>
                            <g-input type="textarea" name="parameters.message" translatable
                                     :label="$t('action_codes.email.message_contents')" autosize/>
                        </div>
                    </div>
                </accordeon-item>

                <!-- Todo: enable rules again in future. Rules should apply on the entire order. Like "min order amount of X". Or "first time customer" -->
                <!--                <accordeon-item-->
                <!--                    :title="$t('action_codes.form.rule_settings')"-->
                <!--                    :top-title="$t('accordeon.step', [4])"-->
                <!--                    :active="activeStep === 4"-->
                <!--                    @select="selectStep(4)">-->
                <!--                    <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">-->
                <!--                        <div>-->
                <!--                            <label class="group-label">-->
                <!--                                {{ $t('action_codes.form.label.validity_rules') }}-->
                <!--                                <small>{{ $t('action_codes.form.label.validity_rules_subtitle') }}</small>-->
                <!--                            </label>-->
                <!--                            <div class="field-body">-->
                <!--                                <g-select name="rules.0.type"-->
                <!--                                          :label="$t('action_codes.form.rule_type')"-->
                <!--                                          :options="ruleOptions"/>-->
                <!--                                <g-format-->
                <!--                                    v-show="form['rules.0.type'] === 'gift_card_amount'"-->
                <!--                                    name="rules.0.parameters.minimum_amount"-->
                <!--                                    type="currency"-->
                <!--                                    :label="$t('action_codes.form.rule_value_min_gift_card_amount')"/>-->
                <!--                                <g-select-->
                <!--                                    v-show="form['rules.0.type'] === 'gift_card_package'"-->
                <!--                                    name="rules.0.parameters.package_ids"-->
                <!--                                    :options="packageOptions"-->
                <!--                                    :label="$t('action_codes.form.rule_value_is_package')"/>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </accordeon-item>-->
            </g-form>
        </template>

        <template v-slot:previewArea>
            <tabbed-view @tabsInitialized="(tabs) => tabItems = tabs" ref="tabbedView">
                <tabbed-view-item
                    :title="$t('action_codes.form.preview')"
                    :active="activeTab === 0"
                >
                    <email-view-item
                        :sender-name="form['parameters.sender_name']"
                        :sender-email="form['parameters.sender_email']"
                        :subject="form[`parameters.subject.${form.activeLocale}`]"
                        :attachments="[$t('action_codes.email.sample.attachment')]"
                    >
                        <template v-slot:header v-if="companyLogo">
                            <img :src="companyLogo" alt="Company logo"/>
                        </template>
                        <template v-slot:body>{{ form[`parameters.message.${form.activeLocale}`] }}</template>
                    </email-view-item>
                </tabbed-view-item>
            </tabbed-view>
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from "../ModalLayout.vue";
import {mapGetters} from "vuex";
import EmailViewItem from "./DigitalOrders/EmailViewItem.vue";
import TabbedViewItem from "../TabbedViewItem.vue";
import TabbedView from "../TabbedView.vue";
import AccordeonItem from "../AccordeonItem.vue";
import TagsInput from "@johmun/vue-tags-input";

export default {
    components: {AccordeonItem, TabbedView, TabbedViewItem, EmailViewItem, ModalLayout, TagsInput},
    props: {
        id: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters({
            packages: 'packages/all',
            reportingTags: 'promotionalOrders/getReportingTags',
            locales: 'application/availableLocalesTranslated',
        }),
        companyLogo() {
            return this.$store.getters['user/activeCompany'].logo;
        },
        formUrl() {
            if (this.id) {
                return `/api/action_code/${this.id}`;
            }

            return '/api/action_code';
        },
        formMethod() {
            if (this.id) {
                return 'patch';
            }

            return 'post';
        },
        actionOptions() {
            return [
                {
                    name: this.$t('action_codes.form.type_gift_card'),
                    value: 'bonus_gift_card'
                }
            ]
        },
        packageOptions() {
            return this.packages.map((option) => {
                return {
                    name: option.title,
                    value: option.id
                }
            });
        },
        rewardOptions() {
            let options = [
                {value: 'fixed_amount', name: this.$t('action_codes.form.reward_type_fixed_amount')},
                {value: 'percentage', name: this.$t('action_codes.form.reward_type_percentage')},
            ]

            if (this.packages.length > 0) {
                options.push({
                    value: 'package',
                    name: this.$t('action_codes.form.reward_type_package')
                })

                this.form.reward_value_package = this.packages[0].id
            }

            return options;
        },
        expirationOptions() {
            const monthsOptions = Array.from({length: 11}, (v, k) => ({
                name: this.$tc('action_codes.form.expiration_months', k + 1),
                value: 30 * (k + 1),
            }));

            const yearsOptions = Array.from({length: 10}, (v, k) => ({
                name: this.$tc('action_codes.form.expiration_years', k + 1),
                value: 365 * (k + 1),
            }));

            return [...monthsOptions, ...yearsOptions];
        },
        criteriaOptions() {
            let options = [
                {value: 'min_amount', name: this.$t('action_codes.form.criteria_type_min_gift_card_amount')},
            ]

            if (this.packages.length > 0) {
                options.push({
                    value: 'package',
                    name: this.$t('action_codes.form.criteria_type_is_package')
                })
            }

            return options;
        },
        tagsAutocomplete() {
            return this.reportingTags.map(tag => {
                return {
                    text: tag,
                }
            })
        },
    },
    data() {
        return {
            loading: false,
            activeTab: 0,
            activeStep: 1,
            tabItems: [],
            editableTag: '',
            fieldStepMap: {
                1: [
                    'type',
                    'name',
                    'code',
                    'valid_from',
                    'valid_until',
                    'max_uses',
                    'is_active',
                ],
                2: [
                    'parameters.reward_type',
                    'parameters.reward_value_fixed_amount',
                    'parameters.reward_value_percentage',
                    'parameters.reward_value_package',
                    'parameters.reward_expiration',
                    'parameters.criteria.0.type',
                    'parameters.criteria.0.minimum_amount',
                    'parameters.criteria.0.package_ids',
                    'parameters.reporting_tags',
                ],
                3: [
                    'parameters.sender_name',
                    'parameters.sender_email',
                    'parameters.subject.nl',
                    'parameters.message.nl',
                    'parameters.subject.en',
                    'parameters.message.en',
                ],
            },
            form: new Form({
                locale: this.$i18n.locale,
                type: 'bonus_gift_card',
                name: '',
                code: '',
                valid_from: new Date(),
                valid_until: '',
                max_uses: 500,
                is_active: true,

                'parameters.reporting_tags': [],

                'parameters.reward_type': 'fixed_amount',
                'parameters.reward_value_fixed_amount': '',
                'parameters.reward_value_percentage': '',
                'parameters.reward_value_package': '',
                'parameters.reward_expiration': this.$store.getters['user/activeCompany'].gift_card_expiration,

                'parameters.criteria.0.type': 'min_amount',
                'parameters.criteria.0.minimum_amount': '',
                'parameters.criteria.0.package_ids': [],

                'parameters.sender_name': '',
                'parameters.sender_email': '',
                'parameters.subject': {
                    translatable: true,
                    useFallback: true,
                    default: {
                        nl: this.$t('action_codes.email.sample.subject', 'nl'),
                        en: this.$t('action_codes.email.sample.subject', 'en')
                    }
                },
                'parameters.message': {
                    translatable: true,
                    useFallback: true,
                    default: {
                        nl: this.$t('action_codes.email.sample.message', 'nl'),
                        en: this.$t('action_codes.email.sample.message', 'en')
                    }
                }
            }, {
                locales: this.$store.getters['application/availableLocales']
            })
        }
    },
    created() {
        // Load related data for the form
        this.$store.dispatch('packages/loadAll').then(() => {
            if (this.packages.length > 0) {
                this.form['parameters.reward_value_package'] = this.packages[0].id
            }
        })
        this.$store.dispatch('promotionalOrders/loadAll')

        // Set default form values
        this.form.code = this.generateRandomActionCode()

        this.form['parameters.sender_name'] = this.$store.getters['user/user'].first_name
        this.form['parameters.sender_email'] = this.$store.getters['user/user'].email
    },
    methods: {
        onModalSubmit() {
            this.$refs.formRef.submit()
        },
        onModalClose() {
            this.$emit('destroy');
        },
        selectStep(step) {
            if (this.activeStep === step) {
                step = step + 1;
            }

            if (step >= 5) {
                step = 1;
            }

            this.activeStep = step;
        },
        async parseFormData(form) {
            // Convert currency values to cents
            if (form['parameters.reward_value_fixed_amount']) {
                form['parameters.reward_value_fixed_amount'] = form['parameters.reward_value_fixed_amount'].replace(',', '.');
                form['parameters.reward_value_fixed_amount'] = parseFloat(form['parameters.reward_value_fixed_amount']) * 100;
            }

            if (form['parameters.criteria.0.minimum_amount']) {
                form['parameters.criteria.0.minimum_amount'] = form['parameters.criteria.0.minimum_amount'].replace(',', '.');
                form['parameters.criteria.0.minimum_amount'] = parseFloat(form['parameters.criteria.0.minimum_amount']) * 100;
            }

            // Format date objects to ISO
            form.valid_from = form.valid_from ? form.valid_from.toISOString() : null;
            form.valid_until = form.valid_until ? form.valid_until.toISOString() : null;

            // Format boolean to integer
            form.is_active = form.is_active ? 1 : 0;

            return form;
        },
        parseExistingFormData(response) {
            this.form['parameters.reward_value_fixed_amount'] /= 100;
            this.form['parameters.criteria.0.minimum_amount'] /= 100;
            this.form.valid_from = new Date(this.form.valid_from)
            this.form.valid_until = this.form.valid_until ? new Date(this.form.valid_until) : null

            // Manually set the package_ids as the component expects an array: data.data.parameters.criteria.0.package_ids
            this.form['parameters.criteria.0.package_ids'] = response.data.parameters.criteria[0].package_ids ?? [];

            // Manually set the reporting tags as the component expects an array: data.data.parameters.reporting_tags
            const tags = response.data.parameters.reporting_tags ?? [];
            this.form['parameters.reporting_tags'] = tags.map(tag => {
                return {
                    text: tag,
                }
            })
        },
        formSuccess() {
            this.$buefy.toast.open({
                message: this.$t('action_codes.toast.saved'),
                type: 'is-success',
                duration: 5000,
            })

            this.destroy()
        },
        setActiveStepForErrors(data) {
            // If data object does not contain an errors object, return
            if (!data.errors) {
                return;
            }

            let formErrorFields = [];

            // Loop through the errors object, it has the format of {field: [errors]}
            for (const field in data.errors) {
                // If the field is not in the form object, continue
                if (!this.form.hasOwnProperty(field)) {
                    continue;
                }

                // Add the field to the formErrorFields array
                formErrorFields.push(field);
            }

            let stepsWithErrors = [];

            // Loop through the fieldStepMap object
            for (const step in this.fieldStepMap) {
                // Loop through the fields in the fieldStepMap object
                for (const field of this.fieldStepMap[step]) {
                    // If the field is in the formErrorFields array, add the step to the stepsWithErrors array
                    if (formErrorFields.includes(field)) {
                        stepsWithErrors.push(parseInt(step));
                        break;
                    }
                }
            }

            // If the stepsWithErrors array is not empty, set the activeStep to the lowest step
            if (stepsWithErrors.length > 0) {
                this.activeStep = Math.min(...stepsWithErrors);
            }
        },
        generateRandomActionCode() {
            return 'AC' + Math.random().toString(36).substring(2, 8).toUpperCase();
        },
    }
}
</script>

<style scoped lang="scss">
.form-min-height {
    min-height: 100%;
}

.form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}
</style>
