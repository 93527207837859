<template>
    <div id="container">
        <router-view/>
    </div>
</template>

<script>
export default {

    mounted() {
        let lastVersionCheck = Date.now();
        const versionCheck = () => {
            lastVersionCheck = Date.now();

            this.$store.dispatch('application/retrieveLatestVersion')
        };

        // Check every 10 minutes by default
        let versionCheckInterval = setInterval(versionCheck, 600000);

        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                // If the page is focused, and the last check is older then 60 seconds, check version immediately
                if (((Date.now() - lastVersionCheck) / 1000) > 60) {
                    versionCheck()
                }

                versionCheckInterval = setInterval(versionCheck, 600000);
            } else {
                // Stop checking version updates in the background
                if (versionCheckInterval !== null) {
                    clearInterval(versionCheckInterval);
                }
            }
        })
    },
}
</script>

<style>
#container {
    height: 100%;
}
</style>
