class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor(errors = {}) {
        this.errors = errors
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * The API returns errors in two formats:
     * 'fieldname' => 'This field is required'
     * 'fieldname.1.name' => 'Fieldname 1 requires a name'
     *
     * @param {string} field
     */
    has(field) {

        // Check if the form field is an form array input (eg: order_personalization[1][consumer])
        if (field.indexOf('[') !== -1) {
            // Reformat the field (order_personalization[1][consumer]) to order_personalization.1.consumer
            field = field.replace(/\[/g, '.').replace(/\]/g, '')
        }

        const subFieldsContainingError = Object
            .keys(this.errors)
            .map((fieldName) => fieldName.startsWith(field + '.') || fieldName.startsWith(field + '['))

        return subFieldsContainingError.includes(true) || this.errors.hasOwnProperty(field)
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        // Check if the form field is an form array input (eg: order_personalization[1][consumer])
        if (field.indexOf('[') !== -1) {
            // Reformat the field (order_personalization[1][consumer]) to order_personalization.1.consumer
            field = field.replace(/\[/g, '.').replace(/\]/g, '')
        }

        if (this.errors[field]) {
            return this.errors[field][0]
        }

        const subFieldsContainingError = Object
            .keys(this.errors)
            .map((fieldName) => fieldName.startsWith(field + '.'))

        if (subFieldsContainingError.includes(true)) {
            const fieldIndex = subFieldsContainingError.indexOf(true)
            const fieldName = Object.keys(this.errors)[fieldIndex]

            return this.errors[fieldName][0]
        }
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record(errors) {
        this.errors = errors
    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field) {
        if (field) {
            field = field.substr('g-form-'.length)

            delete this.errors[field]

            return
        }

        this.errors = {}
    }
}

export default Errors
