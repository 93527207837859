import Vue from 'vue'
import moment from 'moment'

/* Format Giftcard code */
Vue.filter('formatcode', (code) => {
    let i     = '-'
    let one   = [code.slice(0, 4), i, code.slice(4)].join('')
    let two   = [one.slice(0, 9), i, one.slice(9)].join('')
    let final = [two.slice(0, 14), i, two.slice(14)].join('')
    return final
})

Vue.filter('localDateTime', function (value) {
    return moment(value).format('L LT')
})
