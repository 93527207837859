// api calls
import { locationsApi as api } from '../../api/Locations'

// state -> info about way of storing data; https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
const state = {
    objects:         {},
    objectList:      [],
    preferredObject: localStorage.getItem('location_preferred_object'),
}

// getters
const getters = {
    all:                  state => state.objectList.map(id => state.objects[id]),
    allIssuePoints:       state => state.objectList.map(id => state.objects[id]).filter((location) => {
        return !! location.can_issue === true
    }),
    allRedeemPoints:      state => state.objectList.map(id => state.objects[id]).filter((location) => {
        return !! location.can_redeem === true
    }),
    allIssuePointSelect:  state => state.objectList.map(id => state.objects[id]).filter(location => {
        return !! location.can_issue === true
    }).map(location => {
        return {
            value: location.id,
            name:  location.name,
        }
    }),
    allFormSelect:        state => state.objectList.map((id) => {
        return {
            value: id,
            name:  state.objects[id].name,
        }
    }),
    getById:              (state) => (id) => {
        return state.objects[id]
    },
    getPreferredObject:   (state) => state.preferredObject ? state.objects[state.preferredObject] : null,
    getPreferredObjectId: (state) => state.preferredObject,
}

// actions
const actions = {
    loadAll ({commit, state}) {
        return api.getAll()
            .then(({data}) => {
                commit('set', {
                    objects: data.data,
                })
            })
    },
    add ({commit}, payload) {
        // The server post should be handled by g-form
        commit('add', payload)
    },
    update ({commit}, payload) {
        // The server patch should be handled by g-form
        commit('update', payload)
    },
    updatePreferredObject ({commit}, locationId) {
        commit('updatePreferredObject', locationId)
    },
}

// mutations
const mutations = {
    set (state, payload) {
        state.objects    = payload.objects.reduce((obj, object) => {
            obj[object.id] = object

            return obj
        }, {})
        state.objectList = payload.objects.map(object => object.id)

        if (state.objectList.includes(state.preferredObject) === false) {
            state.preferredObject = null
        }
    },
    add (state, payload) {
        state.objects[payload.object.id] = payload.object
        state.objectList.push(payload.object.id)
    },
    update (state, payload) {
        state.objects[payload.object.id] = payload.object
    },
    updatePreferredObject (state, locationId) {
        state.preferredObject = locationId
        localStorage.setItem('location_preferred_object', locationId)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
