import store from '../store/'

export const authenticationApi = {

    login (email, password) {
        return axios.post('/auth/login', {
            email,
            password,
        }, {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    },

    logout () {
        return axios.post('/auth/logout', {}, {
            'Content-Type': 'application/json'
        })
    },

    adminLogout () {
        return axios.delete(`/api/admin/impersonation/${store.getters['user/activeCompanyId']}`, {}, {
            'Content-Type': 'application/json'
        })
    },

    user () {
        return axios.get('/auth/user', {
            'Content-Type': 'application/json'
        })
    },

}
