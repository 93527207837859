<template>
    <div class="notification is-warning is-radiusless is-marginless">
        <div><strong>{{ $t('application_outdated.warning') }}</strong> {{ $t('application_outdated.suggestion') }}</div>
    </div>
</template>

<script lang="ts">
export default {
    name: "ClientUpdateAvailable",
}
</script>
