<template>
    <div :class="['field', { 'error': hasError, 'animation': labelAnimation }]">
        <label class="label"
               :for="id"
               v-if="!labelAnimation && label">
            {{ labelText }}
        </label>

        <div :class="['control is-expanded', { 'has-icons-right': icon || hasError }]">
            <div class="select select--advanced is-fullwidth">
                <div
                    @click="toggleDropdown"
                    class="select-display"
                    :class="[{'is-danger': hasError}, gClass, size]"
                >
                    <strong>{{ activeOption.optionTitle }}</strong>
                    <small>{{ activeOption.optionDescription }}</small>
                </div>

                <select :id="id"
                        v-model="data[name]"
                        v-bind="$attrs"
                        :disabled="disabled"
                        @change="onChange"
                        ref="selectElement"
                        :class="{'hidden-select': !dropdownOpen}"
                >
                    <option v-for="option in options"
                            :value="option.value"
                            :key="option.value"
                            v-bind="$attrs"
                            :disabled="typeof option.disabled !== 'undefined'">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>

        <div class="hover-area"
             v-if="hasError"
             @mouseover="showErrorMessage"
             @mouseleave="hideErrorMessage">
            <div class="message-box">
                <div class="error-message" v-show="errorMessageVisible">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <label :class="['label active']"
               :for="id"
               v-if="labelAnimation">
            {{ labelText }}
        </label>
    </div>
</template>

<script>
import InputHelper from "../../utils/InputHelper";

export default InputHelper.extend({
    name: 'AdvancedSelect',
    data() {
        return {
            dropdownOpen: false,
            activeOptionValue: null,
        };
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
            if (this.dropdownOpen) {
                this.$refs.selectElement.focus();
            }
        },
        onChange(event) {
            this.activeOptionValue = event.target.value;
            this.dropdownOpen = false;
        },
    },
    computed: {
        activeOption() {
            for (const option of this.options) {
                if (option.value === this.activeOptionValue) {
                    return option;
                }
            }
        },
    },
    beforeMount() {
        let value = this.data[this.name];

        if (value === null) {
            value = this.options[0].value;
        }

        this.activeOptionValue = value;
    },
});
</script>

<style scoped lang="scss">
.select-display {
    cursor: pointer;
    /* Add your custom styles here */
}

.hidden-select {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
</style>
