<template>
    <div class="accordeon-item" :class="{ 'is-active': active}">
        <div class="accordeon-button" @click="$emit('select')">
            <div>
                <small v-if="topTitle">{{ topTitle }}</small>
                <strong>{{ title }}</strong>
            </div>
            <i class="icon">{{ icon }}</i>
        </div>
        <transition name="slide-up">
            <div class="accordeon-content" v-show="active">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "AccordeonItem",
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        topTitle: {
            type: String,
            default: null,
        },
        active: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        icon() {
            return this.active ? 'expand_less' : 'expand_less';
        }
    }
}
</script>


<style scoped lang="scss">
$horizontalPadding: 2.1875rem;
$horizontalPaddingMobile: 1.2rem;

.accordeon-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    background: #FCFCFC;

    .slide-down-enter-active,
    .slide-down-leave-active,
    .slide-up-enter-active,
    .slide-up-leave-active {
        transition-duration: 350ms;
    }

    &:last-child:not(.is-active) {

        .accordeon-button {
            border-bottom: 0;
        }
    }

    &.is-active {
        flex: 1;

        .accordeon-button {
            cursor: default;
            border-color: rgba(239, 239, 239, 0.94);

            strong {
                color: #1f1f1f;
            }

            i.icon {
                color: #1f1f1f;
                transform: rotate(180deg);
            }
        }

        .accordeon-content {
            height: 100%;
        }
    }

    .accordeon-button {
        cursor: pointer;
        flex-shrink: 0;
        height: 2.8125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $horizontalPaddingMobile;
        border-top: 1px solid rgba(239, 239, 239, 0.94);
        border-bottom: 1px solid transparent;
        background: #FFFFFF;
        z-index: 2;
        position: relative;

        @media screen and (min-width: 769px) {
            padding: 0 $horizontalPadding;
        }

        &:hover {
            i.icon {
                color: #1f1f1f;
            }

            strong {
                color: #1F1F1F;
            }
        }

        i.icon {
            color: #6A7272;
            transition: color .2s, transform .2s ease-in-out;
        }

        small {
            font-size: 0.625rem;
            line-height: 1;
            text-transform: uppercase;
            display: block;
        }

        strong {
            color: #6A7272;
            line-height: 1;
            font-size: 0.9375rem;
            display: block;
            transition: color .3s ease;
        }
    }

    .accordeon-content {
        padding: 1rem $horizontalPaddingMobile;
        overflow: scroll;
        height: 0;
        box-shadow: inset 0 55px 60px -55px rgba(0, 0, 0, .015);

        @media screen and (min-width: 769px) {
            padding: 1rem $horizontalPadding;
        }
    }
}
</style>
