<template>
    <div class="line-table">
        <div class="table-title" v-if="title" @click="active = !active">
            {{ title }}
        </div>
        <transition name="slide">
            <div class="table-container" v-show="active">
                <table>
                    <thead v-if="this.$slots.header">
                    <slot name="header"></slot>
                    </thead>
                    <tbody v-if="this.$slots.body">
                    <slot name="body"></slot>
                    </tbody>
                    <tfoot v-if="this.$slots.footer">
                    <slot name="footer"></slot>
                    </tfoot>
                </table>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "LineTable",
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
            active: true,
        }
    }
}
</script>

<style scoped lang="scss">
$horizontalPadding: 1rem;

.line-table {
    background: #F6F9FB;

    .table-title {
        cursor: pointer;
        color: #1F1F1F;
        font-size: 0.9375rem;
        font-weight: bold;
        line-height: 1;
        display: flex;
        align-items: center;
        height: 2.8125rem;
        padding: 0 $horizontalPadding;
        background: #FFFFFF;
        border-top: 1px solid #EFEFEF;
    }

    ::v-deep {
        .table-container {
            padding: 0 $horizontalPadding;
            border-top: 1px solid #EFEFEF;
        }

        table {
            font-size: 0.875rem;

            thead, tbody, tfoot {
                border: 0;

                &:first-child {
                    tr:first-child {
                        th, td {
                            border-top: 0;
                        }
                    }
                }
            }

            th, td {
                padding: .63rem calc($horizontalPadding / 2);
                border: 0;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &.minimum-width {
                    width: 1%;
                    white-space: nowrap;
                }
            }

            thead, tbody, tfoot {
                td {
                    border-top: 1px solid rgba(226, 234, 240, 0.94);
                }
            }

            tfoot {
                color: #1F1F1F;
                font-size: 0.875rem;
                font-weight: bold;
            }
        }
    }
}
</style>
