<template>
    <transition name="fade">
        <div class="modal model-large is-active" v-show="visible">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.name }}</h1>
                        <p>{{ this.description }}</p>
                    </div>

                    <g-form :action="`/api/admin/tool/${this.keyId}`" :form="form" method="PATCH" :before="formatData"
                            @success="formSuccess" @fail="processing=false">
                        <div v-show="!processing" class="popup-body popup-rows">
                            <div class="columns" v-for="(options, field) in this.input_schema">
                                <div class="column is-two-fifths"><label :for="`field-${keyId}`">{{ field }}</label>
                                </div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-switch v-if="options.type === 'boolean'" :name="field"
                                                  :v-model="`form.${field}`" :id="`field-${keyId}`"></g-switch>
                                        <g-select v-else-if="options.options" :name="field" :v-model="`form.${field}`"
                                                  :options="fieldOptions(options.options)" :id="`field-${keyId}`"></g-select>
                                        <g-input v-else-if="options.type === 'integer'" :name="field" type="number"
                                                 :v-model="`form.${field}`"> :id="`field-${keyId}`"</g-input>
                                        <g-file v-else-if="options.type === 'file'" :name="field"
                                                :v-model="`form.${field}`" :id="`field-${keyId}`"></g-file>
                                        <g-input v-else :name="field" :type="options.type"
                                                 :v-model="`form.${field}`" :id="`field-${keyId}`"></g-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="processing" class="popup-body action-status">
                            <div class="status-group">
                                <progress v-if="progress_status" class="progress" :value="progress_status"
                                          max="100"></progress>
                                <progress v-else class="progress is-primary" max="100"></progress>
                                <div class="progress-message">
                                    <template v-for="(message, key) in progress_messages">
                                        {{ message }}<br
                                        v-if="key !== progress_messages.length - 1"
                                    /><template
                                            v-if="key === progress_messages.length - 1">{{
                                                progress_dots
                                            }}
                                        </template>
                                    </template>
                                </div>

                                <button :disabled="progress_status != 100" @click="closeTools"
                                        class="button is-success is-rounded">
                                    {{ $t('admin.tools.close') }}
                                </button>
                            </div>
                        </div>

                        <div class="popup-footer" v-if="!processing">
                            <g-button>{{ this.$t('admin.tools.execute') }}</g-button>
                            <a @click.prevent="destroy" href="#" role="button">{{ this.$t('submit_dialog.cancel') }}</a>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        keyId: String,
        name: String,
        description: String,
        input_schema: Object
    },
    data() {
        return {
            processing: false,
            progress_status: 0,
            progress_messages: [],
            progress_dots: '',
            form: null,
        }
    },
    beforeMount() {
        // Set up the form
        console.log(this.input_schema)

        const fields = [];

        for (const [key, value] of Object.entries(this.input_schema)) {
            fields[key] = value.default;
        }

        // Add a unique key to fetch status updates during runtime
        fields['update_key'] = self.crypto.randomUUID();

        this.form = new Form(fields)
    },
    methods: {
        fieldOptions(options) {
            const formattedOptions = [];

            for (const [key, value] of Object.entries(options)) {
                formattedOptions.push({
                    value: key,
                    name: value,
                });
            }

            return formattedOptions;
        },
        formSuccess(response) {
            if (response.success === false) {
                this.progress_status = 100;

                // Show error message
                if (response.errors.length > 0) {
                    // Show toast
                    this.$buefy.toast.open({
                        message: response.message,
                        type: 'is-danger',
                        duration: 5000,
                    });

                    // Add all messages to the progress messages
                    response.errors.forEach((error) => {
                        this.addProgressMessage(error);
                        this.$buefy.toast.open({
                            message: error,
                            type: 'is-danger',
                            duration: 10000,
                        });
                    });
                }

                // Add message to clarify the form will be visible again after 5 seconds
                this.addProgressMessage(this.$t('admin.tools.form_redirect'));

                // Wait for 5 seconds, then stop the processing
                setTimeout(() => {
                    this.processing = false;
                }, 5000);
            }

            if (response.success === true) {
                this.progress_status = 100;

                // Show success message
                if (response.message) {
                    // Show toast
                    this.$buefy.toast.open({
                        message: response.message,
                        type: 'is-success',
                        duration: 5000,
                    });

                    // Add message to the progress messages
                    this.addProgressMessage(response.message);
                }
            }
        },
        formatData(form) {
            this.progress_status = 0;
            this.progress_messages = [];

            this.startUpdater();

            return form
        },
        startUpdater() {
            this.processing = true;

            // Run fetchUpdate every 500 ms until progress is 100 or processing is false
            const interval = setInterval(() => {
                if (this.processing && this.progress_status < 100) {
                    this.fetchUpdate();
                } else {
                    clearInterval(interval);
                }
            }, 1000);

            // Add a dot every 500 ms, reset after 3 dots
            const dotInterval = setInterval(() => {
                if (this.processing && this.progress_status < 100) {
                    if (this.progress_dots.length === 3) {
                        this.progress_dots = '';
                        return;
                    }

                    this.progress_dots += '.';
                } else {
                    this.progress_dots = '';
                    clearInterval(dotInterval);
                }
            }, 500);
        },
        fetchUpdate() {
            // Fetch the update key
            axios.get(`/api/admin/tool/${this.form.update_key}`)
                .then(response => {
                    this.progress_status = response.data.progress;

                    if (response.data.message) {
                        this.addProgressMessage(response.data.message);
                    }
                })
                .catch(error => {
                    console.error(error)
                });
        },
        closeTools() {
            console.log('destroyy');
            this.destroy();
        },
        addProgressMessage(messages) {
            // Make an array if is a string
            if (typeof messages === 'string') {
                messages = [messages];
            }

            // Convert object to basic array
            if (typeof messages === 'object') {
                messages = Object.values(messages);
            }

            // Add all messages to the progress messages
            messages.forEach((message) => {
                const timePrefix = new Date().toLocaleTimeString();
                this.progress_messages.push(`[${timePrefix}] ${message}`);
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.popup-dialog {
    position: relative;
}

.action-status {

    .status-group {
        margin: auto;
        width: 100%;

        .progress {
            width: 100%;
        }

        .progress-message {
            color: #9CD9F0;
            font-size: .9rem;
            font-family: monospace;
            font-weight: bold;
            text-align: left;
            margin: 2rem -2.5rem;
            background: #151515;
            height: 15rem;
            overflow-y: scroll;
            padding: .5rem 1rem;
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
</style>
