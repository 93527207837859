import Vue from 'vue'
import TreeSelect from '@riophae/vue-treeselect'

import Form from '../components/g-form/Form.vue'
import File from '../components/g-form/File.vue'
import Input from '../components/g-form/Input.vue'
import Switch from '../components/g-form/Switch.vue'
import Radio from '../components/g-form/Radio.vue'
import Select from '../components/g-form/Select.vue'
import Button from '../components/g-form/Button.vue'
import Format from '../components/g-form/Format.vue'
import Checkbox from '../components/g-form/Checkbox.vue'
import DatePicker from '../components/g-form/DatePicker.vue'
import ColorPicker from '../components/g-form/ColorPicker.vue'
import SearchBox from '../components/g-form/SearchBox.vue'
import PostLink from '../components/PostLink.vue'
import Table from '../components/Table.vue'
import AdvancedSelect from "../components/g-form/AdvancedSelect.vue";


// register globally
Vue.component('tree-select', TreeSelect)

Vue.component('g-form', Form)
Vue.component('g-file', File)
Vue.component('g-input', Input)
Vue.component('g-switch', Switch)
Vue.component('g-radio', Radio)
Vue.component('g-select', Select)
Vue.component('g-advanced-select', AdvancedSelect)
Vue.component('g-button', Button)
Vue.component('g-format', Format)
Vue.component('g-checkbox', Checkbox)
Vue.component('g-datepicker', DatePicker)
Vue.component('g-colorpicker', ColorPicker)
Vue.component('g-searchbox', SearchBox)

Vue.component('post-link', PostLink)
Vue.component('g-table', Table)
