<script>
export default {
    name: "Base",
    props: {
        data: {
            type: Object,
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    mounted() {
        this.show();
    },
    methods: {
        show() {
            this.visible = true

            this.$emit('show');
        },
        destroy(data) {
            this.visible = false
            this.$emit('destroy', data)

            this.$destroy()
        }
    }
}
</script>
