<template>
    <div :class="['field', { 'error': hasError, 'animation': labelAnimation }]">
        <label class="label"
               :for="id"
               v-if="!labelAnimation && label">
            {{ labelText }}
            <span v-if="isFieldTranslatable" class="language-indicator">
                {{ currentLocaleName }}
            </span>
        </label>
        <div :class="['control is-expanded', gClass, { 'has-icons-right': hasError || !!icon }]">
            <input v-model="inputValue"
                   v-bind="$attrs"
                   :type="type"
                   :id="id"
                   :disabled="disabled"
                   :placeholder="placeholder"
                   @input="emit"
                   @focus="onInputFocus"
                   @blur="onInputBlur"
                   :class="['input', size, { 'is-danger': hasError }]"
                   v-if="type !== 'textarea'">

            <textarea v-else
                      v-model="inputValue"
                      v-bind="$attrs"
                      :id="id"
                      :disabled="disabled"
                      @input="emit"
                      @focus="onInputFocus"
                      @blur="onInputBlur"
                      :class="['textarea', size, { 'is-danger': hasError }]"></textarea>

            <!-- Character count display for textarea -->
            <div v-if="type === 'textarea' && $attrs.maxlength" class="textarea-counter">
                {{ remainingCharacters }}/{{ $attrs.maxlength }}
            </div>

            <i class="icon" :class="['is-right', size]" v-if="!hasError && !!icon">{{ icon }}</i>
            <i class="icon" :class="['is-right is-danger is-normal']" v-if="hasError">error</i>
        </div>
        <div class="hover-area" v-if="hasError" @mouseover="showErrorMessage" @mouseleave="hideErrorMessage">
            <div class="message-box">
                <div class="error-message" v-show="errorMessageVisible">{{ errorMessage }}</div>
            </div>
        </div>
        <label :class="['label', { 'active': isInputActive }]"
               :for="id"
               v-if="labelAnimation">
            {{ labelText }}
            <span v-if="isFieldTranslatable" class="language-indicator">
                {{ currentLocaleName }}
            </span>
        </label>
    </div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'
import autosize from 'autosize'

export default InputHelper.extend({
    props: {
        autosize: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        if (this.type === 'textarea' && this.autosize) {
            let textarea = this.$el.querySelector('textarea')
            autosize(textarea)

            // Autosize doesn't update automatic when v-model is updated directly
            this.$watch(() => this.data[this.name], () => {
                autosize.update(textarea)
            })
        }
    },
    computed: {
        remainingCharacters() {
            if (this.type === 'textarea' && this.$attrs.maxlength) {
                const maxLength = parseInt(this.$attrs.maxlength, 10);
                const currentLength = this.data[this.name] ? this.data[this.name].length : 0;
                return maxLength - currentLength;
            }
            return null;
        }
    }
})
</script>

<style scoped lang="scss">
.textarea-counter {
    color: #9EA5A5;
    text-align: right;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
}

.language-indicator {
    font-size: .9em;
    color: #666;
    font-weight: normal;
    margin-left: 0.5em;

    &::before {
        content: '·';
        margin-right: 0.3em;
    }
}
</style>
