import forEach from "lodash/forEach";

export const adminGiftcardsApi = {

    getAll (filterData) {
        const query = []
        for (let property in filterData) {
            if (filterData.hasOwnProperty(property) && !!filterData[property]) {

                if (filterData[property] instanceof Array) {
                    forEach(filterData[property], value => {
                        query.push(`${encodeURIComponent(property)}[]=${encodeURIComponent(value)}`)
                    })
                } else {
                    query.push(`${encodeURIComponent(property)}=${encodeURIComponent(filterData[property])}`)
                }
            }
        }
        
        return axios.get(`/api/admin/giftcard-management?${query.join('&')}`)
    },

}
