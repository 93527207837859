<template>
	<a @click.prevent="click">
		<slot></slot>
	</a>
</template>

<script>
    export default {
        data () {
            return {
                form:      null,
                className: ''
            }
        },
        props: {
            url:    {
                type:     String,
                required: true
            },
            data:   {
                type: Object,
            },
            method: {
                type:    String,
                default: 'post'
            }
        },

        created () {
            this.form = new Form(this.data || {})
        },

        methods: {
            click () {
                this.form.submit(this.method, this.url)
                this.$emit('click')
            }
        }
    }
</script>
