<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">
                            {{ $t('digital.delivery_selection_form.title') }}
                        </h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body">
                        <p>
                            {{ $t('digital.delivery_selection_form.subtitle') }}
                        </p>

                        <div class="popup-buttons">
                            <button @click.prevent="selected = 'mail'" :class="{ 'active': selected === 'mail' }">
                                <img src="../../../../images/icons/plane.svg" alt="Send through e-mail" width="43" height="28">
                                <p>
                                    {{ $t('digital.delivery_selection_form.button_mail') }}
                                </p>
                            </button>

                            <!--<button @click.prevent="selected = 'shipment'" :class="{ 'active': selected === 'shipment' }">-->
                            <!--    <img src="../../../../images/icons/truck.svg" alt="Send through delivery" width="43" height="30">-->
                            <!--    <p>{{ $t('digital.delivery_selection_form.button_shipment') }}</p>-->
                            <!--</button>-->

                            <button @click.prevent="selected = 'download'" :class="{ 'active': selected === 'download' }">
                                <img src="../../../../images/icons/download.svg" alt="Download files" width="22" height="26">
                                <p>
                                    {{ $t(`digital.delivery_selection_form.${ downloaded ? 'button_download_active' : 'button_download' }`) }}
                                </p>
                            </button>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" class="mr-3" role="button">{{ this.$t('digital.delivery_selection_form.cancel') }}</a>
                        <g-button :disabled="selected === null" @click.prevent="action">{{ getButtonLabel }}</g-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'DigitalOrderDeliverySelection',

    props: {
        order: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            downloaded: false,
            selected: null
        }
    },

    computed: {
        getButtonLabel () {
            switch (this.selected) {
                case 'mail':
                    if (this.order.has_been_sent === true) {
                        return this.$t('digital.delivery_selection_form.submit_mail_history')
                    }

                    return this.$t('digital.delivery_selection_form.submit_mail')
                case 'download':
                    return this.$t('digital.delivery_selection_form.submit_download')
                case 'shipment':
                    return this.$t('digital.delivery_selection_form.submit_shipment')
                default:
                    return this.$t('digital.delivery_selection_form.submit')
            }
        }
    },

    methods: {
        action () {
            switch (this.selected) {
                case 'mail':
                    this.mail()
                    break
                case 'download':
                    this.download()
                    break
                case 'shipment':
                default:
                    this.destroy()
            }
        },

        mail () {
            this.destroy()

            setTimeout(() => {
                if (this.order.has_been_sent === true) {
                    this.$popup('PromotionalDeliveryLogForm', {
                        order_id: this.order.id,
                    })

                    return;
                }

                this.$popup('PromotionalDeliveryForm', {
                    id: this.order.id,
                })
            }, 150)
        },

        download () {
            const anchor = document.createElement('a')

            anchor.href = this.order.export_url
            anchor.target = '_blank'

            anchor.click()
            anchor.remove()

            this.downloaded = true

            setTimeout(() => {
                this.downloaded = false
            }, 30 * 1000)
        }
    }
}
</script>
