<template>
    <div>
        <h3 class="tab-header">Financieel</h3>

        <div class="columns">
            <div class="column is-6 is-4-widescreen">
                <div class="tab-subheader">Betalingsinstellingen</div>

                <g-form :action="`/api/admin/company/${this.$route.params.company_id}`"
                        :prepopulate="true"
                        method="PUT"
                        message="Betalingsinstellingen opgeslagen"
                        :form="paymentSettingsForm">

                    <small>E-mailadres facturatie</small>
                    <g-input name="invoice_mail" type="email" label="E-mailadres"/>

                    <small>Betaaltermijn</small>
                    <g-select
                        name="payment_terms_days"
                        :options="paymentTermsOptions"
                        valuekey="id"
                    />
                    <br/>

                    <g-button type="submit">Opslaan</g-button>
                </g-form>
            </div>
            <div class="column is-6 is-4-widescreen is-offset-1-widescreen">
                <div class="tab-subheader">Betaalaccount</div>

                <small>Balans</small>
                <p>{{ this.$options.filters.currency(this.balance.total / 100) ?? 'Onbekend' }}</p>

                <small>Eerstvolgende uitbetaling</small>
                <p>{{ this.$options.filters.currency(this.balance.available / 100) ?? 'Onbekend' }}</p>
            </div>
        </div>
        <br/><br/>

        <h3 class="tab-header">
            <div class="columns is-mobile">
                <div class="column is-narrow">
                    Factuurregels
                </div>
                <div class="column has-text-right">
                    <button @click="openRulePopup(null)" class="button is-small is-success">Toevoegen</button>
                </div>
            </div>

        </h3>

        <g-table class="mb-6" sort-column="id" ref="table"
                 :url="`/api/admin/company/${this.$route.params.company_id}/invoiceRule`">
            <b-table-column field="id" label="ID" v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="id" label="Regel" v-slot="props">
                <strong>{{ props.row.quantity }}x {{ props.row.name }}</strong>
                <span v-if="props.row.description"><br/>{{ props.row.description }}</span>
            </b-table-column>

            <b-table-column cell-class="text-darker" label="Status" v-slot="props">
                            <span class="icon-label has-text-grey-light" v-if="isCancelled(props.row)">
                                <i class="icon">clear</i>
                                {{ props.row.execution_times }} / {{ props.row.repeat || 'Oneindig' }}
                            </span>

                <span class="icon-label has-text-success" v-else-if="isCompleted(props.row)">
                                <i class="icon">check_circle_outline</i>
                                {{ props.row.execution_times }} / {{ props.row.repeat }}
                            </span>

                <span class="icon-label" v-else-if="isActive(props.row) && props.row.repeat === null">
                                <i class="icon">loop</i>
                                Oneindig
                            </span>

                <span class="icon-label" v-else>
                                <i class="icon">restart_alt</i>
                                {{ props.row.execution_times }} / {{ props.row.repeat }}
                            </span>
            </b-table-column>

            <b-table-column cell-class="text-darker" field="amount" label="Bedrag" v-slot="props" numeric>
                {{ props.row.amount | currency }}
            </b-table-column>

            <b-table-column v-slot="props" numeric>
                <div class="buttons">
                    <button v-if="isCompleted(props.row) === false" @click="openRulePopup(props.row)"
                            class="button is-white">Bewerken
                    </button>
                    <button @click="cancelRule(props.row)" v-if="isCompleted(props.row) === false"
                            class="button is-white">
                        <i class="icon is-danger">clear</i>
                    </button>
                </div>
            </b-table-column>
        </g-table>
        <br/><br/>


        <h3 class="tab-header">
            <div class="columns">
                <div class="column is-narrow">
                    Facturen
                </div>
                <div class="column has-text-right">
                    <div class="buttons">
                        <a :href="commissionInvoiceHistory" class="button is-small" target="_blank"
                           v-if="commissionInvoiceHistory">
                            <span>Specificatie {{ historyMonth }}</span>
                            <i class="icon">history</i>
                        </a>
                        <a :href="commissionInvoicePrediction" class="button is-small" target="_blank"
                           v-if="commissionInvoicePrediction">
                            <span>Specificatie {{ predictionMonth }}</span>
                            <i class="icon">update</i>
                        </a>
                    </div>

                    <a :href="membershipInvoicePrediction" class="button is-small is-right" target="_blank"
                       v-if="membershipInvoicePrediction">
                        <span>Lidmaatschapsfactuur</span>
                        <i class="icon">update</i>
                    </a>

                    <button @click="openInvoicePopup(null)" class="button is-small is-success">Toevoegen</button>
                </div>
            </div>
        </h3>

        <g-table sort-column="created_at"
                 :per-page="25"
                 @changed="setData" ref="invoiceTable"
                 :url="`/api/admin/company/${this.$route.params.company_id}/invoice`">
            <b-table-column field="created_at" label="Datum" sortable v-slot="props">
                <div class="text-nowrap">{{ parseDate(props.row.created_at) }}</div>
            </b-table-column>

            <b-table-column field="number" label="Factuurnummer" sortable v-slot="props">
                {{ props.row.number }}
            </b-table-column>

            <b-table-column field="description" label="Omschrijving" v-slot="props">
                <span v-if="props.row.reference">{{ props.row.reference }}</span>
                <span v-else>{{ props.row.description }}</span>
            </b-table-column>

            <b-table-column field="amount" label="Bedrag" v-slot="props" numeric>
                {{ props.row.amount }}
            </b-table-column>

            <b-table-column v-slot="props" numeric>
                <div class="buttons">
                    <button @click="openInvoicePopup(props.row.id)" class="button">
                        <i class="icon">file_copy</i>
                        <span>Kopie</span>
                    </button>
                    <a :href="props.row.download_url" class="button" target="_blank">
                        <i class="icon">file_download</i>
                        <span>Download</span>
                    </a>
                </div>
            </b-table-column>
        </g-table>
    </div>
</template>

<script>
import Layout from '../../../components/Layout/Layout'
import moment from 'moment'

export default {
    components: {
        Layout
    },
    data() {
        return {
            paymentSettingsForm: new Form({
                invoice_mail: null,
                payment_terms_days: null,
            }),
            paymentTermsOptions: [
                {
                    id: 14,
                    name: '14 dagen',
                },
                {
                    id: 30,
                    name: '30 dagen',
                },
                {
                    id: 60,
                    name: '60 dagen',
                },
                {
                    id: 90,
                    name: '90 dagen',
                },
            ],
            balance: {
                available: null,
                pending: null,
                total: null,
            },
            historyMonth: '',
            predictionMonth: '',
            commissionInvoiceHistory: false,
            commissionInvoicePrediction: false,
            membershipInvoicePrediction: false,
        }
    },
    created() {
        this.$store.dispatch('adminLedgerAccounts/loadAll')
        this.$store.dispatch('adminInvoicePresets/loadAll')
    },
    mounted() {
        axios.get(`/api/admin/company/${this.$route.params.company_id}/paymentAccount`)
            .then(({data}) => {
                this.balance.pending = (data.data.balance?.pending?.reduce((total, balanceObj) => total + balanceObj.amount ?? 0, this.balance.pending));
                this.balance.available = (data.data.balance?.available?.reduce((total, balanceObj) => total + balanceObj.amount ?? 0, this.balance.available));
                this.balance.total = this.balance.available + this.balance.pending;
            })
            .catch(() => {
                this.balance.pending = 0;
                this.balance.available = 0;
                this.balance.total = 0;
            });
    },
    methods: {
        parseDate(date) {
            return moment.utc(date).local().format('L LT')
        },
        setData(response) {
            this.historyMonth = response.history.month;
            this.predictionMonth = response.prediction.month;
            this.commissionInvoiceHistory = response.history.commission
            this.commissionInvoicePrediction = response.prediction.commission
            this.membershipInvoicePrediction = response.prediction.membership
        },
        isCompleted(rule) {
            return rule.completed_at !== null;
        },
        isCancelled(rule) {
            return rule.completed_at !== null && rule.repeat !== rule.execution_times;
        },
        isActive(rule) {
            return rule.completed_at === null;
        },
        openRulePopup(rule) {
            const popup = this.$popup('InvoiceRuleForm', {
                company_id: this.$route.params.company_id,
                id: rule ? parseInt(rule.id) : null,
            })

            popup.$on('destroy', (emit) => {
                this.$refs.table.loadAsyncData()
            });
        },
        openInvoicePopup(invoiceCopy) {
            const popup = this.$popup('InvoiceCreateForm', {
                company_id: this.$route.params.company_id,
                invoice_copy: invoiceCopy ? parseInt(invoiceCopy) : null,
            })

            popup.$on('destroy', (emit) => {
                this.$refs.invoiceTable.loadAsyncData()
            });
        },
        cancelRule(rule) {
            this.$popup('Confirmation', {
                title: 'Regel stopzetten',
                text: `Je staat op het punt om de factuurregel **${rule.name}** stop te zetten. Weet je dit zeker?`,
                onConfirmation: async () => {
                    axios.delete(`/api/admin/company/${this.$route.params.company_id}/invoiceRule/` + rule.id)
                        .then(() => {
                            this.$refs.table.loadAsyncData()
                            this.$buefy.toast.open('Factuurregel verwijderd')
                        })
                }
            })
        }
    },
}
</script>
