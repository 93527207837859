<template>
    <ModalLayout
        :visible="visible"
        @destroy="close"
        @success="submitForm"
        :success-loading="loading"
        :tabs="tabItems"
        :tabActive="activeTab"
        @tabSelected="(tab) => activeTab = tab"
    >
        <template v-slot:title>{{ $t('digital.create.issue_gift_cards') }}</template>

        <template v-slot:settingArea>
            <p>{{ $t('digital.create.setting_area_description') }}</p>

            <g-form :form="form" class="form-container" ref="formRef" action="/api/digital"
                    @loading-state="setLoadingState" @fail="setActiveStepForErrors" @success="formSuccess">
                <accordeon>
                    <accordeon-item
                        :title="$t('digital.create.gift_card')"
                        :active="activeStep === 1"
                        @select="selectedStep(1)"
                    >
                        <div class="is-flex is-flex-direction-column is-justify-content-space-between form-min-height">
                            <div class="">
                                <label class="group-label">{{ $t('digital.create.value') }}</label>
                                <div class="field-body">
                                    <g-select :options="valueTypeOptions" name="value_type"
                                              :label="$t('digital.create.type')"/>
                                    <g-format
                                        v-if="form.value_type === 'amount'"
                                        type="currency"
                                        name="value"
                                        :label="$t('digital.create.value')"/>
                                    <g-select :options="packageOptions" name="package_id"
                                              :label="$t('digital.create.package')" v-else/>
                                </div>

                                <div class="field-body">
                                    <div>
                                        <label class="group-label">{{
                                                $t('digital.create.number_of_gift_cards')
                                            }}</label>
                                        <g-input type="number" :label="$t('digital.create.quantity')" name="quantity"
                                                 min="1"
                                                 max="250"/>
                                    </div>

                                    <div>
                                        <label class="group-label">{{ $t('digital.create.expiration') }}</label>
                                        <g-select :options="expirationOptions" name="expiration"
                                                  :label="$t('digital.create.expiration')"/>
                                    </div>
                                </div>

                                <label class="group-label">{{ $t('digital.create.message_on_gift_card') }}</label>
                                <g-input type="textarea" :label="$t('digital.create.personal_message_on_gift_card')"
                                         name="message" maxlength="300" rows="3" autosize/>
                            </div>
                            <div>
                                <g-button @click.prevent="activeStep++" :is-rounded="false" class="is-pulled-right">
                                    {{ $t('accordeon.next') }}
                                </g-button>
                            </div>
                        </div>
                    </accordeon-item>
                    <accordeon-item
                        :title="$t('digital.create.administration')"
                        :active="activeStep === 2"
                        @select="selectedStep(2)"
                    >
                        <div v-if="locations.length > 0">
                            <label class="group-label">{{ $t('digital.create.issuing_location') }}</label>
                            <g-select :options="locationsWithNoLocationOption" name="location_id" :label="$t('digital.create.location')"/>
                        </div>

                        <label class="group-label">
                            {{ $t('digital.create.internal_reference') }}
                            <small>{{ $t('digital.create.internal_reference_subtitle') }}</small>
                        </label>
                        <g-input name="reference" :label="$t('digital.create.reference')"/>

                        <label class="group-label">
                            {{ $t('digital.create.reporting_labels') }}
                            <small>{{ $t('digital.create.reporting_labels_subtitle') }}</small>
                        </label>

                        <tags-input v-model="editableTag"
                                    :tags="form.tags"
                                    :add-on-key="[';', ',', 13]"
                                    :max-tags="10"
                                    :placeholder="$t('digital.create.reporting_tags')"
                                    :autocomplete-items="tagsAutocomplete"
                                    :autocomplete-min-length="0"
                                    autocomplete="off"
                                    class="mb-3"
                                    @tags-changed="newTags => form.tags = newTags"/>

                        <label class="group-label">
                            {{ $t('digital.create.mark_promotional') }}
                            <small>{{ $t('digital.create.mark_promotional_subtitle') }}</small>
                        </label>
                        <g-switch name="promotional" :label="$t('digital.create.mark_promotional')"/>
                    </accordeon-item>
                </accordeon>
            </g-form>
        </template>

        <template v-slot:previewArea>
            <tabbed-view @tabsInitialized="(tabs) => tabItems = tabs" ref="tabbedView">
                <tabbed-view-item
                    :title="$t('digital.create.overview')"
                    :active="activeTab === 0"
                    blueprint
                >
                    <div class="preview-container">
                        <giftcard-preview
                            :title="previewData.title"
                            :value="previewData.value"
                            :card-side="previewCardSide"
                            :message="previewData.message"
                            hide-title
                            hide-switch-buttons/>
                        <div class="controls">
                            <a href="#" @click.prevent="previewCardSide = 'front'"
                               :class="{'is-active': previewCardSide === 'front'}">
                                {{ $t('digital.create.front') }}
                            </a>
                            <a href="#" @click.prevent="previewCardSide = 'back'"
                               :class="{'is-active': previewCardSide === 'back'}">
                                {{ $t('digital.create.back') }}
                            </a>
                        </div>
                    </div>

                    <line-table :title="$t('digital.create.order_total')">
                        <template v-slot:body>
                            <tr>
                                <td class="minimum-width">{{ form.quantity }}x</td>
                                <td>{{ $t('digital.create.digital_gift_card') }}</td>
                                <td class="minimum-width">{{ (objectPrice * form.quantity) / 100 | currency }}</td>
                            </tr>
                        </template>
                        <template v-slot:footer>
                            <tr>
                                <td colspan="2" class="has-text-right">{{ $t('digital.create.total_excl_vat') }}</td>
                                <td class="minimum-width">{{ (objectPrice * form.quantity) / 100 | currency }}</td>
                            </tr>
                        </template>
                    </line-table>
                </tabbed-view-item>
            </tabbed-view>
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from "../ModalLayout.vue";
import Accordeon from "../../Popup/Accordeon.vue";
import AccordeonItem from "../../Popup/AccordeonItem.vue";
import TabbedView from "../TabbedView.vue";
import TabbedViewItem from "../TabbedViewItem.vue";
import {mapGetters} from "vuex";
import TagsInput from "@johmun/vue-tags-input";
import LineTable from "../LineTable.vue";
import GiftcardPreview from "../../GiftcardPreview.vue";
import debounce from "lodash/debounce";
import posthog from "posthog-js";

export default {
    name: "DigitalOrderCreate",
    components: {
        GiftcardPreview, LineTable, TagsInput, TabbedViewItem, TabbedView, AccordeonItem, Accordeon, ModalLayout
    },
    computed: {
        ...mapGetters({
            packages: 'packages/all',
            locations: 'locations/allIssuePointSelect',
            reportingTags: 'promotionalOrders/getReportingTags',
            objectPrice: 'promotionalOrders/getPrice',
        }),
        locationsWithNoLocationOption() {
            return [this.no_location_option, ...this.locations];
        },
        valueTypeOptions() {
            let options = [
                {
                    name: this.$t('digital.create.amount'),
                    value: 'amount'
                },
            ];

            if (this.packages.length > 0) {
                options.push({
                    name: this.$t('digital.create.package'),
                    value: 'package'
                });
            }

            return options;
        },
        packageOptions() {
            return this.packages.map((option) => {
                return {
                    name: option.title,
                    value: option.id
                }
            });
        },
        expirationOptions() {
            const monthsOptions = Array.from({length: 11}, (v, k) => ({
                name: this.$tc('digital.create.expiration_months', k + 1),
                value: 30 * (k + 1),
            }));

            const yearsOptions = Array.from({length: 10}, (v, k) => ({
                name: this.$tc('digital.create.expiration_years', k + 1),
                value: 365 * (k + 1),
            }));

            return [...monthsOptions, ...yearsOptions];
        },
        tagsAutocomplete() {
            return this.reportingTags.map(tag => {
                return {
                    text: tag,
                }
            })
        },
    },
    data() {
        return {
            loading: false,
            activeStep: 1,
            activeTab: 0,
            tabItems: [],
            editableTag: '',
            previewCardSide: 'front',
            previewData: {
                title: null,
                value: 4500,
                message: '',
            },
            no_location_option: {
                name: this.$t('digital.create.no_location'),
                value: null,
            },
            fieldStepMap: {
                1: ['value_type', 'value', 'package_id', 'quantity', 'message', 'expiration'],
                2: ['reference', 'location_id', 'tags', 'promotional'],
            },
            form: new Form({
                value_type: 'amount',
                value: '',
                package_id: null,
                quantity: 1,
                message: '',
                expiration: this.$store.getters['user/activeCompany'].gift_card_expiration,
                location_id: null,
                promotional: true,
                tags: [],
                reference: null,
            }),
        }
    },
    watch: {
        packageOptions() {
            if (
                this.form.package_id !== null ||
                this.packageOptions.length === 0
            ) {
                return;
            }

            this.form.package_id = this.packageOptions[0].value;
        },
        locations() {
            if (
                this.form.location_id !== null ||
                this.locations.length === 0
            ) {
                return;
            }

            this.form.location_id = this.locations[0].value;
        },
        'form.message': {
            handler: function () {
                this.previewCardSide = 'back';
            },
            deep: true,
        },
        form: {
            // Update preview data
            handler: debounce(function () {
                let value = this.form.value;
                value = value.replace(',', '.');

                if (value !== '') {
                    value = parseFloat(value) * 100;
                } else {
                    value = 0;
                }

                this.previewData.value = value;
                this.previewData.message = this.form.message;

                if (this.form.value_type === 'amount') {
                    this.previewData.title = undefined;
                } else {
                    const gcPackage = this.packageOptions.find(option => option.value === this.form.package_id);
                    this.previewData.title = this.form.package_id ? gcPackage.name : null;
                }
            }, 100),
            deep: true,
        },
    },
    created() {
        this.$store.dispatch('packages/loadAll')
        this.$store.dispatch('locations/loadAll')
    },
    methods: {
        close() {
            this.$emit('destroy');
        },
        selectedStep(step) {
            if(this.activeStep === step) {
                step = step + 1;
            }

            if(step >= 3) {
                step = 1;
            }

            this.activeStep = step;
        },
        setLoadingState(state) {
            this.loading = state;
        },
        setActiveStepForErrors(data) {
            // If data object does not contain an errors object, return
            if (!data.errors) {
                return;
            }

            let formErrorFields = [];

            // Loop through the errors object, it has the format of {field: [errors]}
            for (const field in data.errors) {
                // If the field is not in the form object, continue
                if (!this.form.hasOwnProperty(field)) {
                    continue;
                }

                // Add the field to the formErrorFields array
                formErrorFields.push(field);
            }

            let stepsWithErrors = [];

            // Loop through the fieldStepMap object
            for (const step in this.fieldStepMap) {
                // Loop through the fields in the fieldStepMap object
                for (const field of this.fieldStepMap[step]) {
                    // If the field is in the formErrorFields array, add the step to the stepsWithErrors array
                    if (formErrorFields.includes(field)) {
                        stepsWithErrors.push(parseInt(step));
                        break;
                    }
                }
            }

            // If the stepsWithErrors array is not empty, set the activeStep to the lowest step
            if (stepsWithErrors.length > 0) {
                this.activeStep = Math.min(...stepsWithErrors);
            }
        },
        submitForm() {
            this.$refs.formRef.submit()
        },
        formSuccess(response) {
            posthog.capture(
                'created_digital_gift_cards',
                {
                    $set_once: { has_created_digital_gift_cards: true },
                }
            )

            this.$store
                .dispatch('promotionalOrders/add', {
                    object: response.data
                })
                .then(this.destroy)
        },
    }
}
</script>

<style scoped lang="scss">
.form-min-height {
    min-height: 100%;
}

.form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

.preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;
    max-width: 65%;
    align-self: center;

    @media (min-height: 850px) {
        max-width: 75%;
        margin-bottom: 2rem;
    }

    .controls {
        display: flex;
        justify-content: space-between;

        a {
            color: #1F1F1F;
            text-decoration: underline;

            &.is-active {
                cursor: default;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    ::v-deep {

        canvas {
            max-width: 100% !important;
            height: auto !important;
            box-shadow: 0 11px 54px 0 rgba(0, 0, 0, 0.10);
            border-radius: 3px;
            background: #FFFFFF;
        }
    }
}
</style>
