/**
 * Here you can define and export constants that are used in multiple Components
 * This file is so all components can have easy access to the pieces of information without code duplication
 */

/**
 * Format every g-format[type=currency] before sending data to the server
 * @param amount
 * @returns {number}
 */
export const formatCurrencyInput = (amount) => {

    const stringFloat = String(amount).replace(',', '.')
    const float       = parseFloat(stringFloat)
    return Math.round(float * 100) // Math.round is needed because of float multiplication errors. See: https://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html
}

/**
 * Waits for an object or variable to not be a falsy value
 * Handy when needing to wait until a $ref is loaded
 *
 * @param object
 * @param {Number|boolean}timeout 10
 * @param {Number}speed 100
 * @returns {Promise<any>}
 */
export const waitFor = (object, timeout = 15, speed = 100) => {
    // console.log('waitFor', object)
    let count = 0;
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {

            // console.log('waitFor intervalling', object)
            count++;
            if (!!object) {
                clearInterval(interval)
                resolve(object)
                return true
            }
            if (timeout !== false && count >= timeout) {
                clearInterval(interval)
                reject(new Error('Waiting for object timed out'))
                return false
            }
        }, speed);
    })
}
