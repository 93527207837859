<template>
    <div class="navbar-item has-dropdown is-hoverable"
         :class="{ 'is-hidden-desktop' : mobile , 'is-hidden-touch' : !mobile }">
        <a class="navbar-link" @click="toggleDropdown()">
            {{ $t(title) }}
        </a>
        <div class="navbar-dropdown" v-if="dropdown">
            <router-link
                :key="submenu_item.title"
                :to="getRoute(submenu_item.route_name)"
                class="navbar-item"
                v-for="submenu_item in subMenuItems"
                v-if="allowedRoute(submenu_item.router_name, $store.getters['user/user'])">
                {{ $t(submenu_item.title) }}
            </router-link>
        </div>
    </div>
</template>

<script>
import { allowedRoute, getRoute } from '../menu'

export default {
    props: {
        subMenuItems: {
            type:     Array,
            required: true,
        },
        title:        {
            type:     String,
            required: true,
        },
        mobile:       {
            type:    Boolean,
            default: () => true,
        }
    },

    data () {
        return {
            dropdown: false,
            getRoute,
            allowedRoute
        }
    },

    methods: {
        toggleDropdown () {
            this.dropdown ? this.dropdown = false : this.dropdown = true
        }
    },
    watch:   {
        $route () {
            this.dropdown = false
        }
    }
}
</script>
