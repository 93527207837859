<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" :message="this.$t('notice.saved')" :prepopulate="!!this.id">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.type') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-select name="type"
                                                  :options="this.filterTypes"
                                                  valuekey="id"/>
                                    </div>
                                    <small>{{ $t(`admin.fraud.types_explanation.${this.form.type}`) }}</small>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.filters') }}
                                </label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="filters_edit"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-indented" v-if="showFilterFields">
                                <div class="column">
                                    <small>{{ this.$t('admin.fraud.filters_explanation') }}</small>
                                </div>
                            </div>
                            <div class="columns is-indented" v-if="showFilterFields">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.ip') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="ip" :label="this.$t('admin.fraud.ip')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-indented" v-if="showFilterFields">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.fingerprint') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="fingerprint" :label="this.$t('admin.fraud.fingerprint')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns is-indented" v-if="showFilterFields">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.email') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="email" :label="this.$t('admin.fraud.email')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.reason') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-select name="reason"
                                                  :options="this.filterReasons"
                                                  valuekey="id"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.fraud.comment') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input
                                            name="comment"
                                            type="textarea"
                                            autosize
                                            :label="this.$t('admin.fraud.comment')"/>
                                    </div>
                                    <small>{{ this.$t('admin.fraud.comment_explanation') }}</small>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ this.$t('submit_dialog.cancel') }}</a>
                            <g-button>{{ this.$t('submit_dialog.submit') }}</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    props: {
        id: String
    },
    data() {
        const formData = {
            'type': 'notify',
            'fingerprint': null,
            'ip': null,
            'email': null,
            'reason': 'reported_by_consumer',
            'comment': null,
        }

        return {
            form: new Form({
                filters_edit: 0,
                ...formData
            }),
        }
    },
    computed: {
        ...mapGetters({
            ledger_accounts: 'adminLedgerAccounts/all',
        }),
        showFilterFields() {
            return this.form.filters_edit === 1
        },
        filterTypes() {
            return [
                {
                    id: 'notify',
                    name: this.$t('admin.fraud.types.notify'),
                },
                {
                    id: 'block',
                    name: this.$t('admin.fraud.types.block'),
                },
                {
                    id: 'deny',
                    name: this.$t('admin.fraud.types.deny'),
                },
            ];
        },
        filterReasons() {
            return [
                {
                    id: 'reported_by_customer',
                    name: this.$t('admin.fraud.reasons.reported_by_customer'),
                },
                {
                    id: 'reported_by_company',
                    name: this.$t('admin.fraud.reasons.reported_by_company'),
                },
                {
                    id: 'reported_by_psp',
                    name: this.$t('admin.fraud.reasons.reported_by_psp'),
                },
                {
                    id: 'reported_by_bank',
                    name: this.$t('admin.fraud.reasons.reported_by_bank'),
                },
                {
                    id: 'suspicious_activities',
                    name: this.$t('admin.fraud.reasons.suspicious_activities'),
                },
            ];
        },
        isCreateNewForm() {
            return this.id === undefined
        },
        formTitle() {
            return this.isCreateNewForm ? this.$t('admin.fraud.create.title') : this.$t('admin.fraud.edit.title')
        },
        formEndpoint() {
            let endpoint = `/api/admin/fraud_filter`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    methods: {
        formSuccess() {
            this.destroy();
        },
        formatData(form) {
            return form
        },
    },
}
</script>
