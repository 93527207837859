<template>
    <div class="giftcard-preview">
        <label class="label" v-if="!frontOnly && !hideTitle">{{ $t('packages.preview.your_gift_card') }}</label>
        <div :class="['aspect-ratio-wrapper', { 'loading-canvas': loading }]">
            <div class="aspect-ratio-content">
                <canvas ref="canvas"></canvas>
            </div>
        </div>

        <div class="field is-grouped is-grouped-centered giftcard-side-controls"
             v-if="!frontOnly && !hideSwitchButtons">
            <p class="control">
                <button class="button editor-button with-shadow is-rounded"
                        :class="{ 'is-primary': front }"
                        @click="switchSides(true)">{{ $t('packages.preview.front') }}
                </button>
            </p>
            <div v-show="front" class="arrow-up"></div>
            <p class="control">
                <button class="button editor-button with-shadow is-rounded"
                        :class="{ 'is-primary': !front }"
                        @click="switchSides(false)">{{ $t('packages.preview.back') }}
                </button>
            </p>
            <div v-show="!front" class="arrow-up"></div>
        </div>
    </div>
</template>

<script>
import {waitFor} from '../../../utils/Consts'
import debounce from 'lodash/debounce'
import clone from 'lodash/clone'
import forEach from 'lodash/forEach'
import CanvasMixins from '../../../mixins/CanvasMixins'

export default {
    mixins: [CanvasMixins],
    props: {
        frontOnly: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideSwitchButtons: {
            type: Boolean,
            default: false,
        },
        package: {
            type: String,
        },
        cdn: {
            type: String,
            required: true,
        },
        form: {
            default() {
                return {}
            },
            type: Object,
        },
        fonts: {
            default: () => [],
            type: Array,
        },
        showPackageValue: {
            default: true,
            type: [Boolean, Number],
        },
        amount: {
            default: 4500,
            type: Number,
        },
        cardSide: {
            type: String,
            default: 'front',
        },
        message: {
            type: String,
        }
    },
    data() {
        return {
            front: true,
            width: 595,
            height: 419,
            currentData: {},
            fetched: {
                placeholder: null,
            },
            defaults: {
                QR_LOCATION: '/images/gift-card-template/qr.jpg',
                FONT_LOCATION: '/library/font/',
                BG_LOCATION: '/images/gift-card-template/bg_preview_{{BRANDING_SLUG}}.svg',
                LOGO_PLACEHOLDER: '/images/gift-card-template/logo_placeholder_preview.svg',
                CONTENT_LOCATION: '/images/gift-card-template/{{ID}}.svg',
                TRACK_CODE: 'JW96 - S75S - 9FV8 - L9S4',
                ISSUED_DATE: 'Uitgiftedatum: 1 januari ' + new Date().getFullYear(),
                VALUE_TEXT: 'Waarde € ',
                VALUTA_SIGN: '€',
                GIFTCARD_VALUE: 45,
                MESSAGE: `Hey {{NAME}},\n\nAls ik online deze cadeaubon bestel heb ik de mogelijkheid om hier een tekstje te schrijven wat dan automatisch op de bon geprint wordt. Hartstikke handig toch?\nFijne verjaardag.\n\nLiefs van Gifty!`,
                GIFTCARD_TITLE: 'Cadeaubon',
                GIFTCARD_SUBTITLE: 'Ter waarde van',
            },
            logoOptions: {
                width: 800,
                height: 400,
            },
            loading: false,
            allFontsLoaded: false,
        }
    },
    watch: {
        form: {
            handler(data, oldData) {
                this.runFormHandler(data, oldData)
            },
            deep: true,
        },
        allFontsLoaded() {
            this.redraw()
        },
        fonts(value) {
            this.loadAllFonts(value)
        },
        amount(value) {
            value = value / 100

            value = Number.isInteger(value) ? value : this.$options.filters.currency(value, '').replace(/\s/g, '')

            this.defaults.GIFTCARD_VALUE = value

            this.redraw()
        },
        message(value) {
            if (this.front) {
                return;
            }

            this.redraw()
        },
        package(value) {
            this.redraw()
        },
        showPackageValue(value) {
            if (this.front) {
                return;
            }

            this.redraw()
        },
        cardSide(value) {
            this.switchSides(value === 'front')
        }
    },

    mounted() {
        this.defaults.QR_LOCATION = this.cdn + this.defaults.QR_LOCATION
        this.defaults.FONT_LOCATION = this.cdn + this.defaults.FONT_LOCATION
        this.defaults.BG_LOCATION = this.cdn + this.defaults.BG_LOCATION
        this.defaults.LOGO_PLACEHOLDER = this.cdn + this.defaults.LOGO_PLACEHOLDER
        this.defaults.CONTENT_LOCATION = this.cdn + this.defaults.CONTENT_LOCATION

        this.defaults.MESSAGE = this.defaults.MESSAGE.replace(/{{NAME}}/g, 'Esther')

        const number = this.amount / 100
        this.defaults.GIFTCARD_VALUE = Number.isInteger(number) ? number : this.$options.filters.currency(number, '').replace(/\s/g, '')

        if (this.fonts.length) {
            this.loadAllFonts(this.fonts)
            this.runFormHandler(this.form, {})
        }
    },

    methods: {
        async loadFontIfNotLoaded(font) {
            return new Promise((resolve, reject) => {
                if (this.$store.getters['builder/loadedFonts'].includes(font)) {
                    console.log('Font already loaded', font)
                    return resolve()
                }

                this.loadFont(font)
                    .then(() => {
                        this.$store.commit('builder/addLoadedFont', font)
                        return resolve()
                    })
                    .catch(() => {
                        console.error('Failed to load font', font)
                        return reject()
                    })
            })
        },

        runFormHandler(data, oldData) {
            // Don't redraw if template has been switched
            if (data.ratio !== oldData.ratio && data.background_image instanceof HTMLCanvasElement) {
                if (this.front) {
                    this.loading = true
                }
                return false
            }

            this.dynamicSwitchingSides(data, oldData)

            this.currentData = clone(data)

            this.redraw(clone(data))
        },

        async loadAllFonts(value) {
            if (this.allFontsLoaded) {
                return;
            }

            // console.log('loadAllFonts init')
            await this.loadFontIfNotLoaded('BeautifulEveryTime.ttf')

            let fontsToLoad = [];

            for (const font of value) {
                fontsToLoad.push(font.main)
                fontsToLoad.push(font.subtitle)
                fontsToLoad.push(font.amount)
            }

            // Keep only unique values in the array
            fontsToLoad = [...new Set(fontsToLoad)]

            await Promise.all(fontsToLoad.map(font => this.loadFontIfNotLoaded(font)))

            this.allFontsLoaded = true
        },

        /**
         * Check if the preview needs to switch sides
         * @param data
         * @param oldData
         */
        dynamicSwitchingSides(data, oldData) {

            const forceFront = [
                'template_id',
                'fontgroup_id',
                'background_type',
                'background_image',
                'background_custom',
                'background_pattern_image',
                'background_pattern',
                'background_color',
            ]
            const forceBack = ['address']

            if (data.template.name === 'Custom') {
                forceBack.push('logo')
            }

            if (data.logo instanceof HTMLCanvasElement) {
                forceFront.push('color')
            }

            // Check if it needs to switch to the back
            forEach(forceBack, value => {
                if (data[value] !== oldData[value]) {
                    this.front = false
                    return false
                }
            })

            // Check if it needs to switch to the front
            forEach(forceFront, value => {
                if (data[value] !== oldData[value]) { // If the new data is not the same as the oldData it means that it is changed
                    this.front = true
                    return false
                }
            })
        },
        switchSides(front) {
            console.log('Switch orientation to ', front ? 'front' : 'back')

            this.front = front

            this.reset()
            this.redraw()
        },

        /**
         * Redraw preview
         */
        redraw: debounce(async function (data = this.currentData) {
            if (false === this.allFontsLoaded) {
                console.log('Skipping redraw, fonts not loaded yet')
                return
            }

            console.log('Preview redraw')

            await waitFor(this.canvasReady)

            this.reset()

            if (this.front || this.frontOnly) {
                this.drawModeCentered = true
                await this.drawFront(data)

            } else {
                this.drawModeCentered = false
                await this.drawBack(data)
            }

            this.loading = false
        }, 100),

        /**
         * Draw backside
         * @param data
         * @returns {Promise.<Promise|Bluebird|Promise<T>>}
         */
        async drawBack(data) {

            const startAddressArea = 367 // The border at the address area
            const qrWidthHeight = 70
            const addressAreaWidth = this.width - startAddressArea
            const addressAreaCenter = startAddressArea + (addressAreaWidth / 2)
            const contentAddressAreaWidth = addressAreaWidth - 66 // Add some spacing between text and border
            const qrStartPos = addressAreaCenter - (qrWidthHeight / 2)
            const pricingText = this.defaults.VALUE_TEXT + this.defaults.GIFTCARD_VALUE

            const brandingSlug = this.defaults.BG_LOCATION.replace('{{BRANDING_SLUG}}', 'gifty')
            await this.image(brandingSlug, 0, 0, this.width, this.height)
            await this.image(this.defaults.QR_LOCATION, qrStartPos, 290, qrWidthHeight, qrWidthHeight)

            this.textBlock(this.message ?? this.defaults.MESSAGE, 40, 100, 300, 15, 1.2, 'start', 'BeautifulEveryTime.ttf')
            this.textBlock(this.defaults.TRACK_CODE, addressAreaCenter, 370, contentAddressAreaWidth, 10, 1.0, 'center', 'Open sans', 'bold')
            this.textBlock(this.defaults.ISSUED_DATE, addressAreaCenter, 381, contentAddressAreaWidth, 8, 1.0, 'center')

            if (this.package === undefined || this.showPackageValue) {
                this.textBlock(pricingText, addressAreaCenter, 390, contentAddressAreaWidth, 8, 1.0, 'center')
            }

            this.textBlock(data.address, addressAreaCenter, 170, contentAddressAreaWidth, 10, 1.3, 'center')

            // Draw a line to the center of the address area for debugging
            // this.ctx.beginPath()
            // this.ctx.moveTo(addressAreaCenter, 0)
            // this.ctx.lineTo(addressAreaCenter, this.height)
            // this.ctx.stroke()

            console.log(data.logo)
            if (data.logo === null || data.logo === 'placeholder') {
                const image = await this.parsePlaceholderLogo()
                const placeholderWidth = 45
                const placeholderStartPos = addressAreaCenter - (placeholderWidth / 2)

                await this.image(image, placeholderStartPos, 50, placeholderWidth, 30)
            } else {
                const sourceWidth = this.logoOptions.width
                const sourceHeight = this.logoOptions.height
                const logoSizes = this.calculateAspectRatioFit(sourceWidth, sourceHeight, contentAddressAreaWidth, 64)
                const logoStartPosX = addressAreaCenter - (logoSizes.width / 2)
                const logoStartPosY = 40

                await this.image(data.logo, logoStartPosX, logoStartPosY, logoSizes.width, logoSizes.height)
            }

            return new Promise(resolve => resolve())
        },

        /**
         * Draw front
         * @param data
         * @returns {Promise.<Promise|Bluebird|Promise<T>>}
         */
        async drawFront(data) {
            await this.drawBackground(data)

            if (data.template.name !== 'Custom') {
                await this.drawContent(data)

                await this.drawLogo(data)

                this.drawAmountContainer(data)
            }

            this.drawTexts(data)

            return new Promise(resolve => resolve())
        },

        async drawBackground(data) {
            const backgroundX = this.element('background', 'left')
            const backgroundY = this.element('background', 'top')
            const backgroundWidth = this.element('background', 'width')
            const backgroundHeight = this.element('background', 'height')

            if (data.template.name === 'Custom') {

                if (data.background_custom !== null) {
                    await this.image(data.background_custom, backgroundX, backgroundY, backgroundWidth, backgroundHeight)
                }

                return Promise.resolve()
            }

            switch (data.background_type) {

                case 'image':
                    if (typeof data.background_image === 'string') {
                        await this.image(data.background_image.replace(':template_id', data.template_id), backgroundX, backgroundY, backgroundWidth, backgroundHeight)

                        break;
                    } else if(data.background_image instanceof HTMLCanvasElement) {
                        await this.image(data.background_image, backgroundX, backgroundY, backgroundWidth, backgroundHeight)

                        break;
                    } else if (data.background_image instanceof Object && data.background_image.type === 'image') {
                        if (typeof data.background_image.url === 'undefined') {
                            await this.image(data.background_url.replace(':template_id', data.template_id), backgroundX, backgroundY, backgroundWidth, backgroundHeight)

                            break;
                        }

                        await this.image(data.background_image.url.replace(':template_id', data.template_id), backgroundX, backgroundY, backgroundWidth, backgroundHeight)
                    }
                    break
                case 'pattern':
                    await this.image(data.background_pattern_image, this.width / 2, this.height / 2, this.width, this.height)

                    break
                case 'color':
                    this.rectangle(backgroundX, backgroundY, backgroundWidth, backgroundHeight, data.background_color)
                    break
            }

            return new Promise(resolve => resolve())
        },

        async drawContent(data) {
            const x = this.element('content', 'left'),
                y = this.element('content', 'top'),
                w = this.element('content', 'width'),
                h = this.element('content', 'height')

            if (data.template.name === '50/50') {
                this.rectangle(x - 10, y - 10, w + 10, h + 20, this.element('content', 'background', 'string'))
            } else {
                await this.image(this.defaults.CONTENT_LOCATION.replace('{{ID}}', data.template.id), x, y, w, h)
            }

            return new Promise(resolve => resolve())
        },

        /**
         * Sets the correct color in the placeholder svg
         * @return {Promise<T>|Promise}
         */
        parsePlaceholderLogo() {
            return new Promise(async (resolve) => {

                if (!this.fetched.placeholder) {
                    const placeholderLogo = await axios.get(this.defaults.LOGO_PLACEHOLDER)
                    this.fetched.placeholder = placeholderLogo.data
                }

                const base64Data = this.fetched.placeholder.replace(/.st0{fill:#111110;}/, `.st0{fill:${this.currentData.color};}`)
                resolve(`data:image/svg+xml;base64,${btoa(base64Data)}`)
            })
        },

        async drawLogo(data) {
            const x = this.element('logo', 'left'),
                y = this.element('logo', 'top'),
                width = this.coords(550),
                height = this.coords(275)

            if (data.logo === null || data.logo === 'placeholder') {
                let image = await this.parsePlaceholderLogo()

                await this.image(image, x, y, 45, 30)
            } else {
                await this.image(data.logo, x, y, width, height)
            }

            return new Promise(resolve => resolve())
        },

        drawAmountContainer(data) {

            const x = this.element('amount', 'left')
            const y = this.element('amount', 'top')
            const w = this.element('amount', 'width')
            const h = this.element('amount', 'height')

            if (data.template.name === '50/50' && this.package === undefined) {
                //draw circle
                this.circle(x, y, w / 2, '#EEEEEE', false)

            } else {
                // draw rectangle

                let hasBackgroundColor = this.hasElement('amount', 'background')
                const color = hasBackgroundColor ? this.element('amount', 'background', 'text') : '#fff'
                this.ctx.globalAlpha = hasBackgroundColor ? 1 : 0

                this.rectangle(x, y, w, h, color)

                this.ctx.globalAlpha = 1
            }

        },

        drawTexts(data) {

            if (data.template.name !== 'Custom') {

                let textSize = this.setMaxedFontSize(data.fonts.main, '', 1000, this.defaults.GIFTCARD_TITLE, this.element('content', 'width'))
                textSize *= data.fonts.main_size

                this.text(
                    this.element('text-main', 'left'),
                    this.element('text-main', 'top') + 24,
                    this.defaults.GIFTCARD_TITLE,
                    textSize,
                    data.fonts.main,
                    '',
                    data.color,
                    'center')

                if (this.package === undefined) {
                    this.text(this.element('text-subtitle', 'left'), this.element('text-subtitle', 'top') + 12, this.defaults.GIFTCARD_SUBTITLE, 24, data.fonts.subtitle, '', '#000', 'center')
                }
            }

            const amountColor = data.template.name !== 'Custom' ? '#000' : data.color
            if (this.package === undefined) {
                let size = 60 * data.fonts.amount_size

                if (this.amount !== null && this.amount !== 0) {
                    this.text(this.element('amount', 'left'), this.element('amount', 'top') + (size * 0.4), this.defaults.GIFTCARD_VALUE.toString(), size, data.fonts.amount, '', amountColor, 'center')
                    this.text(this.element('amount', 'left') - 40, this.element('amount', 'top') + (size * 0.3), this.defaults.VALUTA_SIGN, size * 0.6, data.fonts.amount, '', amountColor, 'center')
                }
            } else {

                let containerWidth = 216
                const textSize = this.setMaxedFontSize(data.fonts.subtitle, '', 30, this.package, containerWidth, 20)

                // In GiftyFpdi file there is an extra 8mm offset added for our own templates
                // "For our own predefined templates it looks better to move the package title a little higher"
                let yOffset = 0
                if (data.template.name !== 'Custom') {
                    yOffset = 20
                }

                this.textBlock(
                    this.package,
                    this.element('amount', 'left'),
                    this.element('amount', 'top') - yOffset,
                    containerWidth,
                    textSize,
                    1.0,
                    'center',
                    data.fonts.subtitle,
                    '',
                    amountColor
                )
                // this.text(this.element('text-subtitle', 'left'), this.element('text-subtitle', 'top') + 12 + 42, this.package, 24, data.fonts.subtitle, '', '#000', 'center')
            }
        }
    },
}
</script>
