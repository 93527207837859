<template>
	<div id="page-header" :class="{ 'no-bg': !background }">
		<section class="section header-section">
			<div class="container">
				<h1 class="title is-1">{{ headerInfo.title }}</h1>

				<div class="content" v-if="headerInfo.description">
					<div class="columns">
						<div class="column is-half">
							<p v-html="headerInfo.description"></p>
						</div>
					</div>
				</div>
				<slot name="header-custom"></slot>
				<header-menu v-if="headerInfo.submenu && headerInfo.submenu.active !== false" :items="headerInfo.submenu.items"></header-menu>
			</div>
		</section>
	</div>
</template>

<script>
    import HeaderMenu from './HeaderMenu.vue'

    export default {
        components: {
            HeaderMenu,
        },

        props: {
            headerInfo: {
                type: Object,
                required: true,
            },
            background: {
                type: Boolean,
                default: true,
            }
        },

        mounted () {
            if (this.headerInfo && typeof this.headerInfo.meta !== 'undefined' && this.headerInfo.meta.pageTitle) {
                document.title = `${ this.headerInfo.meta.pageTitle } | Gifty`
            }
        }
    }
</script>
