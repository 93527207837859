import store from '../store/'

export const entitiesApi = {

    getEntities () {
        return axios.get(`/api/company/${store.getters['user/activeCompanyId']}/entity`)
    },

    deleteEntity (entity_id) {
        return axios.delete(`/api/company/${store.getters['user/activeCompanyId']}/entity/${entity_id}`)
    }

}
