<template>
    <ModalLayout
        :visible="visible"
        @destroy="destroy"
        @success="success"
        :success-disabled="false"
        :tabs="tabItems"
        :tabActive="activeTab"
        @tabSelected="update_preview"
    >
        <template v-slot:title>Factuur aanmaken</template>

        <template v-slot:settingArea>
            <p>De factuur wordt na het aanmaken direct verzonden naar het bedrijf en kan niet meer gewijzigd worden.</p>

            <g-form :action="form_endpoint" :form="form" @success="destroy" @changed="update_preview"
                    :before="before" ref="form"
                    :prepopulate="invoice_copy" @prepopulated="set_invoice_copy" message="Factuur aangemaakt">
                <accordeon>
                    <accordeon-item title="Gegevens" :active="activeStep === 0" @select="activeStep = 0">
                        <g-input name="number" label="Factuurnummer" disabled/>
                        <g-input name="date" label="Factuurdatum" disabled/>
                        <g-input name="reference" label="Kenmerk"/>
                    </accordeon-item>
                    <accordeon-item title="Factuurregels" :active="activeStep === 1" @select="activeStep = 1">

                        <div class="invoice-line autocomplete-container" v-for="(key) in invoiceLines"
                             :key="'invoice_line_' + key">
                            <g-input type="number" :name="`line_quantity[${key}]`"
                            />
                            <div class="invoice-details" v-click-outside="() => close_preset_modal(key)">
                                <g-input
                                    :name="`line_name[${key}]`"
                                    placeholder="Titel"
                                    @focus="presets_visible = key"
                                />
                                <g-input :name="`line_description[${key}]`" placeholder="Omschrijving"/>

                                <ul class="autocomplete-options" v-if="presets_visible === key">
                                    <li v-for="preset in invoice_presets_filtered(key)"
                                        @click="set_preset(preset, key)">
                                        <strong>{{ preset.name }}</strong>
                                        <small class="help">{{ preset.amount | currency }}</small>
                                    </li>
                                </ul>
                            </div>
                            <div class="invoice-ledger-account">
                                <g-select :name="`line_ledger_account_code[${key}]`"
                                          :options="ledgerAccountsPrefixedWithCode"
                                          valuekey="code"/>
                            </div>
                            <div class="price">
                                <g-format :name="`line_amount[${key}]`" type="positiveOrNegativeCurrency"/>
                            </div>

                            <div class="item-button" @click="remove_invoice_line(key)">
                                <div class="item-icon"><i class="icon">delete</i></div>
                            </div>
                        </div>

                        <div class="item-button is-success" @click="add_invoice_line()">
                            <div class="item-icon"><i class="icon">add</i></div>
                            <div class="item-text">Regel toevoegen</div>
                        </div>
                    </accordeon-item>
                </accordeon>
            </g-form>
        </template>

        <template v-slot:previewArea>
            <tabbed-view @tabsInitialized="(tabs) => tabItems = tabs">
                <tabbed-view-item
                    title="Voorbeeld"
                    :active="activeTab === 0"
                >
                    <iframe width="100%" height="100%" ref="previewFrame"/>
                </tabbed-view-item>
            </tabbed-view>
        </template>
    </ModalLayout>
</template>

<script>
import ModalLayout from "../ModalLayout.vue";
import Accordeon from "../../Popup/Accordeon.vue";
import AccordeonItem from "../../Popup/AccordeonItem.vue";
import TabbedView from "../TabbedView.vue";
import TabbedViewItem from "../TabbedViewItem.vue";
import {mapGetters} from "vuex";
import Fuse from "fuse.js";
import ClickOutside from "../../ClickOutsideDirective";

export default {
    name: "DigitalOrderCreate",
    components: {TabbedViewItem, TabbedView, AccordeonItem, Accordeon, ModalLayout},
    directives: {
        'click-outside': ClickOutside
    },
    props: {
        company_id: String,
        invoice_copy: Number,
    },
    data() {
        return {
            activeStep: 0,
            activeTab: 0,
            tabItems: [],
            prepopulated: false,
            presets_visible: false,
            invoiceLines: [],
            form: new Form({
                number: '',
                reference: '',
                date: '',
            }),
        }
    },
    mounted() {
        // Set the form date to today localized
        this.form.date = this.$d(new Date(), 'numericDate')

        // Set the invoice number to the format $YEAR-M00000001
        const year = new Date().getFullYear()
        this.form.number = `${year}-G00000001`

        // If there is no invoice copy is set, add a default invoice line
        if (!this.invoice_copy) {
            this.add_invoice_line()
        }
    },
    computed: {
        ...mapGetters({
            ledger_accounts: 'adminLedgerAccounts/all',
            invoice_presets: 'adminInvoicePresets/all',
        }),
        ledgerAccountsPrefixedWithCode() {
            return this.ledger_accounts.map(ledgerAccount => {
                return {
                    ...ledgerAccount,
                    name: `${ledgerAccount.code} - ${ledgerAccount.name}`,
                }
            })
        },
        form_endpoint() {
            let endpoint = `/api/admin/company/${this.company_id}/invoiceCreate`

            if (this.invoice_copy && this.prepopulated === false) {
                endpoint += `/${this.invoice_copy}`
            }

            return endpoint
        },
    },
    methods: {
        success() {
            // Submit the form
            this.$refs.form.submit()
        },
        close_preset_modal(key) {
            if (this.presets_visible !== key) {
                return
            }

            this.presets_visible = false
        },
        invoice_presets_filtered(key) {
            let field = this.form[`line_name[${key}]`];

            if (field.length === 0) {
                return this.invoice_presets;
            }

            const options = {
                keys: ['name', 'description']
            }

            const fuse = new Fuse(this.invoice_presets, options)

            return fuse.search(field).map(result => result.item)
        },
        add_invoice_line(data) {
            // Merge data with defaults and a unique key
            const key = Math.random().toString(36).substring(7)
            const field = Object.assign({
                key: key,
                name: '',
                description: '',
                amount: null,
                quantity: 1,
                ledger_account_code: null,
            }, data);
            this.invoiceLines.push(field.key)

            this.form.addField(`line_name[${key}]`, field.name)
            this.form.addField(`line_description[${key}]`, field.description)
            this.form.addField(`line_amount[${key}]`, field.amount)
            this.form.addField(`line_quantity[${key}]`, field.quantity)
            this.form.addField(`line_ledger_account_code[${key}]`, field.ledger_account_code)
        },
        remove_invoice_line(key) {
            this.invoiceLines = this.invoiceLines.filter(item => item !== key)

            this.form.removeField(`line_name[${key}]`)
            this.form.removeField(`line_description[${key}]`)
            this.form.removeField(`line_amount[${key}]`)
            this.form.removeField(`line_quantity[${key}]`)
            this.form.removeField(`line_ledger_account_code[${key}]`)
        },
        set_preset(preset, key) {
            const ledgerAccount = this.ledger_accounts.find(ledgerAccount => ledgerAccount.id === preset.ledger_account_id)

            this.form[`line_name[${key}]`] = preset.name
            this.form[`line_description[${key}]`] = preset.description ?? this.form[`line_description[${key}]`]
            this.form[`line_amount[${key}]`] = preset.amount ?? this.form[`line_amount[${key}]`]
            this.form[`line_ledger_account_code[${key}]`] = ledgerAccount.code

            this.presets_visible = false
        },
        set_invoice_copy(data) {
            // Get all form fields that start with line_
            const lineFields = Object.keys(data.data).filter(key => key.startsWith('line_'))

            // Group the line fields by their index with their value. Remove the line_ prefix.
            const lineFieldsGrouped = lineFields.reduce((acc, key) => {
                const index = key.match(/\[(\d+)]/)[1]
                const field = key.replace(`[${index}]`, '').replace('line_', '')

                if (!acc[index]) {
                    acc[index] = {}
                }

                acc[index][field] = data.data[key]

                return acc
            }, {})

            // Loop over the line fields and add to the form fields
            Object.keys(lineFieldsGrouped).forEach(index => {
                this.add_invoice_line(lineFieldsGrouped[index])
            })

            // Set the form date to today localized
            this.form.date = this.$d(new Date(), 'numericDate')

            // Set the invoice number to the format $YEAR-M00000001
            const year = new Date().getFullYear()
            this.form.number = `${year}-G00000001`

            this.prepopulated = true;
        },
        before(form) {
            // Convert line_amount number to integer
            this.invoiceLines.forEach(key => {
                // If the field is an integer, just multiply by 100 to convert to cents
                if (typeof form[`line_amount[${key}]`] === 'number') {
                    form[`line_amount[${key}]`] *= 100
                }

                // If the field is a string, replace the comma with a dot and multiply by 100 to convert to cents
                if (typeof form[`line_amount[${key}]`] === 'string') {
                    form[`line_amount[${key}]`] = form[`line_amount[${key}]`].replace(/,/g, '.') * 100
                }

                // Round to full integer
                form[`line_amount[${key}]`] = Math.round(form[`line_amount[${key}]`])
            })

            return form
        },
        async update_preview(data) {
            let parsedData = await this.$refs.form.getParsedFormData();

            axios
                .post(
                    `/api/admin/company/${this.company_id}/invoiceCreate/preview`,
                    parsedData
                )
                .then(response => {
                    // Set the iframe src to the response data
                    this.$refs.previewFrame.src = `data:application/pdf;base64,${response.data}`
                })
                .catch(error => {
                    console.error(error)
                })
        },
    },
}
</script>

<style scoped lang="scss">
::v-deep {
    form {
        flex: 1;
        display: flex;
        overflow-y: hidden;

        .invoice-line {
            display: flex;
            align-items: center;
            margin-bottom: .5rem;

            > div {
                flex: 2;
                margin-right: .5em;

                &:last-child {
                    flex: 1;
                    margin-right: 0;
                }
            }

            .price {
                flex: 3;
            }

            .invoice-ledger-account {
                flex: 4;
            }

            .field {
                margin-bottom: 0;

                input, select {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }

            .invoice-details {
                flex: 8;
                border-radius: 6px;
                border: 1px solid #EFEFEF;
                padding: 0.375em 1.1rem;
                box-shadow: 0 5px 27px 3px rgb(213 213 213 / 35%);

                .field {
                    .control {
                        font-size: 0;
                    }

                    input {
                        line-height: 1;
                        padding: 0;
                        border: 0;
                        box-shadow: none !important;
                    }

                    &:first-child input {
                        color: #1f1f1f;
                    }
                }
            }
        }
    }
}
</style>
