<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('admin.gift_card.top_up_modal.title') }}</h1>
                    </div>

                    <g-form :form="form"
                            :action="`/api/admin/giftcard/${this.id}/top-up`"
                            method="post"
                            @success="this.destroy">

                        <div class="popup-body">
                            <p>{{ $t('admin.gift_card.top_up_modal.description') }}</p><br/>

                            <g-input name="currency" hidden/>

                            <g-select :options="locations" name="location_id" valuekey="id"
                                      :label="$t('admin.gift_card.top_up_modal.location')"/>

                            <g-format type="currency" name="amount" :label="$t('admin.gift_card.top_up_modal.amount')"/>

                            <g-input type="textarea" max="191" autosize name="reason"
                                     :label="$t('admin.gift_card.top_up_modal.reason')"/>
                        </div>

                        <div class="popup-footer">
                            <g-button>{{ $t('confirmation_dialog.confirm') }}</g-button>
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('confirmation_dialog.deny') }}</a>
                        </div>

                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: Number,
    },

    data() {
        return {
            locations: [{
                id: null,
                name: this.$t('admin.gift_card.top_up_modal.headquarters'),
            }],

            form: new Form({
                location_id: null,
                amount: 0,
                reason: '',
                currency: 'EUR',
            })
        }
    },

    mounted() {
        // Fetch the locations
        axios.get(`/api/admin/giftcard/${this.id}/top-up`)
            .then(response => this.setData(response.data))
    },

    methods: {
        setData({data}) {
            // Append the locations to the form
            this.locations.push(...data)
        }
    }
}
</script>
