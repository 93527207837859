import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import giftcards from './modules/giftcards'
import orderDeliveries from './modules/orderDeliveries'
import promotionalOrders from './modules/promotionalOrders'
import entities from './modules/entities'
import packages from './modules/packages'
import locations from './modules/locations'
import application from './modules/application'
import adminPartners from './modules/admin/partners'
import adminCampaigns from './modules/admin/campaigns'
import adminGiftcards from './modules/admin/giftcards'
import adminInventory from './modules/admin/inventory'
import adminAffiliates from './modules/admin/affiliates'
import adminLedgerAccounts from './modules/admin/ledger_accounts'
import adminInvoicePresets from './modules/admin/invoice_presets'
import adminUsers from './modules/admin/users'
import builder from "./modules/builder";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        user,
        giftcards,
        locations,
        application,
        orderDeliveries,
        promotionalOrders,
        entities,
        packages,
        builder,
        adminPartners,
        adminCampaigns,
        adminGiftcards,
        adminInventory,
        adminAffiliates,
        adminLedgerAccounts,
        adminInvoicePresets,
        adminUsers,
    },
    strict: debug
})
