<template>
    <div id="mail-preview">
        <div class="meta">
            <div>
                <strong>{{ $t('digital.email.view_item.from') }}</strong>
                {{ senderName }} <i v-if="senderEmail">&lt;{{ senderEmail }}&gt;</i>
            </div>

            <div>
                <strong>{{ $t('digital.email.view_item.subject') }}</strong>
                {{ subject === '' ? $t('digital.email.view_item.no_subject') : subject }}
            </div>

            <div class="attachments" v-if="attachments">
                <strong>{{ $t('digital.email.view_item.attachment') }}</strong>
                <div class="attachment" v-for="attachment in attachments">
                    <i class="icon">attachment</i>
                    {{ attachment }}
                </div>
            </div>
        </div>

        <div class="email-bg">
            <div class="email-body">
                <div class="email-body-header">
                    <slot name="header"></slot>
                </div>

                <div class="email-body-content">
                    <slot name="body"></slot>
                </div>

                <div class="email-support-footer">
                    <slot name="footer">
                        Heb je vragen over deze e-mail? Mail dan naar <u>info@gifty.nl</u> of reageer op deze e-mail en
                        vermeld daarbij je referentienummer: 2024235691. We beantwoorden je vraag zo snel mogelijk.
                    </slot>
                </div>
            </div>

            <div class="email-footer">
                Gifty BV - KvK 69506981 - <u>info@gifty.nl</u> - <u>algemene voorwaarden</u> - <u>privacyverklaring</u>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmailViewItem',
    props: {
        senderName: {
            type: String,
        },
        senderEmail: {
            type: String,
        },
        subject: {
            type: String,
        },
        attachments: {
            type: Array,
        },
    }
}
</script>

<style scoped lang="scss">
#mail-preview {
    background-color: #ECEFF0;
    font-size: 0.875rem;
    color: #6A7272;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.email-bg {
    padding: 2.5rem;
    overflow-y: scroll;
    flex: 1;
    flex-grow: 2;
}

.email-body {
    max-width: 31.25rem;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;

    .email-body-header {
        padding: 2rem 0;
        text-align: center;

        img {
            width: 11rem;
            max-height: 5.5rem;
        }
    }

    .email-body-content {
        padding: 1rem 2.1875rem;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .email-support-footer {
        text-align: center;
        padding: 1.325rem 2.297rem;
        border-top: 0.063rem solid #F9F9F9;

        u {
            color: #000;
        }
    }
}

.email-footer {
    text-align: center;
    color: #A7A7A7;
    font-size: 0.688rem;
    margin-top: 0.625rem;
}

.meta {
    font-size: 0.875rem;
    padding: 0.8rem 1rem;
    background-color: #fff;

    .attachments {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        strong {
            margin-right: 0.2rem;
        }

        .attachment {
            background-color: #F5F5F5;
            color: #4A4A4A;
            padding: 0 0.3rem;
            font-size: 0.75rem;
            border-radius: 0.188rem;
            margin: 0.1rem 0.2rem 0.1rem 0;
            flex: 0 0 auto;
            white-space: nowrap;

            .icon {
                position: relative;
                top: 0.15rem;
                font-size: 0.8rem;
            }
        }
    }
}
</style>
