<template>
    <div :class="['field', 'upload', 'has-addons', { 'error': hasError, 'animation': labelAnimation }]">
		<div :class="['control is-expanded', gClass, { 'has-icons-right': hasError || !!icon }]">
			<input type="text"
                   :disabled="disabled"
				   :value="fileName"
				   :class="['input', size, { 'is-danger': hasError }]"
				   @focus="preventFocus"/>

            <i class="icon" :class="['is-right', size]" v-if="!hasError && !!icon">{{ icon }}</i>
            <i class="icon" :class="['is-right', 'is-danger', size]" v-if="hasError">error</i>

			<div class="hover-area" v-if="hasError" @mouseover="showErrorMessage" @mouseleave="hideErrorMessage">
				<div class="message-box">
					<div class="error-message" v-show="errorMessageVisible">{{ errorMessage }}</div>
				</div>
			</div>

			<label :class="['label', { 'active': isInputActive }]" :for="id">
				{{ labelText }}
			</label>
		</div>

		<input type="file" @change="upload" :accept="accept" ref="file" :disabled="disabled">

		<div class="control">
			<a class="button is-success" @click="openUploadDialog" :disabled="disabled">{{ this.$t('g-file.select') }}</a>
		</div>
	</div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'

export default InputHelper.extend({
        computed: {
            file: {
                get () {
                    return this.data[this.name]
                },
                set (file) {
                    this.data[this.name] = file
                }
            },
            fileName () {
                return this.file ? this.file.name : ''
            },
        },
        methods:  {
            preventFocus (event) {
                event.target.blur()
                this.openUploadDialog()
            },

            upload (event) {
                // Emit change event to parent
                this.$emit('change', event)

                const file = event.target.files[0]

                if (file.size > 5 * Math.pow(10, 6)) {
                    this.$buefy.toast.open({
                        message: 'Deze afbeelding is te groot!',
                        type:    'is-danger',
                    })
                }
                this.file = file
            },

            openUploadDialog () {
                if (this.disabled === true) {
                    return
                }

                this.$refs.file.click()
            },
        }
    })
</script>
