import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '../routes'
import Guard from './Guard'

Vue.use(VueRouter)

const router = new VueRouter({
    routes,
    mode:            'history',
    linkActiveClass: 'is-active',
})

router.beforeEach((to, from, next) => {

    let title      = to.meta.title
    document.title = !!title ? `${title} | Gifty` : 'Gifty'

    Guard.hasAccess(to, from, next)
})

export default router
