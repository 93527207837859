<template>
    <div>
        <div id="account-nav">
            <div class="container">
                <div class="controls">
                    <div id="language-switch">
                        <div v-for="locale in locales"
                             :key="locale.value"
                             @click="setLocale(locale.value)"
                             :class="{ 'selected': selectedLocale === locale.value }">
                            {{ locale.name }}
                        </div>
                    </div>
                    <div id="company-switch" v-if="this.$store.getters['user/allCompanies'].length > 1">
                        <i class="icon">supervised_user_circle</i>
                        <select v-model="selectedCompany">
                            <option disabled>{{ $t('navigation.company_select') }}</option>
                            <option v-for="company in this.$store.getters['user/allCompanies']" :key="company.id"
                                    :value="company.id">
                                {{ company.name }}
                            </option>
                        </select>
                    </div>
                    <div id="personal-nav">
                        <a href="https://gifty.nl/nl/contact" target="_blank" rel="noopener">
                            <i class="icon selected">support</i>
                            {{ $t('navigation.support') }}
                        </a>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin'}">
                            {{ $t('navigation.admin') }}
                        </router-link>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin.marketing'}">
                            {{ $t('navigation.admin_marketing') }}
                        </router-link>

                        <router-link v-if="this.$store.getters['user/isGiftyAdmin']" :to="{name: 'admin.accounting'}">
                            {{ $t('navigation.admin_accounting') }}
                        </router-link>

                        <router-link :to="{name: 'settings.account'}">{{ $t('navigation.my_account') }}</router-link>

                        <a @click="this.logout">{{ $t('navigation.logout') }}</a>
                    </div>
                </div>
            </div>
        </div>

        <nav :class="['navbar', { 'navbar-builder': showNavbarBuilder }]">
            <div class="container">
                <div class="navbar-brand">
                    <router-link :to="{name: 'home'}" class="navbar-item" exact>
                        <img src="../../../images/logo.svg" width="100" height="40">
                    </router-link>

                    <a role="button" class="navbar-burger" :class="{'is-active': navActive}" aria-label="menu"
                       @click="navActive = !navActive">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div class="navbar-menu" v-bind:class="{'is-active': navActive}">
                    <div class="navbar-end">
                        <router-link class="navbar-item" :to="{ name: 'home'}" exact>
                            {{ $t(redeemNavText) }}
                        </router-link>

                        <router-link
                            v-for="item in menuItems"
                            :key="item.route_name"
                            class="navbar-item is-hidden-touch"
                            :to="getRoute(item.route_name)"
                            v-if="allowedRoute(item.route_name) && item.main">
                            {{ $t(item.title) }}
                        </router-link>

                        <!-- Mobile items -->
                        <template v-for="item in menuItems" v-if="allowedRoute(item.route_name)">
                            <menu-item-dropdown
                                v-if="item.submenu_items"
                                :title="item.title"
                                :sub-menu-items="item.submenu_items"
                                class="is-hidden-desktop"
                            ></menu-item-dropdown>

                            <router-link
                                v-else
                                class="navbar-item is-hidden-desktop"
                                :to="getRoute(item.route_name)">
                                {{ $t(item.title) }}
                            </router-link>
                        </template>

                        <a href="/logout" class="navbar-item is-hidden-desktop"
                           @click.prevent="logout">{{ $t('navigation.logout') }}</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {allowedRoute, getRoute, menuItems} from '../../menu'
import MenuItemDropdown from '../MenuItemDropdown'
import i18n from '../../utils/i18n'

export default {
    components: {
        MenuItemDropdown
    },
    data() {
        return {
            selectedCompany: null,
            selectedLocale: this.$i18n.locale,
            navActive: false,
            menuItems,
            getRoute,
            allowedRoute,
        }
    },

    watch: {
        $route() {
            this.navActive = false
        },
        storeActiveCompanyId(companyId) {
            this.selectedCompany = companyId
        },
        selectedCompany(companyId, oldValue) {
            if (oldValue === null || companyId === oldValue) {
                return
            }

            this.$store.dispatch('user/setCompanyActive', companyId)
        },
        selectedLocale(lang) {
            localStorage.setItem('locale', lang)
            i18n.locale = lang
            document.querySelector('html').setAttribute('lang', lang)

            axios.patch(`/api/user/${this.$store.getters['user/id']}`, {
                locale: lang
            })
        },
    },

    computed: {
        ...mapGetters({
            hasPermission: 'user/hasPermission',
            storeActiveCompanyId: 'user/activeCompanyId',
            locales: 'application/availableLocalesTranslated',
        }),
        redeemNavText() {
            if (
                this.hasPermission('giftcard_redeem') &&
                this.hasPermission('giftcard_issue') === false
            ) {
                return 'navigation.giftcard_redeem'
            }

            if (
                this.hasPermission('giftcard_issue') &&
                this.hasPermission('giftcard_redeem') === false
            ) {
                return 'navigation.giftcard_issue'
            }

            return 'navigation.giftcard_redeem_or_issue'
        },
        showNavbarBuilder() {
            const builderPages = [
                'settings.giftcard.builder',
                'settings.module.packages.create',
                'settings.module.packages.edit',
            ]
            return builderPages.includes(this.$route.name)
        },
    },

    methods: {
        logout() {
            this.$store.dispatch('user/logout')
                .then(() => {
                    this.$router.push({name: 'auth.login'})
                })
        },
        setLocale(locale) {
            this.selectedLocale = locale
        }
    },

    mounted() {
        this.selectedCompany = this.storeActiveCompanyId

        if (
            localStorage.hasOwnProperty('impersonatePreferredCompany') &&
            localStorage.getItem('impersonatePreferredCompany') !== null &&
            this.$store.getters['user/isGiftyAdmin']
        ) {
            this.$store.dispatch('user/setCompanyActive', localStorage.getItem('impersonatePreferredCompany'))
                .then(() => {
                    localStorage.removeItem('impersonatePreferredCompany')
                })
        }
    },
}
</script>


<style scoped lang="scss">
#account-nav {
    background: #FFFFFF;

    @media screen and (max-width: 1023px) {
        display: none;
    }

    .container {
        border-bottom: 1px solid #F1F5F8;
    }

    .controls {
        display: flex;

        i.icon {
            color: #1f1f1f;
            font-size: 1.1rem;
            vertical-align: text-bottom;
        }

        #language-switch {
            > div {
                color: #1F1F1F;
                cursor: pointer;
                font-size: 0.8rem;
                line-height: 1;
                margin: 0.94rem 0;
                padding: 0 .4rem;
                display: inline-block;
                border-right: 2px solid #EFEFEF;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    border-right: 0;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .selected {
                font-weight: 600;
            }
        }

        #company-switch {
            padding-left: 3px;
            margin: 8px 0;
            flex: 0;
            display: flex;
            align-items: center;

            select {
                font-size: 0.8rem;
                max-width: 160px;
                padding: 3px 23px 3px 3px;
                border: 0;
                background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M24 24H0V0h24v24z' fill='none'/><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/></svg>") no-repeat center right;
                appearance: none;

                &:after {
                    font-family: 'Material Icons Outlined';
                    content: "expand_more";
                }
            }
        }

        #personal-nav {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                color: #1F1F1F;
                font-size: .8rem;
                padding: 8px;

                &:hover {
                    text-decoration: underline;
                }

                &:last-child {
                    padding-right: 6px;
                }
            }
        }
    }
}
</style>
