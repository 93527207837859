<template>
    <b-field
        :label="labelText"
        :message="errorMessage"
        :type="errorType">
        <div v-if="typeof $attrs.buttons === 'undefined'">
            <b-radio v-for="option in (isArray ? array : options)"
                     v-model="data[name]"
                     v-bind="$attrs"
                     :class="{ 'b-radio-new-line': typeof $attrs.inline === 'undefined'}"
                     :key="option[valuekey]"
                     :native-value="option[valuekey]"
                     @input="emit">
                {{ option[namekey] }}
            </b-radio>
        </div>
        <b-radio-button v-else
                        v-for="option in (isArray ? array : options)"
                        v-model="data[name]"
                        v-bind="$attrs"
                        :key="option[valuekey]"
                        :native-value="option[valuekey]"
                        size="is-medium"
                        @input="emit">
            <span>{{ option[namekey] }}</span>
        </b-radio-button>
    </b-field>
</template>

<script>
import InputHelper from '../../utils/InputHelper'

export default InputHelper.extend({
    data () {
        return {
            isArray: false,
            array:   [],
        }
    },
    props: {
        options:  {required: true},
        valuekey: {default: 'value'},
        namekey:  {default: 'name'}
    },
    created () {
        try {
            if (this.options.toString() === '[object Object]' && !(this.options instanceof Array)) {

                for (const value in this.options) {
                    if (!this.options.hasOwnProperty(value)) continue
                    const name = this.options[value]
                    this.array.push({
                        name,
                        value
                    })
                }
                this.isArray = true

            } else if ('string' === typeof this.options[0]) {

                this.options.forEach(option => {
                    this.array.push({
                        name:  option,
                        value: option,
                    })
                })
                this.isArray = true
            }
        } catch (exception) {

        }
    }
})

</script>

<style>
.b-radio-new-line {
    display: block;
    width: 100%;
    margin-left: .5em;
}
</style>
