<template>
    <div class="notification is-warning is-radiusless is-marginless">
        <div>Je bent ingelogd als <strong>{{ this.user.user.first_name }}</strong> van <strong>{{
                this.$store.getters['user/activeCompany'].name
            }}</strong>.
        </div>
        <button class="button is-white" :class="{ 'is-loading': loading }" @click="logout">Uitloggen</button>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            user: 'user',
        }),
    },

    data () {
        return {
            loading: false,
        }
    },

    methods: {
        logout () {
            this.loading = true
            const impersonatedAsUser = this.$store.getters['user/isImpersonatedUser'] ?? false

            this.$store.dispatch('user/impersonatedLogout')
                .then(() => {
                    this.$router.push({
                        name: impersonatedAsUser ? 'admin.users'
                                                 : 'admin.companies'
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>
