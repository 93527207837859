import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            form: false,
            data: this.$parent.$data,
            id: 'g-form-input',
            focus: false,
            errorMessageVisible: false,
        }
    },
    created() {
        /**
         * This method is used to find the parent form of the input.
         * It will look for the form in the parent, grandparent and great-grandparent.
         * If it finds the form, it will set the form property to true and the data property to the form data.
         *
         * Todo: add support for setting a form reference directly on the input component, this will allow for more flexibility and less coupling.
         * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#form
         */
        let parent = this.$parent;

        for (let i = 0; i < 3; i++) {
            if (
                !!parent.$props &&
                !!parent.$props.hasOwnProperty('form') &&
                !!parent.$props.form.hasOwnProperty(this.name) &&
                parent.$vnode.tag.indexOf('g-form') !== -1
            ) {
                this.form = true
                this.data = parent.$props.form

                break
            }

            if(!parent.$parent) break

            parent = parent.$parent
        }

        // If this input is used without g-form, attach it to the first parent containing its property
        if(this.form === false) {
            parent = this.$parent

            for (let i = 0; i < 3; i++) {
                if (
                    !!parent.$data &&
                    !!parent.$data.hasOwnProperty(this.name)
                ) {
                    this.data = parent.$data
                    break
                }

                if(!parent.$parent) break

                parent = parent.$parent
            }
        }

        this.id = 'g-form-input-' + (Math.floor(Math.random() * 1000000))
    },
    inject: {
        formLocale: {
            default: () => () => 'en' // Default fallback
        },
        isTranslatable: {
            default: () => () => false
        }
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        label: {
            type: [String, Boolean],
            default: () => false,
        },
        type: {
            type: String,
            default: 'text',
        },
        size: {
            type: String,
            default: 'is-normal',
        },
        gClass: {
            type: String,
            default: '',
        },
        accept: {
            type: String,
            default: ''
        },
        animation: {
            type: [Boolean, String],
            default: true
        },
        placeholder: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        translatable: {
            type: Boolean,
            default: false
        }

    },
    methods: {
        emit(e) {
            this.$emit('input', e)
        },
        onInputFocus(e) {
            this.focus = true
            this.showErrorMessage()
            this.$emit('focus', e)
        },
        onInputBlur(e) {
            this.focus = false
            this.hideErrorMessage()
            this.$emit('blur', e)
        },

        showErrorMessage() {
            this.errorMessageVisible = true
        },
        hideErrorMessage() {
            this.errorMessageVisible = false
        },

        focusOnInput() {
            this.$el.querySelector(`#${this.id}`).focus()
        }
    },
    computed: {
        errorMessage() {
            if (this.form && this.data.errors.has(this.name)) {
                return this.data.errors.get(this.name)
            }
        },
        labelText() {
            if (!!this.label) {
                return this.label
            }
        },
        errorType() {
            return this.hasError ? 'is-danger' : ''
        },
        hasError() {
            return this.form && this.data.errors.has(this.name)
        },

        isInputActive() {
            return !!this.placeholder || this.focus || !!this.data[this.name]
        },
        labelAnimation() {
            return (typeof this.animation === 'boolean' ? this.animation : this.animation.toLowerCase() === 'true') && !!this.label
        },

        currentLocale() {
            return this.formLocale()
        },
        currentLocaleName() {
            if (!this.currentLocale) return ''
            const locale = this.$store.getters['application/availableLocalesTranslated']
                .find(l => l.value === this.currentLocale)
            return locale ? locale.name : this.currentLocale
        },
        localizedKey() {
            return this.isTranslatable(this.name)
                ? `${this.name}.${this.currentLocale}`
                : this.name;
        },
        inputValue: {
            get() {
                return this.data[this.localizedKey];
            },
            set(value) {
                this.$set(this.data, this.localizedKey, value);
            }
        },
        isFieldTranslatable() {
            return this.translatable && this.isTranslatable(this.name)
        }
    },

})
