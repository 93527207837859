<template>
    <div :class="['field', { 'error': hasError, 'animation': labelAnimation }]">
        <div :class="['control is-expanded has-icons-right has-icons-left', gClass]">
            <input v-model="data[name]"
                   v-bind="$attrs"
                   :type="type"
                   :id="id"
                   @input="emit"
                   @focus="onInputFocus"
                   @blur="onInputBlur"
                   :placeholder="placeholder"
                   :class="['input', { 'is-danger': hasError }]">

            <i class="is-left icon">search</i>
            <i @click="data[name] = ''"
               class="is-right icon is-clickable"
               v-if="data[name] && data[name].length"
            >close</i>
        </div>
        <div class="hover-area"
             v-if="hasError"
             @mouseover="showErrorMessage"
             @mouseleave="hideErrorMessage">
            <div class="message-box">
                <div class="error-message" v-show="errorMessageVisible">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'

export default InputHelper.extend({})
</script>
