import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '../lang/en'
import nl from '../lang/nl'

Vue.use(VueI18n)

const datetimeFormats = {
    'en': {
        numeric: {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit'
        },
        numericDate: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'nl': {
        numeric: {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit'
        },
        numericDate: {
            year: 'numeric', month: '2-digit', day: '2-digit'
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
}

export default new VueI18n({
    locale: localStorage.getItem('locale') || 'nl',
    fallbackLocale: 'en',
    datetimeFormats,
    messages: {
        en,
        nl,
    },
})
