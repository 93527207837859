<template>
	<div :class="['field datepicker', { 'error': hasError, 'animation': labelAnimation }]">

		<label v-if="!labelAnimation && labelText"
			   class="label"
			   :for="id">
			{{ labelText }}
		</label>
		<div :class="['control is-expanded', gClass, { 'has-icons-right': hasError || !!icon }]">
			<date-picker v-model="dates"
						 :type="type"
						 :lang="lang"
						 :input-class="{'input': true, 'is-danger': hasError}"
						 :range="!!endDate"
						 :shortcuts="shortcuts"
						 :editable="false"
						 :first-day-of-week="1"
						 :format="format"
						 width="100%"
						 :not-after="notAfter"
						 :not-before="notBefore"
						 range-separator="-"
						 placeholder=""
						 :clearable="false">
				<span slot="calendar-icon"></span>
			</date-picker>
            <i class="icon is-right" :class="[size]" v-if="!hasError && !!icon && !data[name]">{{ icon }}</i>
            <i class="icon is-right is-clickable" :class="[size]" v-else-if="!hasError && !!data[name]" @click="clearDates">clear</i>
            <i class="icon is-right is-danger" :class="[size]" v-else-if="hasError">error</i>
		</div>
		<div class="hover-area"
			 v-if="hasError"
			 @mouseover="showErrorMessage"
			 @mouseleave="hideErrorMessage">
			<div class="message-box">
				<div class="error-message" v-show="errorMessageVisible">
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<label v-if="labelAnimation"
			   :class="['label', { 'active': isInputActive }]"
			   :for="id">
			{{ labelText }}
		</label>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker/lib/datepicker.js'
import InputHelper from '../../utils/InputHelper'
import isArray from 'lodash/isArray'

export default InputHelper.extend({
        components: {DatePicker},
        props:      {
            disableFuture: {
                default: false,
                type:    Boolean,
            },
            disablePast: {
                default: false,
                type:    Boolean,
            },
            notToday: {
                default: false,
                type:    Boolean,
            },
            lang:          {
                default () {
                    return {
                        days:        ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vri', 'Za'],
                        months:      ['Jan', 'Feb', 'Ma', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'],
                    }
                },
                type: [String, Object],
            },
            format:        {
                default: 'DD/MM/YYYY',
                type:    String,
            },
            endDate:        {
                default: false,
				type: [Boolean, String]
            },
            icon:          {
                default: 'today'

			},
            type:          {
                default: 'date',
            },
			label: {
                default: 'Selecteer datum',
			}
        },
        data () {
            return {
                shortcuts: [
                    {
                        text: 'Gisteren', onClick: () => {
							this.dates = [
                                new Date(moment().subtract(1, 'day').startOf('day')),
                                new Date(moment().subtract(1, 'day').endOf('day')),
                            ]
                        },
                    },
                    {
                        text: 'Vandaag', onClick: () => {
                            this.dates = [
                                new Date(moment().startOf('day')),
                                new Date(moment().endOf('day')),
                            ]
                        },
                    },
                    {
                        text: 'Deze week', onClick: () => {
                            this.dates = [
                                new Date(moment().startOf('isoweek')),
                                new Date(moment().endOf('isoweek')),
                            ]
                        },
                    },
                    {
                        text: 'Vorige week', onClick: () => {
                            this.dates = [
                                new Date(moment().subtract(1, 'week').startOf('isoweek')),
                                new Date(moment().subtract(1, 'week').endOf('isoweek')),
                            ]
                        },
                    },
                    {
                        text: 'Deze maand', onClick: () => {
                            this.dates = [
                                new Date(moment().startOf('month')),
                                new Date(moment().endOf('month')),
                            ]
                        },
                    },
                    {
                        text: 'Vorige maand', onClick: () => {
                            this.dates = [
                                new Date(moment().subtract(1, 'month').startOf('month')),
                                new Date(moment().subtract(1, 'month').endOf('month')),
                            ]
                        },
                    },
                    {
                        text: 'Dit jaar', onClick: () => {
                            this.dates = [
                                new Date(moment().startOf('year')),
                                new Date(moment().endOf('year')),
                            ]
                        },
                    },
                    {
                        text: 'Vorig jaar', onClick: () => {
                            this.dates = [
                                new Date(moment().subtract(1, 'year').startOf('year')),
                                new Date(moment().subtract(1, 'year').endOf('year')),
                            ]
                        },
                    },

                ],
            }
        },
		computed: {
            notAfter () {
                if (this.disableFuture) {
                    return this.notToday ? moment().subtract(1, 'd')
                                         : moment()
                }
                return ''
            },
            notBefore () {
                if (this.disablePast) {
                    return this.notToday ? moment().add(1, 'd')
                                         : moment()
                }
                return ''
            },
            dates: {
				set(dates) {
                    if (this.endDate) {

                        if (isArray(dates)) {
							this.data[this.name] = dates[0]
                            this.data[this.endDate] = dates[1]
                        } else {
                            this.data[this.name] = this.data[this.endDate] = dates
                        }
					} else {
                        this.data[this.name] = dates
                    }
				},
				get() {
                    if (this.endDate) {
                        return [
                            this.data[this.name] ? new Date(this.data[this.name]) : null,
                            this.data[this.endDate] ? new Date(this.data[this.endDate]) : null,
                        ]
                    }

                    return this.data[this.name] ? new Date(this.data[this.name]) : null
                }
			}
		},
		methods: {
            clearDates() {
                this.dates = null
            }
		},
    })

</script>
