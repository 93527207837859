var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['field', { 'error': _vm.hasError, 'animation': _vm.labelAnimation }]},[(!_vm.labelAnimation && _vm.label)?_c('label',{staticClass:"label",attrs:{"for":_vm.id}},[_vm._v("\n            "+_vm._s(_vm.labelText)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{class:['control is-expanded', { 'has-icons-right': _vm.icon || _vm.hasError }]},[_c('span',{staticClass:"select is-fullwidth"},[(!_vm.multiple)?_c('select',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data[_vm.name]),expression:"data[name]"}],class:[
                            {'select-invisible': typeof _vm.$attrs.invisible !== 'undefined'},
                            {'is-danger': _vm.hasError},
                            _vm.gClass,
                            _vm.size,
                        ],attrs:{"id":_vm.id,"disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, _vm.name, $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},'select',_vm.$attrs,false),_vm._l((_vm.computedOptions),function(option){return _c('option',_vm._b({key:option['value'],attrs:{"disabled":typeof option.disabled !== 'undefined'},domProps:{"value":option['value']}},'option',_vm.$attrs,false),[_vm._v(_vm._s(option['name'])+"\n\t\t\t\t\t")])}),0):_vm._e(),_vm._v(" "),(_vm.multiple)?_c('tree-select',_vm._b({class:[
                            {'select-invisible': typeof _vm.$attrs.invisible !== 'undefined'},
                            {'is-danger': _vm.hasError},
                            _vm.gClass,
                            _vm.size,
                        ],attrs:{"id":_vm.id,"disabled":_vm.disabled,"multiple":true,"options":_vm.computedMultipleOptions,"placeholder":_vm.$t('g-select.placeholder'),"clearable":false,"value-consists-of":"LEAF_PRIORITY","limit":0,"limitText":(count) => _vm.$t('g-select.multiple_selected', {count}),"noResultsText":_vm.$t('g-select.no_results')},model:{value:(_vm.data[_vm.name]),callback:function ($$v) {_vm.$set(_vm.data, _vm.name, $$v)},expression:"data[name]"}},'tree-select',_vm.$attrs,false)):_vm._e()],1),_vm._v(" "),(!_vm.hasError && !!_vm.icon)?_c('i',{staticClass:"icon is-right",class:[_vm.size]},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "),(_vm.hasError)?_c('i',{staticClass:"icon is-right is-danger",class:[_vm.size]},[_vm._v("error")]):_vm._e()]),_vm._v(" "),(_vm.hasError)?_c('div',{staticClass:"hover-area",on:{"mouseover":_vm.showErrorMessage,"mouseleave":_vm.hideErrorMessage}},[_c('div',{staticClass:"message-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessageVisible),expression:"errorMessageVisible"}],staticClass:"error-message"},[_vm._v("\n                    "+_vm._s(_vm.errorMessage)+"\n                ")])])]):_vm._e(),_vm._v(" "),(_vm.labelAnimation)?_c('label',{class:['label', { 'active': _vm.isSelectActive || _vm.isInputActive }],attrs:{"for":_vm.id}},[_vm._v("\n            "+_vm._s(_vm.labelText)+"\n        ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }