<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" :message="this.$t('notice.saved')">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.ledger_accounts.code') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="code" :label="this.$t('admin.ledger_accounts.code')"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>
                                    {{ this.$t('admin.ledger_accounts.name') }}
                                </label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" :label="this.$t('admin.ledger_accounts.name')"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ this.$t('submit_dialog.cancel') }}</a>
                            <g-button>{{ this.$t('submit_dialog.submit') }}</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        id: String
    },
    data() {
        const object = this.$store.getters['adminLedgerAccounts/getById'](this.id)
        const formData = object ? object : {
            'code': null,
            'name': null,
        }

        return {
            object: object,
            form: new Form(formData),
        }
    },
    computed: {
        isCreateNewForm() {
            return this.object == null
        },
        formTitle() {
            return this.isCreateNewForm ? this.$t('admin.ledger_accounts.create.title') : this.$t('admin.ledger_accounts.edit.title')
        },
        formEndpoint() {
            let endpoint = `/api/admin/ledger_account`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    methods: {
        formSuccess(response) {
            const action = this.isCreateNewForm ? 'adminLedgerAccounts/add' : 'adminLedgerAccounts/update'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
        formatData(form) {
            return form
        },
    },
}
</script>
