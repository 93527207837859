<template>

	<div class="field">
		<label class="b-checkbox checkbox"
			   :class="{ 'is-disabled': disabled }"
			   ref="label"
			   :disabled="disabled"
			   :tabindex="disabled ? false : 0"
			   @keydown.prevent.enter.space="select()">
			<input type="checkbox"
				   :true-value="1"
				   :false-value="0"
				   v-model="data[name]"
				   :disabled="disabled"
				   v-bind="$attrs" />
			<span class="check"></span>
			<span class="control-label">{{ labelText }}</span>
		</label>
		<p class="help is-danger" v-if="hasError">{{ errorMessage }}</p>
	</div>

</template>

<script>

    import InputHelper from '../../utils/InputHelper'

    export default InputHelper.extend({
		methods: {
		    select() {
                this.$refs.label.click()
			}
		}
	})

</script>
