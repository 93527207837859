<template>
	<div :class="['cropper', { 'aspect-ratio-wrapper aspect-ratio-background': !simple }]">

		<transition name="fade">
			<div class="cropper-options" v-show="!isCropping">

				<div class="control">
					<button type="button" class="button" @click="destroy">
						<i class="icon" v-text="simple ? 'delete' : 'close'"></i>
					</button>
				</div>

				<div class="control">
					<button type="button" class="button" @click="clickZoom(0.1)">
                        <i class="icon">zoom_in</i>
					</button>
				</div>

				<div class="control">
					<button type="button" class="button" @click="clickZoom(-0.1)">
                        <i class="icon">zoom_out</i>
					</button>
				</div>

				<div class="control">
					<button type="button" class="button" @click="reset()">
                        <i class="icon">aspect_ratio</i>
					</button>
				</div>
			</div>
		</transition>

		<div :class="['image-preview-container', { 'simple': simple, 'aspect-ratio-content': !simple, 'simple-initialised': isSimpleInitialised }]">
			<img ref="preview" class="image-preview"/>
		</div>
	</div>
</template>

<script>
    import Cropper from 'cropperjs'
    import forEach from 'lodash/forEach'

    /**
     * Cropper event chain:
     * 1. Image gets loaded in the `this.load(URL)` method
     * 2. After the image is loaded the `this.initialise()` method will be called
     */

    export default {
        props:   {
            validate: {
                default: image => true,
                type:    Function,
            },
            ratio:    {
                required: true,
                type:     Number,
            },
            simple:   {
                default: false,
                type:    Boolean
            },
            options:  {
                default () { return {fillColor: '#fff', width: 445, height: 200} },
                type: Object,
            },
        },
        data () {
            return {
                cropper:           null,
                isCropping:        false,
                currentCanvasData: null,

                /**
                 * @deprecated
                 * @type {boolean}
                 */
                isSimpleInitialised: false,
            }
        },
        watch:   {
            zoom (newValue) {
                this.cropper.zoomTo(newValue / 100)
            },

            ratio () {
                this.initialise()
            },

        },
        methods: {

            /**
             * Should cropper load the image
             * @param image
             * @returns {boolean}
             */
            shouldLoad (image) {
                return !!image && this.validate(image)
            },

            /**
             * Load image in element
             * @param imageUrl
             */
            load (imageUrl) {
                return new Promise(resolve => {
                    const image  = new Image()
                    image.onload = () => {
                        if ('undefined' === typeof this.$refs.preview) {
                            return
                        }
                        this.$refs.preview.src = imageUrl
                        this.initialise().then(() => {
                            resolve()
                        })
                    }

                    image.src = imageUrl
                })
            },

            /**
             * Emit the cropper event
             * @param event
             */
            emitCropperEvent (event) {
                this.$refs.preview.style.opacity = 1
                const canvas                     = this.getCanvas()
                const canvasData                 = this.cropper.getCanvasData()
                const isSmallCropperField        = canvasData.height === 100 && canvasData.naturalHeight !== 100

                if (canvas === null || isSmallCropperField) {
                    this.reset()
                    return false
                }

                this.$emit('crop', canvas, event)

                this.currentCanvasData = canvasData

                return true
            },

            /**
             * Initialise the cropper with all settings
             * also removes the old cropper if there is one present
             * @returns {Promise}
             */
            initialise () {
                return new Promise(resolve => {
                    this.isSimpleInitialised = false

                    // Set the image
                    const IMAGE         = this.$refs.preview
                    IMAGE.style.opacity = 0

                    // Rmeove old cropper
                    if (this.cropper !== null) {
                        this.destroy(false)
                    }

                    // Check if cropper should load
                    if (!this.shouldLoad(IMAGE)) {
                        return Promise.reject()
                    }

                    // Set Cropper data
                    this.cropper = new Cropper(IMAGE, {
                        cropstart: event => {
                            this.isCropping = true
                        },
                        cropend:   event => {
                            this.isCropping = false
                            this.emitCropperEvent(event)
                        },
                        ready:     event => {
                            this.isReady(event).then(() => {
                                this.emitCropperEvent(event)

                                if (this.simple) {
                                    this.isSimpleInitialised = true
                                }
                                resolve()
                            })
                        },
                        zoom:      this.emitCropperEvent,

                        // VM1 if not simple, VM0 if simple
                        // VM1 rejects moving outside the cropbox
                        // VM0 is like sandbox mode
                        viewMode: !this.simple ? 1 : 0,

                        // Dummy proof settings
                        zoomOnWheel:              false,
                        cropBoxMovable:           false,
                        cropBoxResizable:         false,
                        toggleDragModeOnDblclick: false,
                        responsive:               true,

                        // Turn off cropbox if simple
                        highlight:  !this.simple,
                        guides:     !this.simple,
                        center:     !this.simple,
                        background: !this.simple,
                        modal:      !this.simple,

                        // Default stuff
                        aspectRatio:  this.ratio,
                        dragMode:     'move',
                        autoCropArea: 0.1,
                        data:         {
                            'x':      0,
                            'y':      0,
                            'width':  4000,
                            'height': 4000,
                        },
                    })

                    this.$emit('input', this.file)

                })
            },

            /**
             * Destroy cropper and sets all objects to null
             */
            destroy (emit = true) {

                if (emit) {
                    this.$emit('destroy')
                }
                if (typeof this.cropper.destroy === 'function') {
                    this.cropper.destroy()
                }
                this.cropper           = null
                this.currentCanvasData = null
            },

            /**
             * Zoom using the control buttons in the cropper
             * @param value
             */
            clickZoom (value) {
                this.cropper.zoom(value)
                this.emitCropperEvent()
            },

            /**
             * Reset cropper alias
             */
            reset () {
                return this.initialise()
            },

            /**
             * Exports canvas from cropper with optional settings
             * @param {Object}options optional - Canvas options
             * @returns {HTMLCanvasElement}
             */
            getCanvas (options = this.options) {
                return this.cropper.getCroppedCanvas(options)
            },

            correctCropBox () {
                const cropbox       = this.cropper.getCropBoxData()
                const correctValues = {width: 330, height: 165}

                let isNormal = true

                forEach(correctValues, (value, key) => {
                    if (cropbox[key] > value) {
                        isNormal = false
                        return false
                    }
                })

                return isNormal
            },

            /**
             * All steps to calculate the cropbox size if the cropper is not simplefied
             */
            calculateCropBox () {
                // Step 1: Save container data and the desired cropbox values
                const containerData = this.cropper.getContainerData()
                const perfectRatio  = {
                    top:    0,
                    height: containerData.height
                }

                // Set canvas data to fill the entire container*
                // * VM1 is in effect, cropbox cannot be bigger than the canvas so the canvas needs to span the entire container to manipulate the cropbox
                this.cropper.setCanvasData({left: 0, width: containerData.width})

                // Fill image in entire height if the image is to wide
                if (this.cropper.getCanvasData().top > 0) {
                    this.cropper.setCanvasData({top: 0, height: containerData.height})
                }

                // Set the perfect ratio
                this.cropper.setCropBoxData(perfectRatio)

                // Position the cropbox in the center
                const cropBoxData = this.cropper.getCropBoxData()
                perfectRatio.left = (containerData.width / 2) - (cropBoxData.width / 2)
                this.cropper.setCropBoxData(perfectRatio)

                // Position the image inside the cropbox as much as possible
                this.cropper.setCanvasData({left: perfectRatio.left, width: cropBoxData.width})

                // Center the image
                const canvasData = this.cropper.getCanvasData()
                this.cropper.setCanvasData({
                    top:  (containerData.height / 2) - (canvasData.height / 2),
                    left: (containerData.width / 2) - (canvasData.width / 2)
                })
            },

            /**
             * On ready event
             * @param event
             * @returns {Promise}
             */
            isReady (event) {

                return new Promise(resolve => {

                    this.cropper = event.target.cropper

                    this.$nextTick(() => {

                        // Calculate the cropbox if the cropper is not the simple version
                        if (!this.simple) {
                            this.calculateCropBox()
                        } else {
                            let containerData = this.cropper.getContainerData()
                            this.cropper.setCropBoxData(containerData)
                        }

                        // Set stored canvas data if there is any
                        if (this.currentCanvasData !== null) {
                            this.cropper.setCanvasData(this.currentCanvasData)
                        }

                        resolve()
                    })
                })
            }
        },
    }
</script>
