import Vue from 'vue'
import router from '../../utils/Router'

import Informational from './Templates/Informational'
import LocationSettings from './Templates/CompanyLocationsSettings'
import InformationalCodeSnippet from './Templates/InformationalCodeSnippet'
import Email from './Templates/Email'
import OrderForm from './Templates/OrderForm'
import EntityForm from './Templates/EntityForm'
import Giftcard from './Templates/Giftcard'
import GiftcardBlock from './Templates/GiftcardBlock'
import GiftcardTopUp from './Templates/GiftcardTopUp'
import GiftcardCodeRotate from './Templates/GiftcardCodeRotate'
import store from '../../store'
import i18n from '../../utils/i18n'
import PartnerForm from './Templates/PartnerForm'
import InventoryManagementForm from './Templates/InventoryManagementForm'
import CampaignForm from './Templates/CampaignForm'
import AffiliateForm from './Templates/AffiliateForm'
import CouponForm from './Templates/CouponForm'
import PromotionalCreateForm from './Templates/PromotionalCreateForm'
import PromotionalDeliveryForm from './Templates/PromotionalDeliveryForm'
import PromotionalDeliveryLogForm from './Templates/PromotionalDeliveryLogForm'
import PromotionalDeliveryLogResendForm from './Templates/PromotionalDeliveryLogResendForm'
import Confirmation from './Templates/Confirmation'
import InvoiceRuleForm from "./Templates/InvoiceRuleForm";
import Agreement from "./Templates/Agreement";
import DeleteCompanyLocation from "./Templates/DeleteCompanyLocation";
import ExportSEPABatch from './Templates/ExportBatch'
import TokenHistoryItem from './Templates/TokenHistoryItem'
import OrderRefundForm from "./Templates/OrderRefundForm";
import TransactionReverseForm from "./Templates/TransactionReverseForm";
import TransactionCancelForm from "./Templates/TransactionCancelForm";
import LedgerAccountForm from "./Templates/LedgerAccountForm";
import InvoicePresetForm from "./Templates/InvoicePresetForm";
import FraudFilterForm from "./Templates/FraudFilterForm";
import UserForm from "./Templates/UserForm";
import AgreementSEPADebit from "./Templates/AgreementSEPADebit";
import DigitalOrderCreate from "./Templates/DigitalOrderCreate.vue";
import DigitalOrderDeliverySelection from "./Templates/DigitalOrderDeliverySelection.vue";
import DigitalOrderEmail from "./Templates/DigitalOrders/DigitalOrderEmail.vue";
import WrappingBrowser from "./Templates/WrappingBrowser";
import Base from "./Base.vue";

const Templates = {
    'Informational': Informational,
    'InformationalCodeSnippet': InformationalCodeSnippet,
    'Agreement': Agreement,
    'AgreementSEPADebit': AgreementSEPADebit,
    'DeleteCompanyLocation': DeleteCompanyLocation,
    'Confirmation': Confirmation,
    'LocationSettings': LocationSettings,
    'Email': Email,
    'OrderForm': OrderForm,
    'OrderRefundForm': OrderRefundForm,
    'TransactionReverseForm': TransactionReverseForm,
    'TransactionCancelForm': TransactionCancelForm,
    'InvoiceRuleForm': InvoiceRuleForm,
    'InvoiceCreateForm': require('./Templates/InvoiceCreateForm').default,
    'EntityForm': EntityForm,
    'Giftcard': Giftcard,
    'GiftcardBlock': GiftcardBlock,
    'GiftcardTopUp': GiftcardTopUp,
    'GiftcardCodeRotate': GiftcardCodeRotate,
    'PartnerForm': PartnerForm,
    'InventoryManagementForm': InventoryManagementForm,
    'CampaignForm': CampaignForm,
    'AffiliateForm': AffiliateForm,
    'CouponForm': CouponForm,
    'PromotionalCreateForm': PromotionalCreateForm,
    'PromotionalDeliveryForm': PromotionalDeliveryForm,
    'PromotionalDeliveryLogForm': PromotionalDeliveryLogForm,
    'PromotionalDeliveryLogResendForm': PromotionalDeliveryLogResendForm,
    'ExportBatch': ExportSEPABatch,
    'SettleSettings': require('./Templates/SettleSettings').default,
    'LedgerAccountForm': LedgerAccountForm,
    'InvoicePresetForm': InvoicePresetForm,
    'TokenHistoryItem': TokenHistoryItem,
    'FraudFilterForm': FraudFilterForm,
    'UserForm': UserForm,
    'DigitalOrderCreate': DigitalOrderCreate,
    'DigitalOrderDeliverySelection': DigitalOrderDeliverySelection,
    'DigitalOrderEmail': DigitalOrderEmail,
    'WrappingBrowser': WrappingBrowser,
    'ToolActionForm': require('./Templates/ToolActionForm').default,
    'ActionCodeForm': require('./Templates/ActionCodeForm').default,
}


Vue.prototype.$popup = function (template, propsData = {}) {
    const popupBase = Vue.extend(Base);
    const popupTemplate = Templates[template];

    if (!popupTemplate) {
        throw new Error(`Template "${template}" not found`);
    }

    const popup = popupBase.extend(popupTemplate);

    let popupBody = new popup({
        el: document.createElement('div'),
        store,
        propsData,
        i18n,
        router
    });

    // Append the popup to the body
    document.body.appendChild(popupBody.$el);

    // On emit destroy, remove the popup from the DOM
    popupBody.$on('destroy', () => {
        popupBody.$destroy();
        popupBody.$el.remove();
    });

    return popupBody;
}
