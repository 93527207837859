<template>
	<div :class="['field', { 'error': hasError, 'animation': labelAnimation }]">
        <label class="label"
               :for="id"
               v-if="!labelAnimation && label">
            {{ labelText }}
        </label>

        <div :class="['control is-expanded', gClass, { 'has-icons-right': hasError || !!icon }]">
            <input v-model="valueModel"
                   v-bind="$attrs"
                   type="text"
                   :id="randomID"
                   autocorrect="off"
                   spellcheck="false"
                   :disabled="disabled"
                   :placeholder="placeholder"
                   @focus="initialise"
                   @blur="onInputBlur"
                   @input="beforePress"
                   @keydown="beforePress"
                   @keyup="afterPress"
                   @paste="pasteContent"
                   @cut="emitRawValue"
                   :class="['input', size, { 'is-danger': hasError }]">

            <i class="icon" :class="['is-right', size]" v-if="!hasError && !!icon">{{ icon }}</i>
            <i class="icon" :class="['is-right is-danger is-normal']" v-if="hasError">error</i>
        </div>

        <div class="hover-area" v-if="hasError" @mouseover="showErrorMessage" @mouseleave="hideErrorMessage">
            <div class="message-box">
                <div class="error-message" v-show="errorMessageVisible">{{ errorMessage }}</div>
            </div>
        </div>

        <label :class="['label', { 'active': isInputActive || hasPrefix || hasSuffix }]"
               :for="randomID"
               v-if="labelAnimation">
            {{ labelText }}
        </label>
	</div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'
import forEach from 'lodash/forEach'
import contains from 'lodash/includes'

export const FORMATS = {
    currency: {
        regex: /^(\d{1,}|\d{1,}[.,]|\d{1,}[.,]\d{1,2})$/,
        options: ['converters', 'prefix'],
        converters: {
            '.': ','
        },
        prefix: '\u20AC ',
    },
    percentage: {
        // Positive or negative percentage with max 2 decimals
        regex: /^(-{1}|-{0,1}\d{1,}|-{0,1}\d{1,}[.,]|-{0,1}\d{1,}[.,]\d{1,2})$/,
        options: ['converters', 'suffix'],
        converters: {
            '.': ','
        },
        suffix: ' %',
    },
    positiveOrNegativeCurrency: {
        regex: /^(-{1}|-{0,1}\d{1,}|-{0,1}\d{1,}[.,]|-{0,1}\d{1,}[.,]\d{1,2})$/,
        options: ['converters', 'prefix'],
        converters: {
            '.': ','
        },
        prefix: '\u20AC ',
    },
    postcode: {
        regex: /^(\d{1,4}(\s?)|\d{4}(\s?)[A-Za-z]{1,2})$/,
        options: ['uppercase', 'delimiter'],
        delimiter: {
            seperator: ' ',
            blocks: [4, 2]
        }
    },
    giftcard: {
        regex: /^(.*gifty\.[a-z]{2}\/)?([A-Z0-9]{1,20})(\/.*)?$/i,
        options: ['delimiter', 'uppercase'],
        delimiter: {
            seperator: '-',
            blocks: [4, 4, 4, 4]
        }
    },
    vat: {
        regex: /^([A-Za-z0-9]{1,16})$/,
        options: ['uppercase'],
    },
    date: {
        regex: /^(\d{0,8})$/,
        options: ['delimiter'],
        delimiter: {
            seperator: '-',
            blocks: [2, 2, 4],
        },
    },
    iban_nl: {
        regex: /^([a-zA-Z]{0,2}|[a-zA-Z]{2}\d{0,2}|[a-zA-Z]{2}\d{2}[a-zA-Z]{0,4}|[a-zA-Z]{2}\d{2}[a-zA-Z]{4}\d{0,10})$/,
        options: ['delimiter', 'uppercase'],
        delimiter: {
            seperator: ' ',
            blocks: [4, 4, 4, 4, 2],
        },
    },
};

export default InputHelper.extend({
        data() {
            return {
                key: '',
                inputArray: [],
                caretStart: 0,
                caretRange: 0,
                caretPosition: 0,
                rawValue: '',
                holdControl: false,
                formatter: {},
                options: [],
                randomID: `g-format-${Math.random().toString(36).substr(2, 9)}`,
                isAndroid: false,
                target: null,
                oldValue: '',
                valueModel: '',
            }
        },
        created() {
            this.initialise()
        },

        /**
         * The following computed property and watchers are used to have a
         * seperate v-model for the  formatting.
         *k
         * All changes will be sent te the Form object in it's raw form
         * All changes made to the Form object will be automatically formatted
         * before putting it in the input
         */
        computed: {
            watchableValue() {
                return (this.inputValue || '').toString()
			},
			hasPrefix() {
                return this.options.length > 0 && contains(this.options, 'prefix')
			},
			hasSuffix() {
                return this.options.length > 0 && contains(this.options, 'suffix')
			},
		},
        watch:    {
            watchableValue(value) {
                if (value !== this.getRawValue()) {
                    this.valueModel = (this.filter(value))
                }

                if (value === '' && this.valueModel !== '') {
                    this.valueModel = this.formatValue(value)
                }
            },
            valueModel(value) {
                const rawValue = this.getRawValue(value || '')
                if (this.inputValue !== rawValue) {
                    this.inputValue = rawValue
                }
            },
        },
        methods: {

            /**
             * Sets all data properties
             * @returns {void|Boolean}
             */
            initialise(event) {
                this.setRawValue(this.getValue())
                this.formatter = FORMATS[this.type]
                this.options = this.formatter.options
                this.setValue(this.formatValue(this.getRawValue()))

                // Detect android chrome to give alternative solution
                const userAgent = navigator.userAgent.toLowerCase()
                const isChrome = !!window.chrome
                this.isAndroid = userAgent.indexOf('android') > -1 && isChrome //&& ua.indexOf("mobile");
            },

            /**
             * Checks if the key pressed is a system key. This is always allowed and wont prevent default.
             * @param {String}key
             * @returns {boolean}
             */
            alwaysAllowed(key, event) {
                if (key === 'Control' || this.appleCommandKey(key, event)) {
                    this.holdControl = true
                }

                // Second if is because if someone presses Ctrl this function will be executed and sets the value
                // holdControl to true
                // But if he presses V (Ctrl+V) this will execute this method a second time without it being the Ctrl
                // button, holdControl has already been set the previous time so it allowes every button
                if (this.holdControl) {
                    return true
                }

                return !key.match(/^.{1}$/)
            },

            /**
             * Check to see if user is holding the apple command key
             * @link https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769
             * @link https://stackoverflow.com/questions/3902635/how-does-one-capture-a-macs-command-key-via-javascript
             */
            appleCommandKey(key, event) {
                let appleCommandKey = false
                if (event) {
                    const
                        // Firefox 1.0+
                        isFirefox = typeof InstallTrigger !== 'undefined',
                        // Opera 8.0+
                        isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
                        // Chrome 1+
                        isChrome = !!window.chrome && !!window.chrome.webstore,
                        // Safari 3.0+ "[object HTMLElementConstructor]"
                        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                            return p.toString() === '[object SafariRemoteNotification]'
                        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))

                    if (isFirefox && event.keyCode === 224) {
                        appleCommandKey = true
                    }
                    if (isOpera && event.keyCode === 17) {
                        appleCommandKey = true
                    }

                    // Webkit browsers detecteren linker en rechter command onder twee verschillende keycodes
                    appleCommandKey = (isChrome || isSafari) && (event.keyCode === 91 || event.keyCode === 93)
                }
                return appleCommandKey || key === 'Meta'
            },

            /**
             * Sets all the local used variables corresponding with the event; where the caret is, which key is pressed and what the current values are
             * @param {KeyboardEvent}event
             */
            setEventValues(event) {
                this.key = event.key
                this.caretStart = event.target.selectionStart
                this.caretRange = event.target.selectionEnd - this.caretStart

                // Fetch pressed key from Chromes input event
                if (event.type === 'input' && this.isAndroid) {
                    // Detect if Delete or Backspace
                    this.key = event.inputType === 'deleteContentBackward' ? 'Backspace' : 'Delete'

                    // Client pressed valid character on keyboard if `event.data` is not `null`
                    if (event.data) {

                        // data is the pressed key if it's length equals 1 else it will fetch the pressed key from the data
                        this.key = event.data.length > 1 ? event.data.substr(this.caretStart - 1, 1) : event.data
                    }
                }

                // Save oldValue before character is inserted in input field
                if (event.type !== 'input') {
                    this.oldValue = this.valueModel
                }

                this.caretPosition = 0
                this.target = event.target

                this.setRawValue(this.getRawValue())

                if (contains(this.options, 'prefix')) {
                    if (this.getValue().length <= 0) {
                        this.setValue(this.formatValue(''))
                        return this.setEventValues(event)
                    }

                    if (this.caretStart < this.formatter.prefix.length) {
                        this.caretStart = this.formatter.prefix.length
                    }
                }

                if (contains(this.options, 'suffix')) {
                    if (this.getValue().length <= 0) {
                        this.setValue(this.formatValue(''))
                        return this.setEventValues(event)
                    }

                    if (this.caretStart >= this.getValue().length - this.formatter.suffix.length) {
                        console.log('Change caret position to end in setEventValues')
                        this.caretStart = this.getValue().length - this.formatter.suffix.length
                    }
                }
            },

            /**
             * Inserts the key in a string on position x or replaces position x
             * @param {String}value
             * @param {String}key
             * @param {Number}selectionStart
             * @param {Number}selectionRange
             * @returns {String}
             */
            insertKeyOnCaret(value, key, selectionStart, selectionRange) {
                const arrayValue = value.split('')

                if (contains(this.options, 'delimiter')) {
                    const seperator = this.formatter.delimiter.seperator
                    if (value.substr(selectionStart, seperator.length) === seperator) {
                        this.caretPosition += seperator.length
                    }
                }
                if (!!selectionRange) {
                    this.caretPosition += key.length
                }

                arrayValue.splice(selectionStart, selectionRange, key)

                return arrayValue.join('')
            },

            /**
             * Deletes parts from strings and reformats it.
             * @param {String}value
             * @param {String}key
             * @param {Number}selectionStart
             * @param {Number}selectionRange
             * @returns {String}
             */
            deleteFromRawValue(value, key, selectionStart, selectionRange) {
                const arrayValue = value.split('')

                // Default removes first character after caret
                let start = selectionStart
                let count = 1

                // Backspace removes character before caret
                if (key === 'Backspace' && !selectionRange) {
                    start--

                    if (selectionStart === 0) {
                        return value
                    }
                    if (selectionStart === 1) {
                        this.caretPosition -= 1
                    }
                }

                // Delete selection
                if (!!selectionRange) {
                    count = selectionRange
                }

                // Delete around delimiters
                if (contains(this.options, 'delimiter')) {
                    let part = arrayValue[start]
                    const seperator = this.formatter.delimiter.seperator

                    if (key === 'Backspace' &&
                        arrayValue[start + 1] === seperator &&
                        typeof arrayValue[start + 1] !== 'undefined' &&
                        value.substr(value.length - seperator.length) === seperator) {

                        this.caretPosition += 1
                    }

                    if (part === seperator[0]) {
                        if (key === 'Backspace') {
                            start -= seperator.length
                            count += seperator.length

                            if (value.substr(value.length - seperator.length) !== seperator) {
                                this.caretPosition -= 1
                            }
                        }
                        if (key === 'Delete') {
                            count += seperator.length
                        }
                    }
                }

                if (contains(this.options, 'prefix')) {

                    /**
                     * if there is no selection range
                     * &&
                     * if position of caret is lower than the length of the prefix
                     * or if key pressed is backspace and position of caret is exactly after the prefix
                     */
                    if ((selectionStart < this.formatter.prefix.length ||
                        (key === 'Backspace' && selectionStart === this.formatter.prefix.length))
                        && !selectionRange) {
                        return value
                    }
                }

                if (contains(this.options, 'suffix')) {

                    /**
                     * if there is no selection range
                     * &&
                     * if position of caret is greater than the length of the value minus the suffix
                     * or if key pressed is backspace and position of caret is after the suffix start
                     */
                    if ((selectionStart > value.length - this.formatter.suffix.length) && !selectionRange) {
                        console.log('prevention from suffix in deleteFromRawValue')
                        return value
                    }
                }

                // Execute settings
                arrayValue.splice(start, count)

                this.setRawValue(this.getRawValue(arrayValue.join('')))
                return this.formatValue(this.getRawValue(true))
            },

            /**
             * Formats given string with the given options
             * @param {String}value
             * @returns {String}value
             */
            formatValue(value) {

                if (typeof this.options === 'undefined' || typeof value !== 'string') {
                    return value
                }

                if (contains(this.options, 'uppercase')) {
                    value = value.toUpperCase()
                }

                if (contains(this.options, 'lowercase')) {
                    value = value.toLowerCase()
                }

                if (contains(this.options, 'converters')) {
                    const converters = this.formatter.converters

                    for (let find in converters) {
                        if (!converters.hasOwnProperty(find)) continue
                        let replace = converters[find]

                        value = value.replace(new RegExp('\\' + find, 'g'), replace)
                    }
                }

                if (contains(this.options, 'delimiter')) {
                    const delimiter = this.formatter.delimiter
                    let valueArray = value.split('')
                    let newValue = []
                    let currentBlock = ''

                    forEach(valueArray, (char, i) => {
                        if (currentBlock.length === delimiter.blocks[newValue.length]) {
                            currentBlock.substr(0, currentBlock.length - 1)
                            newValue.push(currentBlock)
                            currentBlock = ''
                        }

                        currentBlock += char
                        if (i === valueArray.length - 1) {
                            newValue.push(currentBlock)
                            currentBlock = ''
                            return false
                        }
                    })
                    value = newValue.join(delimiter.seperator)

                    if (newValue.length < delimiter.blocks.length && (newValue[newValue.length - 1] || []).length === delimiter.blocks[newValue.length - 1]) {
                        value += delimiter.seperator
                    }
                }

                if (contains(this.options, 'prefix')) {

                    value = this.formatter.prefix + value
                }

                if (contains(this.options, 'suffix')) {

                    value = value + this.formatter.suffix
                }

                return value
            },

            /**
             * Sets the input field to a new value
             * @param {Event}event
             * @param {String}key
             * @returns {boolean}Is the new value allowed
             */
            setFormattedValue(event, key) {
                const valid = this.insertKeyOnCaret(this.getValue(), key, this.caretStart, this.caretRange)
                const newRawValue = this.getRawValue(valid)

                if (this.formatter.regex.test(newRawValue)) {
                    this.setValue(this.formatValue(newRawValue))

                    return true
                }

                return false
            },

            /**
             * Sets the position of the caret in an element on position x
             * @returns {void}
             */
            setCaretPosition() {
                let element = this.target
                let caretPosition = this.caretStart + this.caretPosition
                this.$nextTick(() => {

                    if (element !== null) {
                        if (element.createTextRange) {
                            const range = element.createTextRange()
                            range.move('character', caretPosition)
                            range.select()
                        } else {
                            element.focus()
                            element.setSelectionRange(caretPosition, caretPosition)
                        }
                    }
                    this.caretPosition = 0
                })
            },

            /**
             * Sets the input value
             */
            setValue: function (value) {
                const addedCharacters = (value.length - this.oldValue.length)
                const deletingChars = !this.caretRange && addedCharacters < 0
                this.caretPosition += addedCharacters

                if (contains(this.options, 'delimiter')) {
                    const seperator = this.formatter.delimiter.seperator
                    const checkableValue = deletingChars ? this.getValue() : value

                    if (checkableValue.substr(-seperator.length) === seperator &&
                        this.caretStart < checkableValue.lastIndexOf(seperator) - 1) {

                        const reverser = deletingChars ? -1 : 1
                        this.caretPosition -= seperator.length * reverser
                    }
                }

                if (this.caretStart === 1 && addedCharacters === -1) {
                    this.caretStart = 0
                    this.caretPosition = 0
                }

                if (this.caretRange) {
                    this.caretPosition = this.key.length
                }

                if (this.key === 'Delete') {
                    this.caretPosition = 0
                }

                this.valueModel = value

                this.setCaretPosition()
            },

            /**
             * Sets the raw value
             */
            setRawValue(value) {
                this.rawValue = value
            },

            /**
             * @returns {String}Formatted input value
             */
            getValue() {
                return this.valueModel || ''
            },

            /**
             * Reverse all options to get the raw value
             * @param {String|Boolean|optional}value Returns this.rawValue if boolean or strips given value if String, Uses value in input field if nothing is defined
             * @returns {String}rawValue
             */
            getRawValue(value = this.getValue()) {
                let rawValue = value

                if (value === true) {
                    return this.rawValue
                }

                if (value === '') {
                    rawValue = value
                }

                if (contains(this.options, 'delimiter')) {
                    rawValue = this.stripDelimiters(rawValue, this.formatter.delimiter.seperator)
                }

                if (contains(this.options, 'prefix')) {
                    rawValue = this.stripPrefix(rawValue, this.formatter.prefix)
                }

                if (contains(this.options, 'suffix')) {
                    rawValue = this.stripSuffix(rawValue, this.formatter.suffix)
                }

                return rawValue
            },

            /**
             * Strip delimiters
             * @param {String}value
             * @param {Array}delimiter
             * @returns {String}
             */
            stripDelimiters(value, delimiter) {
                delimiter = delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

                return value.replace(new RegExp(delimiter, 'g'), '')
            },

            /**
             * Strip the prefix
             * @param {String}value
             * @param {String}prefix
             * @returns {String}
             */
            stripPrefix(value, prefix) {
                return value.replace(new RegExp(`\\${prefix}`), '')
            },

            /**
             * Strip the suffix
             * @param {String}value
             * @param {String}suffix
             * @returns {String}
             */
            stripSuffix(value, suffix) {
                return value.replace(new RegExp(`\\${suffix}`), '')
            },

            /**
             * Called before the key is submitted to the field
             * @param event
             * @returns {boolean}
             */
            beforePress(event) {
                this.setEventValues(event)

                // Cancel key insertion android
                if (this.isAndroid) {
                    return false
                }

                if (this.key === 'Delete' || this.key === 'Backspace') {
                    this.$emit('delete')
                    this.setValue(this.deleteFromRawValue(this.getValue(), this.key, this.caretStart, this.caretRange))
                    event.preventDefault()
                    return true
                }

                if (this.alwaysAllowed(this.key, event) || this.holdControl) {
                    return true
                }

                event.preventDefault()

                this.setFormattedValue(event, this.key)
                this.setRawValue(this.getRawValue())
            },

            /**
             * Called after key release
             * @param event
             */
            afterPress(event) {
                event.stopImmediatePropagation()

                // Format new value after key is released on android
                if (this.isAndroid) {
                    const value = this.getValue()
                    const rawva = this.getRawValue(value)
                    this.valueModel = this.formatValue(rawva)

                    // Check if the new value is allowed
                    if (!this.formatter.regex.test(this.getRawValue())) {

                        // Changing of the value is cancelled in Chrome for Android while the event is going
                        // Set timeout will bring it on a seperate thread which will fix the problem
                        setTimeout(() => {

                            // Reset to old value if not allowed
                            this.valueModel = this.oldValue
                        }, 1)

                        return false
                    }
                    this.setRawValue(this.getRawValue())
                }

                if (!this.getValue().length) {
                    this.setRawValue('')
                }

                if (this.holdControl && event.key === 'Control') {
                    this.holdControl = false
                }
                this.emitRawValue()
            },

            /**
             * @param {String} text
             * @returns {String}
             */
            filter(text) {
                // Test is pastedtext can be formatted without fail
                let requested = text,
                    prefix = ''

                const formatted = this.formatValue(requested)

                if (this.formatter.regex.test(formatted)) {
                    // Return fully formatted value
                    return formatted
                }

                let rawRequested = this.getRawValue(requested)
                if (this.formatter.regex.test(rawRequested)) {
                    return this.formatValue(rawRequested)
                }

                /**
                 * Test the formatted string one character at a time and return it before it fails
                 */

                    // Tested values
                const characters = (rawRequested).split('')
                let newString = '',
                    definitiveString = ''

                // Loo[ through every character
                forEach(characters, (character) => {

                    // Test the temporary string
                    newString += character
                    if (this.formatter.regex.test(newString)) {
                        // Sets returnable value if it didn't fail
                        definitiveString = newString
                        return
                    }
                    // Exit forEach because it failed
                    return false
                })

                // Return old value if pasting failed on the first new character
                if (definitiveString === prefix) {
                    return this.oldValue
                }

                // Returns the last value it didn't fail on
                return this.formatValue(definitiveString)
            },

            /**
             * On content pasted
             * @param event
             */
            pasteContent(event) {
                event.preventDefault()

                const clipboardData = event.clipboardData || window.clipboardData
                const pastedData = clipboardData.getData('Text')

                this.setEventValues(event)
                this.key = this.filter(pastedData)

                this.setValue(this.key)
                this.emitRawValue()
            },

            /**
             * Emits the raw value.
             * @tutorial "<g-format @input="returnFunction" />"
             */
            emitRawValue() {
                this.$emit('input', this.getRawValue())
            }
        }
})
</script>
