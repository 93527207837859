// api calls
import { adminCampaignsApi as api } from '../../../api/admin/Campaigns'

// state -> info about way of storing data; https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
const state = {
    objects:     {},
    objectList: []
}

// getters
const getters = {
    all:     state => state.objectList.map(id => state.objects[id]),
    getById: (state) => (id) => {
        return state.objects[id]
    },
}

// actions
const actions = {
    loadAll ({commit}) {
        return new Promise((resolve, reject) => {
            api.getAll()
                .then(({data}) => {
                    commit('set', {
                        objects: data.data
                    })
                    resolve()
                })
        })
    },
    add ({commit}, payload) {
        // The server post should be handled by g-form
        commit('add', payload)
    },
    update ({commit}, payload) {
        // The server patch should be handled by g-form
        commit('update', payload)
    },
}

// mutations
const mutations = {
    set (state, payload) {
        state.objects    = payload.objects.reduce((obj, object) => {
            obj[object.id] = object

            return obj
        }, {})
        state.objectList = payload.objects.map(object => object.id)
    },
    add (state, payload) {
        state.objects[payload.object.id] = payload.object
        state.objectList.push(payload.object.id)
    },
    update (state, payload) {
        state.objects[payload.object.id] = payload.object
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
