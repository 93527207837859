<template>
	<div class="columns is-mobile">
		<div class="column pattern-column is-one-third-mobile">
			<div class="editor-container editor-container-height with-shadow library patterns"
				 v-if="!hasImage">
				<div class="scrollable" ref="scrollable">
					<Library :library="parsedLibrary"
							 ref="library"
							 @change="changeFromLibrary"
							 :current="imageFromLibrary"/>
				</div>
			</div>
		</div>
		<div class="column is-two-third-mobile pattern-edit">
			<div class="editor-container editor-container-height with-shadow pattern-color-column">

				<div>
					<span class="label">Motief</span>
				</div>
				<div class=" color-container">
					<g-colorpicker name="pattern_primary"
								   :suggestions="palette"
								   g-class="editor-container cropped"
								   :prepopulate="primary"
								   inner/>
				</div>
				<div>
					<span class="label label-last">Achtergrond</span>
				</div>
				<div class=" color-container">
					<g-colorpicker name="pattern_secondary"
								   :suggestions="palette"
								   g-class="editor-container cropped"
								   :prepopulate="secondary"
								   inner/>
				</div>

			</div>
		</div>
	</div>

</template>

<script>
    import BackgroundMixins from '../../../mixins/BackgroundMixins'
    import merge from 'lodash/merge'
	import { waitFor } from '../../../utils/Consts'

    export default {
        mixins:   [BackgroundMixins],
        props:    {
            palette:   {
                required: true,
                type:     Array,
            },
            primary:   {
                required: true,
                type:     String
            },
            secondary: {
                required: true,
                type:     String
            },
        },
        data () {
            return {
                rawPattern:      '',
                newLibrary:      [],
                requestedImages: {},
            }
        },
        computed: {
            parsedLibrary () {
                return this.newLibrary.length ? this.newLibrary : this.library
            }
        },
        watch:    {
            rawPattern (value) {

                if (typeof value !== 'string') {
                    return false
                }

                this.form.background_pattern_image = URL.createObjectURL(this.parseBlob(value))
            },

            primary (color) {
                this.$emit('color-change', color)
                this.rawPattern = this.updatePrimaryColor(this.rawPattern, color)
                this.updateThumbnails(color)
            },

            secondary (color) {
                this.$emit('color-change', color)

                this.rawPattern = this.updateSecondaryColor(this.rawPattern, color)
                this.updateThumbnails(color, false)

            },

            library (value) {
                this.updateThumbnails(value)
            },
        },
        methods:  {

            /**
             * Request image from server as text
             * @param url
             * @param main
             * @returns {Promise|Bluebird|Promise<T>}
             */
            requestImage (url, main = true) {
                return new Promise(async (resolve) => {

					const fetchedText = await axios.get(url + '?nocache=' + moment().format('X'), {responseType: 'text'})
                    const text = this.updateColors(fetchedText.data)

                    if (main) {
                        this.rawPattern = text
                    }

                    resolve(text)
                })
            },

            /**
             * Replace color CSS to the provided color
             * @param {String}raw
             * @param {String}color
             * @returns {String}
             */
            updatePrimaryColor (raw = this.rawPattern, color = this.primary || '#000') {

                return raw.replace(/\.st0{fill:.*;}/, `.st0{fill:${color};}`)
            },

            /**
             * Sets background style to the secondary color
             * @param {String}raw
             * @param {String}color
             * @return {String}
             */
            updateSecondaryColor (raw = this.rawPattern, color = this.secondary || '#fff') {

                if (raw.indexOf('<svg') === -1) {
                    return raw
                }

                // Creates a standard element
                const element               = document.createElement('div')
                // Inserts the raw SVG text inside the element
                element.innerHTML           = raw
                // Select the SVG
                const svgElement            = element.querySelector('svg')
                // Set the background of the SVG
                svgElement.style.background = color

                // Fetch and return the inside of the div element as text
                return element.innerHTML
            },

            /**
             * Update both colors
             * @param {String}text
             * @return {String}
             */
            updateColors (text) {
                text = this.updatePrimaryColor(text)
                text = this.updateSecondaryColor(text)

                return text
            },

            /**
             * Converts text to a Blob
             * @param {String}text
             * @param {String}contentType
             * @return {Blob}
             */
            parseBlob (text, contentType = 'image/svg+xml') {

                /** TODO find a better fix
                 * Firefox will not load the SVG image in canvas unless it has the width and height attribute present
                 * The other browsers cannot have these attributes because it will mess with the ratio of the SVG image
                 *
                 * @link https://stackoverflow.com/questions/46910177/drawing-image-on-canvas-not-working-on-firefox
                 * src="...target.svg" this image is an svg image. Open it in a text editor and you will find svg markup (xml like). Make sure that the first <svg> tag has width and height attributes set and that they aren't set to relative units (like %). Then if you still face the issue, edit your question with the svg markup. – Kaiido
                 */
                if (contentType === 'image/svg+xml' && typeof InstallTrigger !== 'undefined') {
                    text = text.replace('<svg ', '<svg width="1200px" height="600px" ')
                }

                return new Blob([text], {type: contentType})
            },

            /**
             * Update the pattern thumbnails to contain the corresponding colors
             * @param {String}color
             * @param {boolean}primary
             * @return {boolean}
             */
            async updateThumbnails (color, primary = true) {

                /** TODO find a better fix
                 * Colored thumbnails where not showing in IE and Edge
                 * This function will be terminated for those browser so it will have the default link to the server instead of an altered Blob url
                 *
                 * 1. `document.documentMode` - Is a variable only present in IE
                 * 2. `/Edge/.test(navigator.userAgent)` - User agent testing
                 */
                const isMicrosoft = document.documentMode || /Edge/.test(navigator.userAgent)
                const isSafari    = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
                const isiOS       = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && !!navigator.platform
                if (isMicrosoft || isSafari || isiOS) {
                    return false
                }

                const newLibrary = []
                const urlReader  = (URL || webkitURL)
                const library    = color instanceof Array ? color : this.library

                for (const thumb of library) {

                    const libraryRef = await waitFor(this.$refs.library)

                    let raw = !!thumb.url ? thumb.raw : await this.requestImage(libraryRef.imageUrl(thumb), false)

                    if (!!thumb.url) {
                        urlReader.revokeObjectURL(thumb.url)
                    }

                    if (color instanceof Array) {
                        raw = this.updateColors(raw)
                    } else if (primary) {
                        raw = this.updatePrimaryColor(raw, color)
                    } else {
                        raw = this.updateSecondaryColor(raw, color)
                    }

                    let blob  = this.parseBlob(raw)
                    let items = merge(thumb, {url: urlReader.createObjectURL(blob), raw})

                    newLibrary.push(items)
                }

                this.$set(this, 'newLibrary', newLibrary)
            }
        }
    }
</script>
