<template>
    <div id="application">
        <client-update-available v-if="this.$store.getters['application/updateAvailable']"/>
        <impersonated-notification v-if="this.$store.getters['user/isImpersonated']"/>
        <main-menu v-if="this.$store.getters['user/isAuthenticated']"/>

        <page-header v-if="headerInfo" :header-info="headerInfo" :background="background">
            <div slot="header-custom">
                <slot name="header-custom"></slot>
            </div>
        </page-header>

        <slot name="content" v-if="contentVisible"></slot>
    </div>
</template>

<script>
import MainMenu from './MainMenu'
import ImpersonatedNotification from '../auth/ImpersonatedNotification'
import PageHeader from './PageHeader'
import ClientUpdateAvailable from "../ClientUpdateAvailable";

export default {
    components: {
        ClientUpdateAvailable,
        ImpersonatedNotification,
        MainMenu,
        PageHeader,
    },

    props: {
        headerInfo: {
            type: Object,
            default: null,
        },
        background: {
            type: Boolean,
            default: true,
        }
    },

    data: function () {
        return {
            contentVisible: true
        }
    },

    computed: {
        activeCompany() {
            return this.$store.getters["user/activeCompanyId"]
        }
    },

    watch: {
        activeCompany() {
            this.contentVisible = false;

            setTimeout(() => {
                this.contentVisible = true;
            }, 5);
        }
    },
}
</script>

<style>
#application {
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>
