// Simple VueX store that stores fonts that area already loaded in the application by name

const state = {
    loadedFonts: [],
}

// getters
const getters = {
    loadedFonts: state => state.loadedFonts,
}

// actions
const actions = {
    addLoadedFont ({commit}, payload) {
        commit('addLoadedFont', payload)
    },
}

// mutations
const mutations = {
    addLoadedFont (state, payload) {
        state.loadedFonts.push(payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
