<template>
	<div :class="{ 'error': hasError, 'field': typeof $attrs['no-field'] === 'undefined' }">
		<div :class="['control is-expanded', gClass, { 'has-icons-right': hasError }]">
			<input :id="id"
				   type="checkbox"
				   v-model="value"
				   :class="['switch', type]"
				   v-bind="$attrs"
				   :disabled="isSubmitting || disabled"/>
			<div class="switch-container" @click="toggle"></div>
			<label class="label" v-if="label" @click="toggle">{{ labelText }}</label>
			<small class="help cursor-pointer" v-if="subtitle" @click="toggle">{{ subtitle }}</small>
            <i class="icon is-right is-danger is-normal" v-if="hasError">error</i>
		</div>

		<div class="hover-area" v-if="hasError" @mouseover="showErrorMessage" @mouseleave="hideErrorMessage">
			<div class="message-box">
				<div class="error-message" v-show="errorMessageVisible">{{ errorMessage }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'

export default InputHelper.extend({
        props: {
            type:     {
                default: 'is-success',
            },
            subtitle: {
                default: false,
                type:    [Boolean, String],
            },
        },

        data () {
            return {
                value:        false,
                isSubmitting: false,
            }
        },

        watch: {
            value (val) {
                this.data[this.name] = val ? 1 : 0
            },
        },

        mounted () {
            // Initial setting the switches value + the watcher to keep the value a boolean
            this.value = !!this.data[this.name]
            this.$watch(`data.${this.name}`, (val) => {
                this.value = !!val
            })
        },

        methods: {
            toggle (event) {
                if (!this.disabled) {
                    this.value = !this.value

                    this.$emit('input', event, this.value)

                    this.submit()
                }
            },

            async submit () {
                this.$nextTick(async () => {
                    if (typeof this.$attrs.submit !== 'undefined' && this.form) {
                        this.isSubmitting = true
                        await this.$parent.submit()
                        this.isSubmitting = false
                    }
                })
            },
        }
    })
</script>
