<template>
    <div>
        <b-table
            class="gifty-table"
            :class="{ 'drag-drop-table' : dragDrop }"

            :loading="loading"
            :data="sortedData"

            mobile-cards

            backend-sorting
            :default-sort="[sortColumn, sortDirection]"
            @sort="onSort"

            :selected="selectedRow"

            backend-pagination
            :total="meta.total"
            :per-page="perPage"
            :paginated="(links.next !== null || links.prev !== null) && meta.total !== 0"
            @page-change="getPageData"

            @click="onRowClick"

            v-bind="$attrs"
            v-sortable="sortableOptions">

            <b-table-column v-if="dragDrop" cell-class="drag-drop-column" v-slot="props">
                <i class="icon">drag_indicator</i>
                <b-icon icon="menu"></b-icon>
            </b-table-column>

            <slot></slot>

            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered loading-state">
                        <div v-if="!noConnection && loading">
                            <p>
                                <i class="icon is-large">search</i>
                            </p>
                            <p>{{ $t('g-table.is_loading') }}</p>
                        </div>
                        <div v-if="!noConnection && !loading">
                            <p>
                                <i class="icon is-large">search</i>
                            </p>
                            <p>{{ $t('g-table.no_data') }}</p>
                        </div>
                        <div v-if="noConnection">
                            <p>
                                <i class="icon is-large">wifi_off</i>
                            </p>
                            <p>{{ $t('g-table.no_connection') }}</p>
                        </div>
                    </div>
                </section>
            </template>
        </b-table>

        <div class="top level" v-if="(links.next || links.prev) && meta.total === 0 && initialised">
            <div class="level-left"></div>
            <div class="level-right">
                <div class="level-item">
                    <nav>
                        <button
                            :disabled="links.prev === null"
                            class="button is-medium"
                            @click.prevent="loadAsyncData(links.prev)">
                            <i class="icon">chevron_left</i>
                        </button>

                        <button
                            :disabled="links.next === null"
                            class="button is-medium" @click.prevent="loadAsyncData(links.next)">
                            <i class="icon">chevron_right</i>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs'

const createSortable = (el, options, vnode) => {
    return Sortable.create(el, {
        ...options,
        onEnd: function (evt) {
            const data = vnode.context.rows.data
            const item = data[evt.oldIndex]
            if (evt.newIndex > evt.oldIndex) {
                for (let i = evt.oldIndex; i < evt.newIndex; i++) {
                    data[i] = data[i + 1]
                }
            } else {
                for (let i = evt.oldIndex; i > evt.newIndex; i--) {
                    data[i] = data[i - 1]
                }
            }
            vnode.context.sortingChanged = vnode.context.sortingChanged === true ? false : true
            data[evt.newIndex] = item

            vnode.context.sortingOrder = []

            for (let item of data) {
                vnode.context.sortingOrder.push(item.id)
            }
        },
    })
}

const sortable = {
    name: 'sortable',
    bind(el, binding, vnode) {
        if (binding.value.active) {
            const table = el.querySelector('table')
            table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
        }
    },
    update(el, binding, vnode) {
        if (binding.value.active) {
            const table = el.querySelector('table')
            table._sortable.destroy()
            table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode)
        }
    },
    unbind(el, binding) {
        if (binding.value.active) {
            const table = el.querySelector('table')
            table._sortable.destroy()
        }
    }
}

export default {
    directives: {sortable},
    data() {
        return {
            // Data
            rows: {},
            links: {
                next: null,
                prev: null,
            },
            meta: {
                active_url: null,
                current_page: 1,
                from: 0,
                last_page: 0,
                path: null,
                to: 0,
                total: 0,
            },

            // State
            loading: true,
            initialised: false,
            noConnection: false,

            // Sortable (drag drop)
            sortDirection: 'desc',
            sortableOptions: {
                active: this.dragDrop,
                handle: '.drag-drop-column',
                chosenClass: 'drag-drop-selection',
            },
            sortingChanged: false,
            sortingOrder: [],
            queryParameters: null,
        }
    },

    props: {
        onRowClick: {
            type: Function,
            default: () => {}
        },
        selectedRow: Object,
        sortColumn: {
            type: String,
            required: true
        },
        url: {
            type: String,
            default: ''
        },
        perPage: {
            type: Number,
            default: 50
        },
        dragDrop: {
            type: [String, Boolean],
            default: false
        },
        filterData: {
            type: Function,
        },
    },

    mounted() {
        this.loadAsyncData(this.url)
    },

    methods: {
        parseURL(url = this.url, page = null) {
            const queryObject = {
                sort: this.sortColumn,
                direction: this.sortDirection,
                per_page: this.perPage,
                ...this.queryParameters !== null ? this.queryParameters : {},
            }

            if (page !== null) {
                queryObject.page = page
            }

            if (typeof this.filterData === 'function') {
                const filters = this.filterData()

                for (const key in filters) {
                    if (!!filters[key].value) {
                        queryObject[key] =
                            typeof filters[key].parse === 'function' ?
                                filters[key].parse(filters[key].value) :
                                filters[key].value
                    }
                }
            }

            // If the URL is built of a relative path, we need to prefix the domain
            if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
                url = window.location.origin + url;
            }

            let parsedURL = new URL(url);

            for (let queryObjectKey in queryObject) {
                if (!(queryObject[queryObjectKey] instanceof Array)) {
                    parsedURL.searchParams.set(queryObjectKey, queryObject[queryObjectKey]);

                    continue;
                }

                for (let queryObjectKeySub in queryObject[queryObjectKey]) {
                    parsedURL.searchParams.append(queryObjectKey + '[]', queryObject[queryObjectKey][queryObjectKeySub])
                }
            }

            return parsedURL.toString()
        },

        onSort(column, direction) {
            this.sortDirection = direction

            this.meta.current_page = 1
            this.loadAsyncData()
        },

        getPageData(page) {
            this.loadAsyncData(this.url, page)
        },

        reloadCurrentPage() {
            this.loadAsyncData(this.meta.active_url)
        },

        loadAsyncData(url = this.url, page = null) {
            this.loading = true

            // Get query parameters from URL
            const urlSearchParameters = new URLSearchParams(url.split('?')[1])
            const queryParameters     = Object.fromEntries(urlSearchParameters.entries())

            // Set initial query parameters
            if (this.queryParameters === null) {
                this.queryParameters = queryParameters
            }

            let requestUrl = this.parseURL(url, page)
            axios.get(requestUrl)
                .then(({data}) => {
                    this.setMetaData(data)
                    this.meta.active_url = requestUrl
                    this.rows = data
                    this.noConnection = false
                    this.initialised = true

                    this.$emit('changed', data)
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.warn('Request canceled', error.message)

                        return
                    }

                    if (typeof error.response === 'undefined') {
                        this.rows = {}
                        this.noConnection = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        setMetaData(data) {
            if (typeof data.meta !== 'undefined') {
                this.meta.current_page = data.meta.current_page ?? 1;
                this.meta.from = data.meta.from ?? 0;
                this.meta.to = data.meta.to ?? 0;
                this.meta.last_page = data.meta.last_page ?? 0;
                this.meta.path = data.meta.path ?? null;
                this.meta.total = data.meta.total ?? 0;
            }

            if (typeof data.links !== 'undefined') {
                this.links.next = data.links.next;
                this.links.prev = data.links.prev;
            }
        }
    },
    computed: {
        sortedData() {
            if (this.dragDrop && this.initialised && this.rows.data.length) {
                let obj = this.rows.data[0]

                if ('position' in obj) {
                    return this.rows.data.sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
                }
            }
            return this.rows.data
        }
    },
    watch: {
        sortingChanged() {
            if (this.dragDrop) {
                this.$emit('sorting-update', this.sortingOrder)
            }
        },
    },
}
</script>
