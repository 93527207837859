<template>
	<button :class="['button', color, size, { 'is-loading': isLoading, 'is-rounded': isRounded } ]"
			type="submit" :disabled="isLoading || disabled" @click="emit">
        <i class="icon" v-if="icon">{{ icon }}</i>
		<span><slot></slot></span>
	</button>
</template>

<script>

	import InputHelper from '../../utils/InputHelper'

	export default InputHelper.extend({
		props: {
			name,
			isRounded: {
			    default: true,
			},
			color: {
			    default: 'is-success'
			}
		},
		computed: {
			isLoading() {
				return this.loading || this.isFormLoading;
			},
			isFormLoading() {
				return this.form ? this.$parent.isLoading : false;
			}
		},

		methods: {
            emit (e) {
                this.$emit('click', e)
            },
		},
	})

</script>
