import store from '../store/'

export default class Guard {

    static async hasAccess (to, from, next) {

        if (store.getters['user/requiresUpdate'] === true) {
            await store.dispatch('user/getUserData').catch(() => {})
        }

        const authRoutes = [
            'auth.login',
            'password.reset',
            'password.reset.confirm',
        ]

        const registerRoutes = [
            'register',
            'register.confirmation',
        ]

        const shouldFinishRegistration =
                  store.getters['user/isAuthenticated'] === true &&
                  store.getters['user/isImpersonated'] === false &&
                  store.getters['user/activeCompany'].registration_process < 6 &&
                  !sessionStorage.getItem('finished_registration')

        if (shouldFinishRegistration) {
            if (registerRoutes.includes(to.name)) {
                return next()
            }

            return next({name: 'register'})
        }

        // Navigating to route that is only allowed when logged out
        if (
            store.getters['user/isAuthenticated'] &&
            authRoutes.includes(to.name)
        ) {
            return next({name: 'home'})
        }

        // Hide page if it's only intended for non-collaborative accounts
        if (
            typeof to.meta !== 'undefined' &&
            typeof to.meta.hide_from_collaborative_accounts !== 'undefined' &&
            to.meta.hide_from_collaborative_accounts === true &&
            to.path !== from.path &&
            store.getters['user/isAuthenticated'] &&
            !! store.getters['user/activeCompany'].is_collaborative === true
        ) {
            return next({ name: 'home' })
        }

        // No active membership for route that requires it
        if (
            store.getters['user/isAuthenticated'] &&
            store.getters['user/activeCompany'].has_active_membership === false &&
            typeof to.meta.must_have_current_membership !== 'undefined' &&
            to.meta.must_have_current_membership === true
        ) {
            return next({name: 'settings.finance.membership'})
        }

        // No permission for route that requires it
        if (
            store.getters['user/isAuthenticated'] &&
            typeof to.meta.permissions !== 'undefined' &&
            to.meta.permissions.every(permission => store.getters['user/hasPermission'](permission)) === false
        ) {
            return next({name: 'home'})
        }

        if (
            store.getters['user/isAuthenticated'] ||
            authRoutes.includes(to.name)
        ) {
            return next()
        }

        if(registerRoutes.includes(to.name)) {
            return next()
        }

        return next({name: 'auth.login'})
    }
}
