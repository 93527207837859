<template>
    <div id="header-menu" class="is-hidden-mobile">
        <ul class="inline-menu custom">
            <li v-for="(item, index) in items"
                :key="index"
                @click="active = index"
                @mouseover="setMarkerDimensions(index)"
                @mouseleave="setMarkerDimensions(active)"
                v-if="allowedRoute(item.route_name)">
                <router-link :to="getRoute(item.route_name)" :class="`menuLink${index}`" :ref="`menuLink${index}`">
                    {{ $t(item.title) }}
                </router-link>
            </li>
            <div class="marker" ref="marker"></div>
        </ul>
    </div>
</template>

<script>
import { allowedRoute, getRoute } from '../../menu'

export default {
    components: {},

    props: {
        items: {
            type:     Array,
            required: true,
        },
    },

    data () {
        return {
            active: '',
            getRoute,
            allowedRoute
        }
    },

    methods: {
        initActive () {
            this.items.forEach((item, index) => {
                if (typeof this.$refs[`menuLink${index}`] !== 'undefined' &&
                    typeof this.$refs[`menuLink${index}`][0] !== 'undefined' &&
                    this.$refs[`menuLink${index}`][0].$el.classList.contains('is-active')) {
                    this.active = index
                }
            })
            this.setMarkerDimensions(this.active)
        },
        setMarkerDimensions (index) {
            let ref = `menuLink${index}`

            if (typeof (this.$refs[ref]) == 'undefined' || !this.$refs[ref][0]) {
                return
            }

            let offset = this.$refs[ref][0].$el.offsetLeft
            let width  = this.$refs[ref][0].$el.offsetWidth

            this.$refs.marker.style.width   = width + 'px'
            this.$refs.marker.style.left    = offset + 'px'
            this.$refs.marker.style.display = 'block'
        }
    },
    mounted () {
        this.initActive()
    },
    updated () {
        this.initActive()
    }
}
</script>
