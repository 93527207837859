<template>

    <div :class="['field', { 'error': hasError, 'animation': labelAnimation }]">
        <label class="label"
               :for="id"
               v-if="!labelAnimation && label">
            {{ labelText }}
        </label>

        <div :class="['control is-expanded', { 'has-icons-right': icon || hasError }]">
			<span class="select is-fullwidth">
				<select :id="id"
                        v-if="!multiple"
                        v-model="data[name]"
                        v-bind="$attrs"
                        :disabled="disabled"
                        :class="[
                            {'select-invisible': typeof $attrs.invisible !== 'undefined'},
                            {'is-danger': hasError},
                            gClass,
                            size,
                        ]">

					<option v-for="option in computedOptions"
                            :value="option['value']"
                            :key="option['value']"
                            v-bind="$attrs"
                            :disabled="typeof option.disabled !== 'undefined'">{{ option['name'] }}
					</option>

				</select>
                 <tree-select
                     :id="id"
                     v-if="multiple"
                     v-model="data[name]"
                     v-bind="$attrs"
                     :disabled="disabled"
                     :class="[
                            {'select-invisible': typeof $attrs.invisible !== 'undefined'},
                            {'is-danger': hasError},
                            gClass,
                            size,
                        ]"
                     :multiple="true"
                     :options="computedMultipleOptions"
                     :placeholder="$t('g-select.placeholder')"
                     :clearable="false"
                     value-consists-of="LEAF_PRIORITY"
                     :limit="0"
                     :limitText="(count) => $t('g-select.multiple_selected', {count})"
                     :noResultsText="$t('g-select.no_results')"/>
			</span>

            <i class="icon is-right" :class="[size]" v-if="!hasError && !!icon">{{ icon }}</i>
            <i class="icon is-right is-danger" :class="[size]" v-if="hasError">error</i>
        </div>
        <div class="hover-area"
             v-if="hasError"
             @mouseover="showErrorMessage"
             @mouseleave="hideErrorMessage">
            <div class="message-box">
                <div class="error-message" v-show="errorMessageVisible">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <label :class="['label', { 'active': isSelectActive || isInputActive }]"
               :for="id"
               v-if="labelAnimation">
            {{ labelText }}
        </label>
    </div>
</template>

<script>
import InputHelper from '../../utils/InputHelper'

export default InputHelper.extend({
    props:   {
        valuekey: {default: 'value'},
        namekey:  {default: 'name'},
        options:  {required: true},
        multiple: {default: false},
        size:     '',
    },
    created () {
        this.$watch(`data.${this.name}`, value => {
            this.$emit('input', value)
        })
    },
    computed: {
        computedOptions() {
            if(this.options === 'trueFalse') {
                return [
                    {name: this.$t('g-form.defaults.yes'), value: 1},
                    {name: this.$t('g-form.defaults.no'), value: 0},
                ]
            }

            if ('string' === typeof this.options[0]) {
                const newOptions = []
                this.options.forEach(option => {
                    newOptions.push({
                        name:  option,
                        value: option,
                    })
                })
                return newOptions
            }

            return this.options.map(option => {
                return {
                    name:  option[this.namekey],
                    value: option[this.valuekey],
                }
            })
        },
        computedMultipleOptions() {
            // Map to id and label
            return this.computedOptions.map(option => {
                return {
                    id: option['value'],
                    label: option['name'],
                }
            })
        },
        isSelectActive() {
            // Check if an option with value null exists
            if (typeof this.computedOptions === 'undefined') return false;

            if(this.data[this[this.namekey]] !== null) {
                return true;
            }

            const nullOptionExists = this.computedOptions.filter(option => {
                return option[this.valuekey] === null;
            }).length > 0

            return nullOptionExists && this.data[this[this.namekey]] === null;
        }
    },
})

</script>
