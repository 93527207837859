<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('wrapping_browser.title') }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body">
                        <div class="columns is-multiline">
                            <div class="column is-4" v-for="wrapping in wrappings">
                                <div class="box" @click="setWrapping(wrapping.id)">
                                    <img :src="wrapping.image" alt="Wrapping" class="">
                                    <p>
                                        {{ wrapping.name }}<br />
                                        <small>{{ wrapping.company_price / 100 | currency }}</small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="popup-footer">
                        <a @click.prevent="destroy" href="#" role="button">{{ this.$t('agreement_dialog.deny') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.box {
    text-align: center;
    padding: 0.8rem;
    border: 1px solid #FFFFFF;

    &:hover {
        cursor: pointer;
        border: 1px solid #ddd;
    }
}

img {
    border-radius: 2px;
}
</style>

<script>
export default {
    props: {
        wrappings: {
            type: Array,
            required: true
        }
    },

    methods: {
        setWrapping(id) {
            this.$emit('selected', id)
            this.destroy()
        }
    }
}
</script>
