<template>
  <div class="tabbed-view">
    <slot></slot>
  </div>
</template>

<script>
import TabbedViewItem from "./TabbedViewItem.vue";

export default {
  name: "TabbedView",
  components: {TabbedViewItem},
  data() {
    return {
      tabs: [],
    }
  },
  mounted() {
    let initializedTabs = [];

    if (this.$slots.default) {
      let tabs = this.$slots.default

      // Filter out other types of nodes
      tabs = tabs.filter(tab => tab.tag)

      tabs.forEach(tab => {
        initializedTabs.push({
          title: tab.componentOptions.propsData.title,
          content: tab
        })
      })
    }

    // Emit the tabs to the parent
    this.$emit('tabsInitialized', initializedTabs)
  }
}
</script>

<style scoped lang="scss">
.tabbed-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
