import FontFaceObserver from 'fontfaceobserver'
import forEach from 'lodash/forEach'
import {waitFor} from '../utils/Consts'

export default {
    data () {
        return {
            loadedImages:     {},
            ctx:              null,
            drawModeCentered: false,
            canvasReady:      false,
        }
    },

    async mounted () {
        this.ctx = this.$refs.canvas.getContext('2d')

        // Create a retina ready canvas
        const canvas        = this.$refs.canvas
        canvas.width        = this.width * 2
        canvas.height       = this.height * 2
        canvas.style.width  = (this.width) + 'px'
        canvas.style.height = (this.height) + 'px'

        // Scale the canvas 2 times for retina + added percentage of the cutlines
        this.ctx.scale(2.06, 2.06)

        // Cutlines are 8 pixels wide, and it needs to draw everything starting from (x: -8, y: -8)
        this.ctx.translate(-8, -8)

        // Simultanious run the two promises, that check if canvas has width and height
        const forWidth = waitFor(this.ctx.canvas.width)
        const forHeight = waitFor(this.ctx.canvas.height)

        // Wait for both of them to be a truthy value
        if (await forWidth && await forHeight) {

            // There can be drawn on the canvas
            this.canvasReady = true
        }
    },

    methods: {

        /**
         * Reset canvas to a white screen
         */
        reset () {
            console.log('Resetting canvas')

            this.rectangle(0, 0, this.width, this.height, '#fff', false)
        },

        /**
         * Draw rectangle
         * @param x
         * @param y
         * @param w
         * @param h
         * @param color
         * @param centered
         */
        rectangle (x, y, w, h, color = '#fff', centered = this.drawModeCentered) {

            if (centered) {
                x -= w / 2
                y -= h / 2
            }

            this.ctx.fillStyle = color
            this.ctx.fillRect(x, y, w, h)
        },

        /**
         * Draw ellipse
         * @param x
         * @param y
         * @param r
         * @param color
         * @param centered
         */
        circle (x, y, r, color = '#fff', centered = this.drawModeCentered) {

            if (centered) {
                x -= r / 2
                y -= r / 2
            }

            this.ctx.fillStyle = color
            this.ctx.beginPath()
            this.ctx.arc(x, y, r, 0, 2 * Math.PI)
            this.ctx.fill()
        },

        /**
         * Check if the value is a valid image for a HTMLCanvasElement
         * @param image
         * @returns {boolean}
         */
        isImage (image) {
            const allowed = [
                HTMLImageElement,
                SVGImageElement,
                HTMLVideoElement,
                HTMLCanvasElement,
                Image, File,
            ]

            let isImage = false
            for (const type of allowed) {
                if (image instanceof type) {
                    isImage = true
                    break
                }
            }

            return isImage
        },

        /**
         * Draw image
         * @param {HTMLImageElement|SVGImageElement|HTMLVideoElement|HTMLCanvasElement|Image|File|String} image
         * @param x
         * @param y
         * @param w
         * @param h
         * @param scale
         * @param autoHeight
         * @returns {Promise}
         */
        image (image, x, y, w, h, scale = 1, autoHeight = false) {

            const draw = (img) => {
                h = autoHeight ? (w * img.height / img.width) : h

                if (this.drawModeCentered) {
                    x -= w / 2
                    y -= h / 2
                }
                this.ctx.drawImage(img, x, y, w * scale, h * scale)
            }

            return new Promise(resolve => {

                if (this.isImage(image)) {
                    draw(image)
                    return resolve(true)

                } else if (typeof image === 'string') {

                    if (typeof this.loadedImages[image] === 'undefined') {
                        const imageObj           = new Image()
                        imageObj.onload          = event => {
                            draw(imageObj)
                            resolve(true)
                        }
                        imageObj.src             = image
                        this.loadedImages[image] = imageObj

                    } else {
                        draw(this.loadedImages[image])
                        resolve(true)
                    }
                }

            })
        },

        /**
         * Load font in a style element
         * @param font
         * @returns {Promise}
         */
        loadFont (font) {
            return new Promise((resolve, reject) => {
                console.log('Loading font:', font)

                // Attempt to load the font with a timeout
                const fontFaceObserver = new FontFaceObserver(font);
                const ONE_SECOND = 1000;
                fontFaceObserver.load(null, 3 * ONE_SECOND).then(() => {
                    return resolve(true);
                }).catch(error => {
                    console.error('Font loading failed:', font, error);
                    return resolve(false);
                });

                // Add font to the document
                const url = this.defaults.FONT_LOCATION
                let style = ''

                style += `@font-face {
                            font-family: "${font}";
                            font-display: swap;
                            src: url("${url}${font}");
                          }`

                // Ensure only one style element for font faces
                let styleElement = document.getElementById('builder-font-styles');
                if (!styleElement) {
                    styleElement = document.createElement('style');
                    styleElement.id = 'builder-font-styles';
                    document.head.appendChild(styleElement);
                }

                styleElement.appendChild(document.createTextNode(style));
            })
        },

        /**
         * Draw text
         * @param x
         * @param y
         * @param text
         * @param size
         * @param font
         * @param style
         * @param color
         * @param textAlign
         * @param maxLength
         * @returns {Promise.<void>}
         */
        async text (x, y, text, size, font = 'Open sans', style = '', color = '#000', textAlign = 'start', maxLength = 'default') {
            // Check if the bold rule applies
            if (/^\*(.*)\*\s{0,3}$/.test(text)) {
                style = 'bold'
                text  = text.substr(1, text.lastIndexOf('*') - 1)
            }

            // Set text styling
            this.ctx.fillStyle = color
            this.ctx.textAlign = textAlign
            this.ctx.font      = `${style} ${size}px "${font}"`

            // Change font size if it needs to fit inside a container
            if (typeof maxLength === 'number') {
                const containerWidth = maxLength
                let textWidth        = this.ctx.measureText(text).width + 150

                if (textWidth >= containerWidth) {

                    size *= containerWidth / textWidth
                }

                this.ctx.font = `${style} ${size}px "${font}"`

            }

            // Draw text
            this.ctx.fillText(text, x, y)

            return new Promise(resolve => resolve())
        },

        async textBlock (text, x, y, width, size, spacing = 1.0, textAlign = 'start', font = 'Open sans', style = '', color = '#000') {
            // Set font for calculating the text width
            this.ctx.font = `${style} ${size}px "${font}"`

            // Stored lines
            let originalLines = text.split(/\n/g),
                finalLines    = []

            // Loop through every word of every line to check if the line needs to be split in two
            forEach(originalLines, line => {

                let newLines     = [],
                    lineTmp      = '',
                    finalLineTmp = '',
                    words        = line.split(' ')

                forEach(words, (word, index) => {

                    // Create a new line and add the next word to it
                    lineTmp += word + ' '

                    // Measure if the temporary line exceeds the width limit
                    if (this.ctx.measureText(lineTmp).width >= width) {

                        // Store the temporary line minus the word that caused it to exceed the limit to a new line array
                        newLines.push(finalLineTmp)

                        // Start a new tmp line using the word that would be cut off
                        lineTmp = finalLineTmp = word + ' '

                        // Also send this to the new lines list if it is the last word
                        if (index === words.length - 1) {
                            newLines.push(lineTmp)
                            lineTmp = finalLineTmp = ''
                        }
                    } else if (index === words.length - 1) {
                        // Store the created tmpline if word is the last word
                        newLines.push(lineTmp)
                        lineTmp = finalLineTmp = ''
                    } else {
                        // Update the final line to match the tmp line if the word fits and the word is not the last word
                        finalLineTmp = lineTmp
                    }
                })

                // Merge all final lines and new lines
                finalLines.push(...newLines)

            })

            for (let i = 0; i < finalLines.length; i++) {
                // Draw every line below each other
                await this.text(x, y + (i * size * spacing), finalLines[i], size, font, style, color, textAlign)
            }

            return new Promise(resolve => resolve())
        },

        /**
         * Converts PDF coordinates to canvas coordinates
         * @param n
         * @returns {Number}
         */
        coords (n) {
            let templateHeight = this.element('background', 'height', 'int')
            return this.map(parseInt(n), 0, templateHeight, 0, this.height)
        },

        /**
         * Conserve aspect ratio of the orignal region. Useful when shrinking/enlarging
         * images to fit into a certain area.
         *
         * @param {Number} srcWidth Source area width
         * @param {Number} srcHeight Source area height
         * @param {Number} maxWidth Fittable area maximum available width
         * @param {Number} maxHeight Fittable area maximum available height
         * @return {Object} { width, heigth }
         */
        calculateAspectRatioFit (srcWidth, srcHeight, maxWidth, maxHeight) {

            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)

            return {width: srcWidth * ratio, height: srcHeight * ratio}
        },

        /**
         * Check if element has property
         * @param el
         * @param prop
         * @returns {boolean}
         */
        hasElement (el, prop) {
            return !!this.currentData.template.elements[el] && this.currentData.template.elements[el][prop]
        },

        /**
         * Gets value of an element and property
         * @param el
         * @param prop
         * @param type
         * @returns {Number|String}
         */
        element (el, prop, type = 'coord') {
            const value = this.currentData.template.elements[el][prop].value

            switch (type) {
                case 'coord':
                    return Math.round(this.coords(value))
                case 'int':
                    return parseInt(value)
                default:
                    return value
            }
        },

        /**
         *
         * @param n
         * @param start1
         * @param stop1
         * @param start2
         * @param stop2
         * @returns {Number}
         */
        map (n, start1, stop1, start2, stop2) {
            return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
        },

        setMaxedFontSize (font, style, defaultTextSize, text, containerWidth, minSize = 0) {
            let textSize  = defaultTextSize
            this.ctx.font = `${style} ${textSize}px "${font}"`

            const stringWidth = this.ctx.measureText(text).width + 1500
            if (stringWidth >= containerWidth) {
                const amountEnlargement = containerWidth / stringWidth
                textSize                = textSize * amountEnlargement
            }

            if (minSize > 0 && textSize < minSize) {
                textSize = minSize
            }

            return textSize
        }
    },
}
