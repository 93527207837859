<template>
	<transition name="fade">
		<div class="modal is-active" v-show="visible">
			<div class="modal-background" @click="destroy"></div>
			<div class="modal-content">
				<div class="popup-dialog">

					<div class="popup-title">
						<h1 class="title is-1">{{ this.$t('payment.sepa.title') }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <div class="popup-body">
                        <p v-show="missingRequiredFields.length === 0 && has_finished === false">{{ this.$t('payment.sepa.message') }}</p><br />

                        <p v-if="date_start !== null && date_end !== null">
                            {{ this.$t('payment.sepa.message_specified_period', exportDate) }}
                        </p>
                    </div>

                    <template v-if="missingRequiredFields.length >= 1 && is_prepopulated">
                        <div class="popup-body">
                            <div class="notification is-warning">
                                <div>
                                    <strong>{{ this.$t('payment.sepa.missing_details.title') }}</strong><br/>
                                    {{ this.$t('payment.sepa.missing_details.message') }}
                                    <br/>

                                    <ul>
                                        <li v-for="(field) in missingRequiredFields" :key="field">{{ $t(`payment.sepa.${field}`) }}</li>
                                    </ul>

                                    {{ this.$t('payment.sepa.missing_details.footer') }}
                                    <a href="#" @click="openOrganisationDetails">{{ this.$t('navigation.company_details') }}</a>.
                                </div>
                            </div>
                        </div>
                    </template>

                    <div v-show="missingRequiredFields.length === 0 && has_finished === false">
                        <g-form :form="form" method="post" @success="success" @fail="fail" @prepopulated="prepopulated"
                                :action="`/api/company/${ this.$store.getters['user/activeCompanyId'] }/collaboration-settle/sepa-export`"
                                 prepopulate="true" :before="before" v-show="missing_companies === null">
                            <div class="popup-body popup-rows">
                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <strong>{{ this.$t('payment.sepa.account') }}</strong>
                                    </div>

                                    <div class="column">
                                        {{ form.account_number_name }}<br/>
                                        {{ form.account_number }}
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <strong>{{ this.$t('payment.sepa.address')}}</strong>
                                    </div>

                                    <div class="column">
                                        <address>
                                        {{ form.street }} {{ form.house_number }}{{ form.addition }}<br />
                                        {{ form.zip }} {{ form.city }}
                                        </address>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column is-one-quarter">
                                        <strong>{{ this.$t('payment.sepa.task') }}</strong>
                                    </div>

                                    <div class="column">
                                        <g-datepicker disable-past
                                                      not-today
                                                      :label="this.$t('payment.sepa.execution_date')"
                                                      name="execution_date"/>

                                        <g-input type="textarea"
                                                 name="payment_description"
                                                 :disabled="!is_prepopulated"
                                                 :label="this.$t('payment.sepa.description')"/>

                                        <g-switch
                                            name="include_promotional"
                                            :label="this.$t('payment.sepa.include_promotional')"
                                            :subtitle="this.$t('payment.sepa.include_promotional_subtitle')"
                                        />
                                    </div>
                                </div>

                            </div>

                            <div class="popup-footer">
                                <a @click.prevent="destroy" href="#" role="button">{{ this.$t('confirmation_dialog.deny') }}</a>
                                <g-button>{{ this.$t('payment.sepa.export') }}</g-button>
                            </div>
                        </g-form>
                    </div>

                    <div class="popup-body" v-if="missing_companies">
                        {{ this.$t('payment.sepa.missing_details.companies.title') }}<br/><br/>

                        <ul>
                            <li v-for="(company, index) in missing_companies" :key="index">
                                {{ company.name }} ({{ parseMissingDetails(company) }})
                            </li>
                        </ul>
                    </div>

                    <div v-show="has_finished">
                        <div class="popup-body">
                            <p>{{ this.$t('payment.sepa.success.title') }}</p>

                            <i18n path="payment.sepa.success.subtitle" tag="p">
                                <template v-slot:action>
                                    <i18n path="payment.sepa.success.click_here" tag="a" :href="downloadUrl"/>
                                </template>
                            </i18n>

                            <br/>
                            <label>{{ this.$t('payment.sepa.task') }}</label>

                            <i18n path="payment.sepa.task_confirmation" tag="p">
                                <template v-slot:value>
                                    <strong>
                                        {{ response ? response.context.value / 100 : 0 | currency }}
                                    </strong>
                                </template>
                                <template v-slot:companies>
                                    <strong>
                                        {{ response ? response.context.companies : 0 }}
                                    </strong>
                                </template>
                                <template v-slot:companies_plural>
                                    {{ response ? $tc('payment.sepa.companies', response.context.companies) : 0 }}
                                </template>
                                <template v-slot:execution_date>
                                    <strong>{{ response ? formatExecutionDate(response.context.execution_date) : 0 }}</strong>
                                </template>
                            </i18n>

                            <br/>
                            <label>{{ this.$t('payment.sepa.success.key') }}</label>
                            <p>
                                {{ this.$t('payment.sepa.success.message') }}
                            </p>

                            <input type="text"
                                   class="input is-small mt-3"
                                   disabled
                                   :value="response ? response.checksum : ''">
                        </div>

                        <div class="popup-footer">
                            <button @click.prevent="destroy" href="#" role="button" class="button is-success is-normal is-rounded">
                                {{ this.$t('payment.sepa.close') }}
                            </button>
                        </div>
                    </div>


				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import moment from "moment"

export default {
        data () {
            return {
                missing_companies: null,
                is_prepopulated: false,
                has_finished: false,
                response: null,

                form: new Form({
                    account_number:      '',
                    account_number_name: '',
                    street:              '',
                    house_number:        '',
                    addition:            '',
                    zip:                 '',
                    city:                '',
                    payment_description: '',
                    include_promotional: 0,
                    date_start:          '',
                    date_end:            '',
                    execution_date:      null,
                }),
            }
        },

        props: {
            date_start: {
                type: Date
            },
            date_end: {
                type: Date
            }
        },

        computed: {
            downloadUrl () {
                return this.response ? this.response.url
                                     : ''
            },

            missingRequiredFields () {
                let fields = {
                    'account_number':      this.form.account_number,
                    'account_number_name': this.form.account_number_name,
                    'street':              this.form.street,
                    'house_number':        this.form.house_number,
                    'zip':                 this.form.zip,
                    'city':                this.form.city
                }

                return Object.entries(fields).filter(([x, value]) => value === '')
                                            .map(item => item[0])
            },

            exportDate () {
                return {
                    start: moment(this.date_start).format('DD-MM-YYYY'),
                    end: moment(this.date_end).format('DD-MM-YYYY')
                }
            }
        },

        methods: {
            formatExecutionDate (date) {
                return moment(date, 'YYYY-MM-DD').format('D MMMM YYYY')
            },

            parseMissingDetails (company) {
                const missingDetails = []

                if (company.has_account_number === false) {
                    missingDetails.push(this.$t('payment.sepa.missing_details.companies.account_number'))
                }

                if (company.has_account_number_name === false) {
                    missingDetails.push(this.$t('payment.sepa.missing_details.companies.account_number_name'))
                }

                return missingDetails.join(` ${ this.$t('payment.sepa.missing_details.companies.conjunction') } `)
            },

            before (form) {
                if (typeof form.date_start === 'undefined') {
                    this.$delete(form, 'date_start')
                } else {
                    form.date_start = moment(this.date_start, 'MM-DD-YYYY').startOf('day').toISOString()
                }

                if (typeof form.date_end === 'undefined') {
                    this.$delete(form, 'date_end')
                } else {
                    form.date_end = moment(this.date_end, 'MM-DD-YYYY').endOf('day').toISOString()
                }

                return new Form({
                    payment_description: form.payment_description,
                    date_start:          form.date_start,
                    date_end:            form.date_end,
                    include_promotional: form.include_promotional,
                    execution_date:      moment(form.execution_date).format('YYYY-MM-DD'),
                    creation_date:       moment().toISOString()
                })
            },

            prepopulated () {
                this.is_prepopulated = true

                if (this.date_start && this.date_end) {
                    this.form.date_start = this.date_start
                    this.form.date_end = this.date_end
                }

                this.form.include_promotional = 0;
                this.form.execution_date = moment().add(1, 'd')

                const month = this.form.execution_date.locale(this.$i18n.locale ?? 'en').format('MMMM');
                const year = this.form.execution_date.locale(this.$i18n.locale ?? 'en').format('YYYY');

                this.form.payment_description = this.$t('payment.sepa.default_description', {
                    company_name: this.$store.getters['user/activeCompany'].name,
                    month: month,
                    year: year,
                })
            },

            success (data) {
                this.has_finished = true
                this.response = {
                    checksum: data.checksum,
                    url: data.url,
                    context: data.context
                }

                // Automatically download the SEPA file
                setTimeout(() => {
                    window.open(data.url, '_blank');
                }, 1000)
            },

            fail (response) {
                if (response.status === 400) {
                    this.missing_companies = response.errors
                }
            }
        }
    }
</script>
