<template>
    <transition name="fade">
        <div class="modal model-large is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>
                        <p>Voeg een factuurregel toe om korting of kosten in rekening te brengen. De factuurregel wordt
                            toegevoegd aan de maandelijkse lidmaatschapsfactuur.</p>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" @success="destroy"
                            :prepopulate="!this.isCreateNewForm"
                            @prepopulated="setFrequency"
                            message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body">
                            <label>Regel</label>
                            <div class="invoice-line autocomplete-container">
                                <g-input type="number" name="quantity"/>
                                <div class="invoice-details" v-click-outside="() => presets_visible = false">
                                    <g-input
                                        name="name"
                                        placeholder="Titel"
                                        @focus="presets_visible = true"
                                    />
                                    <g-input name="description" placeholder="Omschrijving"/>

                                    <ul class="autocomplete-options" v-if="presets_visible">
                                        <li v-for="preset in this.invoicePresetsFiltered" @click="setPreset(preset)">
                                            <strong>{{ preset.name }}</strong>
                                            <small class="help">{{ preset.amount | currency }}</small>
                                        </li>
                                    </ul>
                                </div>
                                <div class="invoice-ledger-account">
                                    <g-select name="ledger_account_id" :options="this.ledgerAccountsPrefixedWithCode"
                                              valuekey="id"/>
                                </div>
                                <g-format name="amount" type="positiveOrNegativeCurrency"/>
                            </div>

                            <template v-if="this.form.execution_times === 0">
                                <label>Herhaling</label>
                                <div class="columns">
                                    <div class="column is-8">
                                        <div class="field-body">
                                            <g-select label="Frequentie" name="repeat_selection"
                                                      :options="repeatOptions"/>
                                            <g-input v-show="this.form.repeat_selection === 'monthly'" type="number"
                                                     name="repeat" label="Aantal maanden"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button>Opslaan</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from "vuex";
import ClickOutside from './../../ClickOutsideDirective'
import Fuse from 'fuse.js'

export default {
    props: {
        company_id: String,
        id: Number,
    },
    directives: {
        'click-outside': ClickOutside
    },
    data() {
        return {
            form: null,
            presets_visible: true,
            repeatOptions: [
                {name: 'Eenmalig', value: 'once'},
                {name: 'Maandelijks', value: 'monthly'},
                {name: 'Oneindig', value: 'infinite'},
            ],
        }
    },
    created() {
        let formFields = {
            repeat_selection: 'once',
            name: '',
            description: '',
            quantity: 1,
            amount: null,
            repeat: 1,
            execution_times: 0,
            ledger_account_id: null,
        }

        if (this.id) {
            formFields.id = this.id
        }

        this.form = new Form(formFields)
    },
    computed: {
        ...mapGetters({
            ledger_accounts: 'adminLedgerAccounts/all',
            invoice_presets: 'adminInvoicePresets/all',
        }),
        ledgerAccountsPrefixedWithCode() {
            return this.ledger_accounts.map(ledgerAccount => {
                return {
                    ...ledgerAccount,
                    name: `${ledgerAccount.code} - ${ledgerAccount.name}`,
                }
            })
        },
        invoicePresetsFiltered() {
            if (this.form.name.length === 0) {
                return this.invoice_presets;
            }

            const options = {
                keys: ['name', 'description']
            }

            const fuse = new Fuse(this.invoice_presets, options)

            return fuse.search(this.form.name).map(result => result.item)
        },
        isCreateNewForm() {
            return this.id === null
        },
        formTitle() {
            return this.isCreateNewForm ? 'Factuurregel toevoegen' : 'Factuurregel aanpassen'
        },
        formEndpoint() {
            let endpoint = `/api/admin/company/${this.company_id}/invoiceRule`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },
        formMethod() {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },
    watch: {
        'form.repeat_selection'(selection) {
            if (selection === 'infinite') {
                this.form.repeat = null
            } else if (selection === 'once') {
                this.form.repeat = 1
            } else if (this.form.repeat === null) {
                this.form.repeat = 12
            }
        }
    },
    methods: {
        setPreset(preset) {
            this.form.name = preset.name;
            this.form.description = preset.description ?? this.form.description;
            this.form.amount = preset.amount ?? this.form.amount;
            this.form.ledger_account_id = preset.ledger_account_id;

            this.presets_visible = false
        },
        setFrequency({data}) {
            if (data.repeat_selection === 'monthly' && data.repeat === 1) {
                this.form.repeat_selection = 'once'
            }
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    form .invoice-line {
        display: flex;
        align-items: flex-start;

        > div {
            flex: 2;
            margin-right: .5em;

            &:last-child {
                flex: 3;
                margin-right: 0;
            }
        }

        input, select {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .invoice-ledger-account {
            flex: 4;
        }

        .invoice-details {
            flex: 8;
            border-radius: 6px;
            border: 1px solid #EFEFEF;
            padding: 0.375em 1.1rem;
            margin-bottom: 1.5rem;
            box-shadow: 0 5px 27px 3px rgb(213 213 213 / 35%);

            .field {
                margin: 0;

                .control {
                    font-size: 0;
                }

                input {
                    line-height: 1;
                    padding: 0;
                    border: 0;
                    box-shadow: none !important;
                }

                &:first-child input {
                    color: #1f1f1f;
                }
            }
        }
    }
}
</style>
