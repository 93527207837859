import ImageMixins from './ImageMixins'
import Library from '../components/Design/Builder/Library'
import Scrollbar from 'perfect-scrollbar'
import find from 'lodash/find'

export default {
    components: {Library},
    mixins:     [ImageMixins],
    props:      {
        template: {
            type: [Number, String],
        },
        library:  {
            required: true,
            type:     Array,
        },
        type:     {
            required: true,
            type:     String,
        }
    },
    data () {
        return {
            scrollbar: null
        }
    },
    computed:   {
        imageFromLibrary: {
            set (value) {
                this.file = value
            },
            get () {
                return this.file instanceof HTMLCanvasElement ? {} : this.file
            },
        },

        backgroundType () {
            return this.form.background_type
        },

        visible () {
            return this.form.background_type === this.type
        },
    },
    watch:      {
        library () {
            if (this.$refs.scrollable) {
                this.$refs.scrollable.scrollTop = 0
            }
        },
        hasImage (value) {
            if (!value) {
                this.setDefaultSelection()
            }
        },
        visible (value) {
            if (value) {
                this.setDefaultSelection()
            }
        },

        /**
         * Sets the currentImage to one that is not custom
         * Custom images should not be selected they go straight in to the cropper
         * @param image
         */
        imageFromLibrary (image) {

            // If custom image selected
            if (image !== null && typeof image.filename !== 'undefined') {

                // find first image that is not custom
                const firstStockImage = find(this.library, img => {
                    return img.filename === undefined
                })

                // Sets image
                this.imageFromLibrary = firstStockImage
            }
        },
    },
    mounted () {
        this.initialiseScrollbar()
    },
    methods:    {
        /**
         * After clicking an image in the library
         *
         * 1. Check if the image was aready selected
         * 2. Delete any old custom image
         * 3. Sets new current image
         *
         * @param image
         * @returns {Promise}
         */
        changeFromLibrary (image) {
            if (this.imageFromLibrary === {}) {
                return new Promise(resolve => resolve())
            }

            if (this.hasImage) {
                this.deleteImage()
            }

            this.imageFromLibrary = image

            return this.addImageFromLibrary(this.template, image)
        },

        /**
         * Get image url
         * Request image
         * Emit change event
         *
         * @param template_id
         * @param image
         * @returns {Promise}
         */
        addImageFromLibrary (template_id = this.template, image = this.imageFromLibrary) {
            const imageUrl = image.url.replace(':template_id', template_id)

            this.imageFromLibrary = image

            this.$emit('change', imageUrl)
            return this.requestImage(imageUrl)
        },

        /**
         * Fetch image from server using url
         * Empty function used for overwriting in child Components
         * @param url
         * @returns {Promise}
         */
        requestImage (url) {
            return new Promise(resolve => resolve())
        },

        /**
         * Initialise custom scrollbar from perfect-scrollbar library
         */
        initialiseScrollbar () {
            this.$nextTick(() => {
                this.scrollbar = new Scrollbar(this.$refs.scrollable, {
                    suppressScrollX: true,
                    wheelSpeed:      0.75
                })
            })
        },

        /**
         * Set default image selected from library
         */
        setDefaultSelection () {

            if ((this.imageFromLibrary === {} || this.imageFromLibrary instanceof Array || this.imageFromLibrary === null) && !this.hasImage) {
                this.$nextTick(() => {
                    if (this.library[0] === undefined) {
                        return
                    }
                    this.imageFromLibrary = this.library[0]
                    this.changeFromLibrary(this.imageFromLibrary)
                })
            }
        }
    }
}
