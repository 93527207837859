<template>
	<div :class="['is-multiline is-mobile', {'columns is-gapless': uploader }]">
		<div v-if="uploader" class="image-column-container column is-one-third library-uploader">
			<div>
				<label class="upload-draggable small"
					   :class="[{ 'is-bordered': dragDropFocus}, 'can-be-bordered']">
                    <i class="icon is-primary">add_circle_outline</i>
					Eigen afbeelding
					<input type="file"
						   accept="image/*, application/postscript, application/eps, application/x-eps, application/psd, application/photoshop, application/pdf"
						   @change="emitUpload"/>
				</label>
			</div>
		</div>
		<div :class="['image-column-container', { 'column is-one-third': uploader }]"
			 v-for="image in library">
			<div v-if="!!image.filename" class="action-buttons">
                <i class="icon" @click="emitDeleteCustom(image)">delete</i>
			</div>
			<div class="image is-3by2">
				<img :src="imageUrl(image)"
					 :alt="image.id"
					 :class="[{ 'is-bordered': isSelected(image), 'grey': !uploader }, 'can-be-bordered']"
					 @click="image.type === 'custom' ? emitCrop(image) : update(image)"
					 width="135"
					 height="92"/>
			</div>
		</div>
	</div>
</template>

<script>
import BIcon from 'buefy/src/components/icon/Icon'

export default {
        components: {BIcon},
        props:      {
            library:       {
                required: true,
                type:     Array,
            },
            uploader:      {
                default: false,
                type:    Boolean,
            },
            dragDropFocus: {
                default: false,
                type:    Boolean,
            },
            current:       {
                required: true,
            },
        },
        methods:    {
            imageUrl (image) {
                let svgView       = '#svgView(viewBox(50,50,210,104))'

                if (!!image.thumbnail) {
                    return image.thumbnail + svgView
                }

                return image.url + svgView
            },

            update (image) {
                this.$emit('change', image)
            },

            emitUpload (event) {
                this.$emit('upload', event)
            },

            emitCrop (image) {
                this.$emit('crop', image)
            },

            emitDeleteCustom (image) {
                this.$emit('delete-custom', image)
            },

            isSelected (image) {

                // Is current present
                return (this.current !== null && typeof this.current !== 'undefined') &&
                   (// siple check: is current equal to image
                      this.current === image ||
                      (// is svg and current \/
                         typeof this.current.svg !== 'undefined' && this.current.svg === image.id
                      ) ||// is image and current \/
                      this.current.id === image.id
                   )
            },
        }
    }
</script>
