<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">
                    <div class="popup-title">
                        <h1 class="title is-1">{{ $t('digital.delivery_log_resend_form.title') }}</h1>
                        <p>{{ $t('digital.delivery_log_resend_form.description') }}</p>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" @success="formSuccess"
                            prepopulate="true">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-fifth"><label>{{ $t('digital.delivery_log_resend_form.address') }}</label></div>
                                <div class="column">
                                    <g-input name="email_recipient" type="email" :label="$t('digital.delivery_log_resend_form.email')"/>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">{{ $t('digital.delivery_log_resend_form.cancel') }}</a>
                            <g-button :disabled="formDisabled">{{ $t('digital.delivery_log_resend_form.submit') }}</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: {
        order_id:    String,
        delivery_id: String
    },

    data () {
        return {
            form: new Form({
                'email_recipient': null
            }),
        }
    },

    computed: {
        formEndpoint () {
            return `/api/company/${this.$store.getters['user/activeCompanyId']}/promotional/${this.order_id}/delivery/${this.delivery_id}`
        },
        formMethod () {
            return 'patch'
        },
        formDisabled () {
            return !this.form.email_recipient
        },
    },

    methods: {
        formSuccess (response) {
            const action = 'orderDeliveries/add'

            this.$store
                .dispatch(action, {
                    object: response.data
                })
                .then(this.destroy)
        },
    },
}
</script>
