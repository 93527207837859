import i18n from "../../utils/i18n";
import {countriesApi} from "../../api/Countries";

const state = {
    currentVersion: process.env.MIX_BUILD_NUMBER,
    latestVersion: process.env.MIX_BUILD_NUMBER,
    countries: [],
    locales: ['nl', 'en'],
}

// getters
const getters = {
    availableLocales: state => state.locales,
    availableLocalesTranslated: state => state.locales.map(locale => {
        return {
            name: i18n.t(`locales.${locale}`),
            value: locale,
        }
    }),
    availableCountries: state => state.countries,
    availableCountriesTranslated: state => state.countries.map(country => {
        return {
            name: i18n.t(`countries.${country.code}`),
            value: country.value,
        }
    }),
    updateAvailable: state => parseInt(state.currentVersion) < parseInt(state.latestVersion),
    versionedAssetPath: state => process.env.ASSET_PATH || process.env.MIX_ASSET_URL + `/${state.latestVersion ?? ''}`,
}

// actions
const actions = {
    retrieveLatestVersion({commit}) {
        return axios.get(process.env.ASSET_PATH || process.env.MIX_ASSET_URL + `/version.json?time=${Date.now()}`)
            .then(({data}) => {
                commit('setLatestVersion', data.version)
            })
            .catch(() => {
            })
    },
    loadAllCountries({commit}) {
        if (state.countries.length > 0) {
            return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
            countriesApi.getAll()
                .then(({data}) => {
                    commit('setCountries', data)
                    resolve()
                })
        })
    }
}

// mutations
const mutations = {
    setLatestVersion(state, payload) {
        state.latestVersion = payload
    },
    setCountries(state, payload) {
        state.countries = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
