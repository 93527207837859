// api calls
import { adminInventoryApi as api } from '../../../api/admin/Inventory'

// state -> info about way of storing data; https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
const state = {
    availableCompanies: [],
    objects: [],
    objectsList: [],
}

// getters
const getters = {
    getAvailableCompanies:  state => state.companies,
    all:                    state => state.objectList.map(id => state.objects[id]),
    getById:                (state) => (id) => {
        return state.objects[id]
    },
}

// actions
const actions = {
    setCompanies ({commit}, payload) {
        // The server post should be handled by g-form
        commit('set', {
            objects: payload
        })
    },

    findCompany ({ commit }, query) {
        return new Promise((resolve, reject) => {
            api.findCompany(query).then(({ data }) => {
                resolve(data)
            })
        })
    },

    add ({commit}, payload) {
        // The server post should be handled by g-form
        commit('add', payload)
    },

    update ({commit}, payload) {
        // The server patch should be handled by g-form
        commit('update', payload)
    },
}

// mutations
const mutations = {
    set (state, payload) {
        let companies = payload.objects.map((company) => {
            const connected_to = company.connected_to.map((company) => {
                return {
                    id: company.id,
                    label: company.name
                }
            })

            return {
                id:             company.id,
                name:           company.name,
                translation_nl: company.translation_nl,
                translation_en: company.translation_en,
                company_price:  company.company_price,
                price:          company.price,
                priority:       company.priority,
                active:         company.active,
                thumbnail:      company.thumbnail,
                connected_to:   connected_to
            }
        })

        state.objects    = companies.reduce((obj, object) => {
            obj[object.id] = object

            return obj
        }, {})

        state.objectList = companies.map(object => object.id)
    },

    add (state, payload) {
        state.objects[payload.object.id] = payload.object
        state.objectList.push(payload.object.id)
    },

    update (state, payload) {
        if (typeof payload.object.connected_to !== 'undefined' && payload.object.connected_to.length >= 1) {
            payload.object['connected_to'] = payload.object.connected_to.map((company) => {
                return {
                    id: company.id,
                    label: company.name
                }
            })
        }

        state.objects[payload.object.id] = payload.object
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
