<template>
    <div class="tab-content" :class="{'blueprint-bg': blueprint, 'justify': justifyContent}" v-if="active">
        <div class="tab-header" v-if="!!this.$slots.header === true">
            <div class="header">
                <slot name="header"></slot>
            </div>
            <slot name="postheader"></slot>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TabbedViewItem",
    props: {
        title: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        blueprint: {
            type: Boolean,
            default: false,
        },
        justifyContent: {
            type: Boolean,
            default: true,
        }
    }
}
</script>

<style scoped lang="scss">
.tab-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .tab-header .header {
        background-color: #ffffff;
        width: 100%;
        font-size: 0.875rem;
        padding: 0.8rem 1rem;
        border-bottom: 0.063rem solid #efefef;
    }

    &.justify {
        justify-content: space-between;
    }
}

.blueprint-bg {
    background: url('../../../images/layout/blueprint-bg.svg') repeat #F6F9FB;
    background-blend-mode: darken;

    // The background starts with a border on the left side. This can look funny if the container itself also has a border
    background-position-x: -5px;
}
</style>
