import store from '../store/'

export const giftcardsApi = {

    /**
     * Retrieves all giftcards
     * @param company_id
     */
    getAllGiftcards () {
        return axios.get(`/api/admin/giftcard-management`)
    },

    /**
     * Search for Giftcard by code
     * @param company_id
     * @param code
     */
    searchGiftcard (code) {
        return axios.get(`/api/company/${store.getters['user/activeCompanyId']}/giftcards/search/${code}`);
    },

    /**
     * Redeem Giftcard
     *
     * @param giftcard_code
     * @param amount
     * @param currency
     * @param capture
     * @param location_id
     */
    redeemGiftcard (giftcard_code, amount, currency, capture, location_id) {
        return axios.post(`/v1/giftcards/${giftcard_code}/redeem`, {
            amount,
            currency,
            capture,
            location_id,
        });
    },

    /**
     * Issue Giftcard
     *
     * @param giftcard_code
     * @param amount
     * @param currency
     * @param promotional
     */
    issueGiftcard (giftcard_code, amount, currency, promotional, location_id, package_id) {
        return axios.post(`/v1/giftcards/${giftcard_code}/issue`, {
            amount,
            currency,
            promotional,
            location_id,
            package_id
        });
    },

    /**
     * Extend the validity of a Giftcard
     *
     * @param giftcard_code
     * @param expires_at
     */
    extendGiftcard (giftcard_code, expires_at) {
        return axios.post(`/v1/giftcards/${giftcard_code}/extend`, {
            expires_at
        });
    },

};
