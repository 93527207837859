<template>
	<form @submit.prevent="submit" @keydown="form.errors.clear($event.target.id)">
		<slot
            :active-locale="activeLocale"
            :change-locale="setLocale"
            :available-locales="availableLocales"
        ></slot>

		<div v-if="standardButton" class="field">
			<g-button :loading="isLoading">
				<slot name="submit"></slot>
			</g-button>
		</div>
	</form>
</template>

<script>
import clone from 'lodash/clone'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

export default {
        props:    {
            action:      '',
            method:      {default: 'post'},
            prepopulate: {default: false},
            prepopulateWithPostResponse: {default: false},
            before:      {default: false},
            form:        {default: false},
            message:     {default: false},
            initialLocale: {
                type: String,
                default: null
            }
        },
        data () {
            return {
                isLoading:   false,
                isValidating: false,
                formWatcher: null,
                activeLocale: this.initialLocale || this.$i18n.locale
            }
        },
        computed: {
            availableLocales() {
                return this.$store.getters['application/availableLocalesTranslated']
            },
            standardButton () {
                return !!this.$slots['submit']
            },
        },

        methods: {
            setLoadingState (state) {
                this.$emit('loading-state', state)
                this.isLoading = state
            },
            validate: debounce(function () {
                if (this.isValidating) return;

                this.isValidating = true;
                const html5Errors = {};

                try {
                    if (this.form.errors.has('_fromServer') && !this.form.errors.get('_fromServer')) {
                        this.form.errors.clear();
                    }

                    this.$children.forEach(child => {
                        const element = child.$el.querySelector('input');
                        if (element && !element.name && !element.checkValidity()) {
                            html5Errors[child.name] = [element.validationMessage];
                        }
                    });

                    if (!isEmpty(html5Errors)) {
                        html5Errors._fromServer = [false];
                        this.form.errors.record(html5Errors);
                    }

                    this.$emit('changed', this.form);
                } finally {
                    this.isValidating = false;
                }
            }, 1000),

            setLocale(locale) {
                this.activeLocale = locale
                this.form.setLocale(locale)
                this.$emit('locale-changed', locale)
            },

            submit (event) {
                return new Promise(async (resolve) => {
                    this.setLoadingState(true);

                    let sendData = await this.getFormData()
                        .catch(() => {
                            this.$emit('loading-state', false)

                            this.setLoadingState(false)
                        })

                    if (typeof this.$attrs.files !== 'undefined') {
                        sendData.config = {
                            headers: {'content-type': 'multipart/form-data'}
                        }
                    }

                    if (sendData !== false) {
                        sendData.submit(this.method, this.action)
                           .then(data => {
                               this.form.errors.clear()
                               this.$emit('success', data)

                               if (!!this.message) {
                                   this.$buefy.toast.open({
                                       message: this.message,
                                       type:    'is-success'
                                   })
                               }

                               if (!!this.prepopulate && !!this.prepopulateWithPostResponse) {
                                   this.form.setFormData(data).then(event => {
                                       this.$emit('prepopulated', event)
                                   })
                               }

                               this.setLoadingState(false);

							   resolve()
                           })
                           .catch(error => {
                               this.$emit('fail', error.response.data)

                               if (error.response.status === 422 && error.response.data.message !== undefined) {
                                   this.$buefy.toast.open({
                                       message: this.$t('notice.invalid') + ': ' + error.response.data.message,
                                       type:    'is-danger',
                                        duration: 1000 * 3,
                                   })
                               } else {
                                   this.$buefy.toast.open({
                                       message: this.$t('notice.error'),
                                       type:    'is-danger'
                                   })
                               }

                               this.setLoadingState(false)

							   resolve()
                           })
                    }
                })
            },
            getFormData() {
                return new Promise(async (resolve, reject) => {
                    let sendData = clone(this.form)

                    if (!!this.before) {
                        sendData = await this.before(sendData, event)

                        if (sendData === false) {
                            reject()
                        } else if (!(sendData instanceof Form)) {
                            sendData = this.form
                        }
                    }

                    resolve(sendData)
                })
            },
            getParsedFormData: async function () {
                let form = await this.getFormData()

                return form.data('post')
            },
        },
        created() {
            // Initialize form with current locale
            this.form.setLocale(this.activeLocale)

            // Watcher that handles all form changes
            this.$watch(
                'form',
                () => {
                    if (!this.isValidating) {
                        this.validate();
                    }
                },
                { deep: true }
            );
        },
        provide() {
            return {
                formLocale: () => this.activeLocale,
                isTranslatable: (fieldName) => {
                    return this.form.isFieldTranslatable(fieldName)
                }
            }
        },
        mounted () {
            if (!!this.prepopulate) {
                this.form.prepopulate(this.action).then(event => {
                    this.$emit('prepopulated', event)
                })
            }
        }
    }
</script>
