<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">Kortingscodes aanmaken</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">
                            <div class="columns">
                                <div class="column is-one-third"><label>Korting</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-format ref="format" label="Bedrag" name="amount" type="currency"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third"><label>Aantal</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="quantity" label="Aantal" type="number"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-third"><label>Regels</label></div>
                                <div class="column is-vcentered">
                                    <div class="field-body">
                                        <g-switch name="rule_edit"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-indented" v-if="this.form.rule_edit === 1">
                                <div class="column is-one-third"><label>Bestelbedrag</label></div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-format ref="format" label="Bedrag" name="rule_min_order_value"
                                                  type="currency"/>
                                    </div>
                                    <small>Bepaal het minimale bestelbedrag</small>
                                </div>
                            </div>
                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button>Opslaan</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        campaign_id: String
    },
    data() {
        const formData = {
            campaign_id: this.campaign_id,
            amount: null,
            quantity: null,
            rule_edit: 0,
            rule_min_order_value: null,
        }

        return {
            form: new Form(formData),
        }
    },
    computed: {
        formEndpoint() {
            return `/api/admin/coupon`
        },
        formMethod() {
            return 'post'
        },
    },
    methods: {
        formSuccess() {
            this.destroy()
        },
        formatData(form) {
            return form
        },
    },
}
</script>
