<template>
    <transition name="fade">
        <div class="modal is-active" v-show="visible">
            <div class="modal-background" @click="destroy"></div>
            <div class="modal-content">
                <div class="popup-dialog">

                    <div class="popup-title">
                        <h1 class="title is-1">{{ this.formTitle }}</h1>

                        <div class="popup-close" @click.prevent="destroy">
                            <i class="icon">close</i>
                        </div>
                    </div>

                    <g-form :action="formEndpoint" :form="form" :method="formMethod" :before="formatData"
                            @success="formSuccess" message="Wijzigingen succesvol opgeslagen">
                        <div class="popup-body popup-rows">

                            <div class="columns" v-if="this.isCreateNewForm === true">
                                <div class="column is-one-fifth">
                                    <label>Naam</label>
                                </div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="name" label="Naam"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth">
                                    <label>Vertaling</label>
                                </div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-input name="translation_nl" label="Nederlands"/>
                                        <g-input name="translation_en" label="Engels"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-one-fifth">
                                    <label>Prijs</label>
                                </div>
                                <div class="column">
                                    <div class="field-body">
                                        <g-format ref="format" label="Consument" name="price" type="currency"/>
                                        <g-format ref="format" label="Bedrijf" name="company_price" type="currency"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-full">
                                    <label>
                                        Thumbnail
                                    </label>

                                    <template v-if="form.thumbnail && in_edit_mode === false &&
                                                    isCreateNewForm === false &&
                                                    typeof form.thumbnail === 'string'">
                                        <div class="field-body">
                                            <img :src="form.thumbnail" alt="Sleeve" width="183" height="130">
                                        </div>

                                        <div class="mt-2">
                                            <g-button color="is-white"
                                                      @click="in_edit_mode = true"
                                                      :is-rounded="false">Wijzigen</g-button>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="field-body">
                                            <g-file name="thumbnail"
                                                    accept="image/png"/>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-full">
                                    <label>Koppelen aan bedrijven</label>

                                    <div class="field-body">
                                        <tree-select :multiple="true"
                                                     :async="true"
                                                     :load-options="loadOptions"
                                                     placeholder="Begin met typen om een bedrijf te vinden.."
                                                     :value="[{ id: 1, name: 'Gifty B.V.' }]"
                                                     :defaultOptions="defaultOptions"
                                                     loadingText="Aan het zoeken.."
                                                     searchPromptText="Begin met typen om een bedrijf te vinden.."
                                                     noResultsText="Geen resultaten.."
                                                     :clearable="false"
                                                     v-model="selectedCompanies"/>

                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column is-half">
                                    <g-switch name="priority"
                                              label="Prioriteit"/>

                                    <g-switch name="active"
                                              label="Actief"/>
                                </div>
                            </div>

                        </div>

                        <div class="popup-footer">
                            <a @click.prevent="destroy" href="#" role="button">Annuleren</a>
                            <g-button>Opslaan</g-button>
                        </div>
                    </g-form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props:    {
        id: Number
    },

    data () {
        const object   = this.$store.getters['adminInventory/getById'](this.id)
        const formData = object ? object : {
            name:           null,
            translation_nl: null,
            translation_en: null,
            price:          '2,50',
            company_price:  '1,75',
            priority:       null,
            active:         null,
            thumbnail:      null,
            connected_to:   [],
        }

        return {
            selectedCompanies: [],
            defaultOptions:    [],
            object:            object,
            form:              new Form(formData),
            in_edit_mode:      false,
        }
    },

    computed: {
        isCreateNewForm () {
            return this.object == null
        },

        formTitle () {
            return this.isCreateNewForm ? 'Sleeve toevoegen' : 'Sleeve aanpassen'
        },

        formEndpoint () {
            let endpoint = `/api/admin/inventory`

            if (false === this.isCreateNewForm) {
                endpoint += `/${this.id}`
            }

            return endpoint
        },

        formMethod () {
            return this.isCreateNewForm ? 'post' : 'patch'
        }
    },

    created() {
        if (this.form.price) {
            this.form.price = this.object ? parseInt(this.object.price) / 100 : this.form.price
            this.form.company_price = this.object ? parseInt(this.object.company_price) / 100 : this.form.company_price
        }

        if (false === this.isCreateNewForm && this.object.connected_to.length >= 1) {
            this.defaultOptions = this.object.connected_to.map((company) => {
                return {
                    id: company.id,
                    label: `#${ company.id } - ${ company.label }`
                }
            })

            this.selectedCompanies = this.object.connected_to.map((company) => {
                return company.id
            })
        }
    },

    methods:  {
        loadOptions({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                this.$store.dispatch('adminInventory/findCompany', searchQuery).then(response => {
                    const results = response.data.map((company) => {
                        return {
                            id: company.id,
                            label: `#${ company.id } - ${ company.name }`
                        }
                    })

                    callback(null, results)
                })
            }
        },

        formSuccess (response) {
            const action = this.isCreateNewForm ? 'adminInventory/add' : 'adminInventory/update'

            this.$store.dispatch(action, {
                object: response.data
            }).then(this.destroy)
        },

        formatData (form) {
            form.connected_to = this.selectedCompanies

            if (typeof form.thumbnail === 'string') {
                delete form.thumbnail
            }

            return form
        },
    },
}
</script>
