<template>
    <div>
        <h3 class="tab-header">Vertalingen bestelmodule</h3>

        <div class="is-relative pb-5">
            <BLoading :active="this.isInitializing" :is-full-page="false"/>

            <div class="table is-fullwidth" style="display: table;">
                <thead>
                <tr>
                    <th>Taal</th>
                    <th>Vertaalsleutel</th>
                    <th colspan="2" style="min-width: 30rem">Vertaling</th>
                </tr>
                </thead>

                <g-form
                    :action="`/api/admin/company/${this.$route.params.company_id}/translation`"
                    :prepopulate="true"
                    @prepopulated="prepopulated"
                    :form="this.form"
                    message="Instellingen opgeslagen"
                    style="display:table-row-group"
                    id="main-form"
                >
                    <template v-for="(fields, locale) in translationFields">
                        <tr v-for="(field, index) in fields">
                            <td v-if="index === 0" :rowspan="fields.length">{{ locale }}</td>
                            <td style="word-wrap: anywhere">{{ field.key }}</td>
                            <td>
                                <g-input type="textarea" :name="field.fieldName" rows="1"/>
                            </td>
                            <td class="items-list mb-0">
                                <div
                                    class="item-button mt-2"
                                    @click="removeTranslationField(field)">
                                    <div class="item-icon"><i class="icon">clear</i></div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </g-form>

                <!-- Hier gaat het mis, andere elementen in een form zijn niet toegestaan -->
                <g-form :form="addTranslationForm" style="display: table-footer-group;">
                    <tr class="autocomplete-container">
                        <td>
                            <g-select :options="translatableLocales" name="locale"/>
                        </td>
                        <td colspan="2">
                            <div class="translation-input" v-click-outside="() => presetsVisible = false">
                                <g-input
                                    name="newKey"
                                    placeholder="Vertaalsleutel"
                                    v-on:keyup.native.down="setPresetTabIndex(0)"
                                    v-on:keyup.native.up="setPresetTabIndex(0)"
                                    @focus="presetsVisible = true"/>
                                <g-input
                                    name="newTranslation"
                                    :placeholder="newPlaceholder"
                                    v-on:keyup.native.enter="addNewTranslation"
                                    ref="newTranslationInput"
                                />

                                <ul class="autocomplete-options" v-if="presetsVisible">
                                    <li
                                        v-for="(preset, key) in keyPresetsFiltered"
                                        tabindex="0"
                                        @keyup.enter="setPreset(preset)"
                                        @keydown.down="setPresetTabIndex(key + 1)"
                                        @keydown.up="setPresetTabIndex(key - 1)"
                                        @click="setPreset(preset)">
                                        <strong>{{ preset.translation }}</strong>
                                        <small class="help">{{ preset.key }}</small>
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td class="items-list mb-0">
                            <div
                                class="item-button is-success mt-3"
                                :class="{'is-disabled': !newTranslationValid}"
                                @click="addNewTranslation">
                                <div class="item-icon"><i class="icon">add</i></div>
                            </div>
                        </td>
                    </tr>
                </g-form>
            </div>

            <br/>
            <g-button v-if="!isInitializing" type="submit" form="main-form">Opslaan</g-button>
        </div>
    </div>
</template>

<script>

import ClickOutside from "../../../components/ClickOutsideDirective";
import Fuse from "fuse.js";

export default {
    name: "Translations",
    directives: {
        'click-outside': ClickOutside
    },
    data() {
        return {
            form: new Form({}),
            addTranslationForm: new Form({
                newKey: '',
                newTranslation: '',
                locale: null,
            }),
            isInitializing: true,
            presetsVisible: false,
            newPlaceholder: 'Voer een vertaling in om te overschrijven',
            translationFields: [],
            translatableLocales: [],
            availableTranslationKeys: {},
            fuseInstances: {},
        }
    },
    methods: {
        prepopulated(data) {
            let translationFields = {}

            this.translatableLocales = Object
                .keys(data.data.translatable_keys)
                .map((locale) => {
                    return {
                        value: locale,
                        name: 'Taal: ' + locale.toUpperCase(),
                    }
                });
            for (const locale in data.data.translatable_keys) {
                translationFields[locale] = [];
                let localeGroup = data.data.translations[locale] ?? []

                localeGroup.forEach((field) => {
                    let fieldName = `${field.locale}[${field.key}]`

                    this.form.addField(fieldName, field.translation)

                    translationFields[field.locale].push({
                        ...field,
                        fieldName
                    })
                })
            }

            this.translationFields = translationFields
            this.availableTranslationKeys = data.data.translatable_keys;
            this.isInitializing = false;
            this.addTranslationForm.locale = this.$i18n.locale;
        },
        setPreset(preset) {
            this.addTranslationForm.newKey = preset.key;
            this.addTranslationForm.newTranslation = preset.translation;
            this.newPlaceholder = preset.translation;
            this.presetsVisible = false;
            this.$refs.newTranslationInput.focusOnInput();
        },
        addNewTranslation() {
            if (!this.newTranslationValid) return;

            let fieldName = `${this.addTranslationForm.locale}[${this.addTranslationForm.newKey}]`
            this.form.addField(fieldName, this.addTranslationForm.newTranslation)

            this.translationFields[this.addTranslationForm.locale].push({
                key: this.addTranslationForm.newKey,
                translation: this.addTranslationForm.newTranslation,
                locale: this.addTranslationForm.locale,
                fieldName
            })

            this.addTranslationForm.newKey = '';
            this.addTranslationForm.newTranslation = '';
            this.newPlaceholder = 'Voer een vertaling in om te overschrijven';
        },
        removeTranslationField(field) {
            this.form.removeField(field.fieldName)
            this.translationFields[field.locale] = this.translationFields[field.locale].filter((f) => f.key !== field.key)
        },
        setPresetTabIndex(index) {
            const options = document.querySelectorAll('.autocomplete-options li');
            const option = options[index];

            if (option) {
                option.focus();
            }
        },
        getFuseInstance(locale) {
            if (!this.fuseInstances[locale]) {
                const localeTranslationKeys = this.availableTranslationKeys[locale] ?? {};
                const mappedTranslationKeys = Object
                    .keys(localeTranslationKeys)
                    .map((key) => {
                        return {
                            key,
                            translation: localeTranslationKeys[key]
                        }
                    });
                const options = {
                    ignoreLocation: true,
                    ignoreFieldNorm: true,
                    findAllMatches: true,
                    keys: ['key', 'translation'],
                }
                this.fuseInstances[locale] = new Fuse(
                    mappedTranslationKeys,
                    options,
                )
            }

            return this.fuseInstances[locale];
        },
        getTranslationKeys(locale) {
            const localeTranslationKeys = this.availableTranslationKeys[locale] ?? [];

            if (localeTranslationKeys.length === 0) {
                return null;
            }

            return Object
                .keys(localeTranslationKeys)
                .map((key) => {
                    return {
                        key,
                        translation: localeTranslationKeys[key]
                    }
                })
        },
    },
    watch: {
        'addTranslationForm.locale': function (locale) {
            this.newPlaceholder = 'Voer een vertaling in om te overschrijven';
            this.addTranslationForm.newKey = '';
            this.addTranslationForm.newTranslation = '';
        },
        'addTranslationForm.newKey': val => {
            // Scroll to top of autocomplete options
            const options = document.querySelector('.autocomplete-options');
            if (options) {
                options.scrollTop = 0;
            }
        }
    },
    computed: {
        newTranslationValid() {
            return this.addTranslationForm.newKey !== '' &&
                this.addTranslationForm.newTranslation !== '' &&
                this.availableTranslationKeys[this.addTranslationForm.locale][this.addTranslationForm.newKey] !== undefined &&
                this.translationFields[this.addTranslationForm.locale].find((field) => field.key === this.addTranslationForm.newKey) === undefined;
        },
        keyPresetsFiltered() {
            const searchQuery = this.addTranslationForm.newKey ?? ''

            if(!searchQuery) return this.getTranslationKeys(this.addTranslationForm.locale);

            return this
                .getFuseInstance(this.addTranslationForm.locale)
                .search(searchQuery, {
                    limit: 50
                })
                .map((result) => result.item)
                .filter((key) => {
                    // Filter out keys that are already translated
                    return !this.translationFields[this.addTranslationForm.locale].find((field) => field.key === key.key)
                });
        }
    },
    mounted() {

    },
}
</script>

<style lang="scss">
.autocomplete-container {
    td {
        border-bottom: 0;
    }

    input, select {
        padding-top: .85rem;
        padding-bottom: .85rem;
    }

    .translation-input {
        flex: 8;
        border-radius: 6px;
        border: 1px solid #EFEFEF;
        padding: 0.375em 1.1rem;
        min-width: 20rem;
        margin-bottom: 1.5rem;
        box-shadow: 0px 5px 27px 3px rgb(213 213 213 / 35%);

        .field {
            margin: 0 !important;

            .control {
                font-size: 0;
            }

            input {
                line-height: 1;
                padding: 0;
                border: 0;
                box-shadow: none !important;

                &:disabled {
                    background: none !important;
                }
            }

            &:first-child input {
                font-size: .8rem;
            }

            &:last-child input {
                color: #1f1f1f;
            }
        }
    }
}
</style>
