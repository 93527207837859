export const adminInventoryApi = {

    getAll () {
        return axios.get(`/api/admin/inventory`)
    },

    findCompany (query) {
        return axios.post(`/api/admin/inventory/search`, {
            query: query
        })
    }

}
